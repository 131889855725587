import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = new BehaviorSubject<any>(typeof localStorage !== 'undefined' ? localStorage.getItem('user') : undefined);
        console.log(user);
        if (user === undefined) {
            this.router.navigate(['/login']);
            return false;
            // logged in so return true
        } 
        else {
            // not logged in so redirect to login page with the return url
            // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            console.log("login done")
            return true;
        }


    }
}