import {
  Component,
  NgModule,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  model,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  FormArray,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { EmployeeService } from '../../inputfileds/form_services/employee/employee.service';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UploadDialogComponent } from '../../components/upload-dialog/upload-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ToastService } from '../../Services/toast/toast.service';
import { ToastComponent } from '../../components/toast/toast.component';

export interface Employee {
  index: number;
  Name: number;
  Dost_ID: string;
  Roll_No: string;
  Department: string;
  Academic_Year: string;
  Action: any;
}

export interface Employeeeducation {
  "id": number,
  "qualification_id": string,
  "start_year": string,
  "end_year": string,
  "university_name": string,
  "institution_name": string,
  "course_name": string,
  "percentage": string
}
export interface EmployeeFormdata {
  "personal_details": {
    "organization_id": string,
    "employee_unique_id": string,
    "title": string,
    "first_name": string,
    "middle_name": string,
    "last_name": string,
    "personal_email": string,
    "father_name": string,
    "mother_name": string,
    "phone_number": string,
    "alternate_mobile_number": string,
    "password": string,
    "religion_id": string,
    "caste_id": string,
    "sub_caste": string,
    "gender_id": string,
    "date_of_birth": string,
    "adhar_number": string,
    "pan_number": string,
    "user_role_id": string,
    "status_id": string,
    // "status": "ACTIVE"
  },
  "educational_qualifications": [
    {
      "id": number,
      "qualification_id": string,
      "start_year": string,
      "end_year": string,
      "university_name": string,
      "institution_name": string,
      "course_name": string,
      "percentage": string
    }
  ],
  "addresses": [
    {
      "country_id": string;
      "state_id": string;
      "address_line1": string;
      "address_line2": string;
      "pincode": string;
      "city": string;
    },
    {
      "country_id": string,
      "state_id": string,
      "address_line1": string,
      "address_line2": string,
      "pincode": string,
      "city": string
    }
  ],
  "experience_details": {
    "total_expiriance": string,
    "expiriance_in_our_institute": string,
    "number_of_publications_in_national_journal": string,
    "number_of_publications_in_international_journal": string,
    "number_of_books_published": string,
    "number_of_publications_in_national_conference": string,
    "number_of_publications_in_international_conference": string,
    "any_other_expirince_in_years": string,
    "number_of_workshops_attended": string,
    "number_of_seminars_attended": string,
    "number_of_seminars_conducted": string,
    "number_of_conferences_conducted": string,
    "number_of_conferences_attended": string,
    "number_of_pg_projects_guided": string,
    "number_of_doctorate_students_guided": string
  },
  "salary_deatails": {
    "pay_scale": string,
    "pf_number": string,
    "esi_number": string,
    "salary_mode_id": string,
    "gross_pay_per_month": string,
    "hra_in_rupees": string,
    "basic_pay": string,
    "other_allowances": string,
    "bank_account_number": string,
    "bank_ifsc_code": string,
    "bank_name": string,
    "bank_branch_name": string
  },
  "joining_details": {
    "professional_email": string,
    // "employement_type_id": string,
    "appointment_type_id": string,
    "department_id": string,
    "faculty_teaching_for": string,
    "designation_id": string,
    "date_of_joining": string,
    "date_of_relieving": string
  }
}

export interface personal_details_model {
  organization_id: number;
  employee_unique_id: string;
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  personal_email: string;
  father_name: string;
  mother_name: string;
  phone_number: string;
  alternate_mobile_number: string;
  // "password": "Welcome@123",
  religion_id: number;
  caste_id: number;
  sub_caste: string;
  gender_id: number;
  date_of_birth: string;
  adhar_number: string;
  pan_number: string;
  user_role_id: number;
  status_id: number;
}
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  EmployeeProfileFormQuestions: any[] = [];
  EmployeeProfileFormQuestions$: Observable<any[]> | null = null;
  EmployeeProfileForm!: FormGroup;
  EmployeePresentAddressForm!: FormGroup;
  EmployeePermanentAddressForm!: FormGroup;
  EmployeeExperienceForm!: FormGroup;
  EmployeeJoiningForm!: FormGroup;
  EmployeeSalaryForm!: FormGroup;
  EmployeeFilterForm!: FormGroup;
  EmployeeeAddresscheckboxForm!: FormGroup;
  form2!: FormGroup;
  EmployeeEducationForm!: FormGroup;
  payLoad = '';
  EmployeeAddressFormQuestions: any[] | null = [];
  EmployeeEducationFormQuestions: any[] | null = [];
  EmployeeExperienceFormQuestions: any[] | null = [];
  EmployeeJoiningFormQuestions: any[] | null = [];
  EmployeeSalaryFormQuestions: any[] | null = [];
  EmployeeeAddresscheckboxQuestions: any[] | null = [];
  EmployeeAddressFormQuestions$: Observable<any[]> | null = null;
  EmployeeEducationFormQuestions$: Observable<any[]> | null = null;
  EmployeeExperienceFormQuestions$: Observable<any[]> | null = null;
  EmployeeJoiningFormQuestions$: Observable<any[]> | null = null;
  EmployeeSalaryFormQuestions$: Observable<any[]> | null = null;
  EmployeeeAddresscheckboxQuestions$: Observable<any[]> | null = null;
  EmployeeFilterFormQuestions: any[] = [];
  EmployeeFilterFormQuestions$: Observable<any[]> | null = null;
  getEmployeeData = [];
  displayedColumns: string[] = [
    'Name',
    'email',
    'phone_number',
    'employee_unique_id',
    'designation_name',
    'organization_name',
    'Action',
  ];
  dataSource = new MatTableDataSource<Employee>(this.getEmployeeData);
  selection = new SelectionModel<Employee>(true, []);
  educationaddform = this.fb.group({});
  educationaddformarry: any = [];
  addressesformarry: any = [];
  showaddflag: boolean = false;
  showuploadconfirm: boolean = false;
  console = console;
  filterdata: any[] | undefined;
  pipe = new DatePipe('en-US');
  errorMessage: string | null = null;
  checked: boolean = false;
  url: string | ArrayBuffer | null | undefined;
  readonly indeterminate = model(false);
  paginationdata: any;
  total_count: any;
  file: File | null = null;
  dataId: number | null = null;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  selectedFilter = '';
  @ViewChild(MatMenuTrigger) filterMenu!: MatMenuTrigger;
  filters: any;
  isEditMode: boolean = false;
  editingEmployeeId: number | null = null;
  editeducationarrayflag: boolean = false;
  editeducationindex: any;
  editeducationid: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private EmployeeService: EmployeeService,
    private apiservice: ApiservicesService,
    // private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    private toast: ToastService
  ) {
    this.getemployeelist();
    this.EmployeeFilterForm = this.fb.group({
      department_id: [''],
    });
  }

  getemployeelist(departmentId?: any) {
    this.filters = { departmentId };
    this.apiservice
      .getemployeelist(this.paginationdata, this.filters)
      .subscribe((res: any) => {
        this.dataSource = new MatTableDataSource<Employee>(res?.rows);
        this.total_count = res.total_count;
        console.log('student res', res);
      });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }
  checkboxLabel(row?: Employee): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1
      }`;
  }

  ngOnInit() {
    this.EmployeeProfileForm = this.fb.group({});
    this.EmployeePresentAddressForm = this.fb.group({});
    this.form2 = this.fb.group({});
    this.EmployeeEducationForm = this.fb.group({});
    this.EmployeeExperienceForm = this.fb.group({});
    this.EmployeeJoiningForm = this.fb.group({});
    this.EmployeeSalaryForm = this.fb.group({});
    this.EmployeeeAddresscheckboxForm = this.fb.group({});
    this.EmployeePermanentAddressForm = this.fb.group({});
    this.EmployeeProfileFormQuestions$ = this.EmployeeService.getemployeeform();
    this.EmployeeAddressFormQuestions$ = this.EmployeeService.getAddressForm();
    this.EmployeeEducationFormQuestions$ =
      this.EmployeeService.getEducationalDetails();
    this.EmployeeExperienceFormQuestions$ =
      this.EmployeeService.getExperienceForm();
    this.EmployeeJoiningFormQuestions$ = this.EmployeeService.getJoiningFrom();
    this.EmployeeSalaryFormQuestions$ = this.EmployeeService.getSalaryForm();
    this.EmployeeeAddresscheckboxQuestions$ =
      this.EmployeeService.getPermanetcheckbox();
    this.EmployeeFilterFormQuestions$ =
      this.EmployeeService.getFilterDropdowns();

    this.EmployeeProfileFormQuestions$?.subscribe((q) => {
      this.EmployeeProfileFormQuestions = q;
      this.populateFormGroups();
    });

    this.EmployeeAddressFormQuestions$.subscribe((q) => {
      this.EmployeeAddressFormQuestions = q;
      this.populateFormGroups();
    });

    this.EmployeeEducationFormQuestions$.subscribe((q) => {
      this.EmployeeEducationFormQuestions = q;
      this.populateFormGroups();
    });

    this.EmployeeExperienceFormQuestions$.subscribe((q) => {
      this.EmployeeExperienceFormQuestions = q;
      this.populateFormGroups();
    });

    this.EmployeeJoiningFormQuestions$.subscribe((q) => {
      this.EmployeeJoiningFormQuestions = q;
      this.populateFormGroups();
    });

    this.EmployeeSalaryFormQuestions$.subscribe((q) => {
      this.EmployeeSalaryFormQuestions = q;
      this.populateFormGroups();
    });

    this.EmployeeeAddresscheckboxQuestions$.subscribe((q) => {
      this.EmployeeeAddresscheckboxQuestions = q;
      this.populateFormGroups();
    });
    this.EmployeeFilterFormQuestions$.subscribe((q) => {
      this.EmployeeFilterFormQuestions = q;
    });
    this.filterdata = [...this.EmployeeProfileFormQuestions];
    this.filterdata.splice(0, 7);
  }

  populateFormGroups() {
    for (const question of this.EmployeeProfileFormQuestions) {
      this.EmployeeProfileForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      ); // Ensure 'organization_id' is properly added
    }

    for (const question of this.EmployeeAddressFormQuestions || []) {
      this.EmployeePresentAddressForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }

    for (const question of this.EmployeeAddressFormQuestions || []) {
      this.EmployeePermanentAddressForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }

    for (const question of this.EmployeeEducationFormQuestions || []) {
      this.EmployeeEducationForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }

    for (const question of this.EmployeeExperienceFormQuestions || []) {
      this.EmployeeExperienceForm.addControl(
        question.key,
        this.fb.control(
          question.type === 'number' ? <number>0 : <string>'',
          <any>question?.required ? Validators.required : []
        )
      );
    }

    for (const question of this.EmployeeJoiningFormQuestions || []) {
      this.EmployeeJoiningForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }

    for (const question of this.EmployeeSalaryFormQuestions || []) {
      this.EmployeeSalaryForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }

    for (const question of this.EmployeeeAddresscheckboxQuestions || []) {
      this.EmployeeeAddresscheckboxForm.addControl(
        question.key,
        this.fb.control('', <any>question?.required ? Validators.required : [])
      );
    }
    // for (const question of this.EmployeeFilterFormQuestions || []) {
    //   this.EmployeeFilterForm.addControl(question.key, this.fb.control('', <any>question?.required ? Validators.required : []));
    // }

    // this.form2 = this.fb.group({
    //   educational_qualifications: this.fb.array([this.initTimes()]),
    // });
  }

  // ngDoCheck(){
  //   if(this.EmployeePresentAddressForm.value.country_id !== null){
  //     var array : any = []
  //     this.apiservice.getlookupcodesdropdown('INDIA').subscribe((course) => {
  //       console.log("--->",course)
  //       for (let i = 0; i <= course.length; i++) {
  //         array?.push({ id: course[i].id, key: course[i].name, value: course[i].id })
  //       }
  //     });
  //     // this.EmployeeAddressFormQuestions[1].options
  //     this.console.log("address data", this.EmployeeAddressFormQuestions);
  //   }
  // }
  // initTimes() {
  //   for (const question of this.EmployeeEducationFormQuestions || []) {
  //     this.educationaddform.addControl(question.key, this.fb.control('', <any>question?.required ? Validators.required : []));
  //   }
  //   return this.educationaddform
  // }

  // objectToFormGroup(obj: any): FormGroup {
  //   const formGroup = this.fb.group({});

  //   // Iterate over the keys in the object
  //   Object.keys(obj).forEach(key => {
  //     // Create a FormControl for each key-value pair and add it to the FormGroup
  //     formGroup.addControl(key, new FormControl(obj[key]));
  //   });

  //   return formGroup;
  // }

  changingids(event: any, index: number) {
    event.id = event.key + index;
    return event;
  }

  resetemployeeform() {
    // this.isEditMode = false;
    this.EmployeeProfileForm.reset();
    this.EmployeeEducationForm.reset();
    this.EmployeeExperienceForm.reset();
    this.EmployeeJoiningForm.reset();
    this.EmployeePresentAddressForm.reset();
    this.EmployeePermanentAddressForm.reset();
    this.EmployeeSalaryForm.reset();
    this.educationaddformarry = [];
    this.addressesformarry = [];
    this.EmployeeeAddresscheckboxForm.reset();
  }

  // get fa() {
  //   return this.form2.get('educational_qualifications') as FormArray;
  // }

  // addGroup() {
  //   this.fa.push(this.initTimes());
  // }

  // removeGroup(i: number) {
  //   this.fa.removeAt(i);
  // }

  gotoemplyeeview(event: any) {
    this.router.navigate(['employeeview', { dataId: event.id }]);
  }
  showadd(event: boolean) {
    this.showaddflag = event;
    if (!event) {
      this.getemployeelist();
    }
  }

  filterEmployees(event: Event) {
    const formValues = this.EmployeeFilterForm.value;
    this.getemployeelist(formValues.department_id);
    this.filterMenu.closeMenu();
  }

  clearFilter() {
    this.EmployeeFilterForm.reset();
    this.getemployeelist(); // Reset to the unfiltered list
    this.filterMenu.closeMenu(); // Optionally close the menu after clearing
  }

  // ngAfterViewInit() {
  //   this.cdr.detectChanges();
  // }

  getfirtformdata() {
    this.console.log('first form data', this.EmployeeProfileForm);
  }

  arraySubmit() {
    if (this.EmployeeEducationForm.valid) {
      this.educationaddformarry.push(this.EmployeeEducationForm.getRawValue());
      // this.openSnackBar();
      this.EmployeeEducationForm.reset();
    }
  }

  menuClosed(event: any) {
    console.log('Menu closed:', event);
  }

  editarrySubmit() {
    this.console.log("index for edit", this.editeducationindex)
    // this.educationaddformarry[this.editeducationindex].append(
    //   this.EmployeeEducationForm.getRawValue()
    // );
    this.educationaddformarry[this.editeducationindex] = this.EmployeeEducationForm.getRawValue();
    this.educationaddformarry[this.editeducationindex].id = this.editeducationid;
    this.reseteducationform();
    this.console.log("education edit data", this.educationaddformarry);
    this.editeducationarrayflag = false;
    this.editeducationindex = null;
  }
  reseteducationform() {
    this.console.log('Array of education', this.educationaddformarry);
    this.EmployeeEducationForm.reset();
  }

  editarrayform(event: any, index: number) {
    this.editeducationarrayflag = false;
    this.editeducationindex = index;
    this.editeducationid = event.id;
    this.EmployeeEducationForm.patchValue(event);
  }

  editarraysubmit(event: any) { }

  deletearrayform(event: number) {
    this.educationaddformarry.splice(event, 1);
  }

  openSnackBar() {
    this.snackBar.open(
      'Education details Added to the Form. you can modify/delete the Details by clicking on the menu option. also you can add more other educational details by repeating the same steps.',
      'cancel',
      {
        duration: 3000,
      }
    );
  }

  convertStoN(event: FormGroup) {
    this.console.log('form event', event);
    Object.keys(event.controls).forEach((key) => {
      if (!event.value[key]) {
        event.patchValue({ [key]: null });
      }
    });
    return event;
  }
  addresscheckbox() {
    if (this.checked) {
      this.EmployeePermanentAddressForm.setValue(
        this.EmployeePresentAddressForm.getRawValue()
      );
    } else {
      this.EmployeePermanentAddressForm.reset();
    }
  }

  imageInputChange(fileInputEvent: any): void {
    this.file = fileInputEvent.target.files[0];
    this.fileName = fileInputEvent.target.files[0].name;
    if (this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.selectedImageUrl = reader.result;
      };
    }
  }

  updateimage(file: File, id: number) {
    this.apiservice.updateemployeeimage(file, id).subscribe((res) => {
      console.log('employee image upload', res);
      this.getemployeelist();
    });
  }
  updateImage(file: File, id: number): void {
    console.log('klqwmlc', file);
    this.apiservice.updateemployeeimage(file, id).subscribe(
      (res) => {
        console.log('employee image upload', res);
        this.getemployeelist();
      },
      (err) => {
        console.error('Image upload error', err);
        this.errorMessage = 'Image upload failed. Please try again.';
      }
    );
  }

  onSubmit() {
    // this.convertStoN(this.EmployeeExperienceForm);
    let payload = {
      ...this.EmployeeProfileForm.getRawValue(),
      // ...this.EmployeePresentAddressForm.getRawValue(),
      // ...this.EmployeeEducationForm.getRawValue(),
      // ...this.convertStoN(this.EmployeeExperienceForm).getRawValue(),
      ...this.EmployeeExperienceForm.getRawValue(),
      ...this.EmployeeJoiningForm.getRawValue(),
      ...this.EmployeeSalaryForm.getRawValue(),
      // ...this.EmployeePermanentAddressForm.getRawValue()
    };

    // let addreess: any[] = [];
    this.addressesformarry.push(this.EmployeePresentAddressForm.getRawValue());
    this.addressesformarry.push(this.EmployeePermanentAddressForm.getRawValue());
    payload = { ...payload, addresses: this.addressesformarry };

    let apiObjecttosend = {
      personal_details: {
        organization_id: parseInt(
          this.EmployeeProfileForm.value.organization_id
        ),
        employee_unique_id: this.EmployeeProfileForm.value.employee_unique_id,
        title: this.EmployeeProfileForm.value.title,
        first_name: this.EmployeeProfileForm.value.first_name,
        middle_name: this.EmployeeProfileForm.value.middle_name,
        last_name: this.EmployeeProfileForm.value.last_name,
        personal_email: this.EmployeeProfileForm.value.personal_email,
        father_name: this.EmployeeProfileForm.value.father_name,
        mother_name: this.EmployeeProfileForm.value.mother_name,
        phone_number: this.EmployeeProfileForm.value.phone_number,
        alternate_mobile_number:
          this.EmployeeProfileForm.value.alternate_mobile_number,
        password: this.EmployeeProfileForm.value.password,
        religion_id: parseInt(this.EmployeeProfileForm.value.religion_id),
        // "religion": this.EmployeeProfileForm.value.religion,
        caste_id: parseInt(this.EmployeeProfileForm.value.caste_id),
        caste: this.EmployeeProfileForm.value.caste,
        sub_caste: this.EmployeeProfileForm.value.sub_caste,
        gender_id: parseInt(this.EmployeeProfileForm.value.gender_id),
        // "gender": this.EmployeeProfileForm.value.gender,
        date_of_birth: this.pipe.transform(
          this.EmployeeProfileForm.value.date_of_birth,
          'MM/dd/yyyy'
        ),
        adhar_number: this.EmployeeProfileForm.value?.adhar_number?.toString(),
        pan_number: this.EmployeeProfileForm.value.pan_number,
        user_role_id: parseInt(this.EmployeeProfileForm.value.user_role_id),
        // "user_role": this.EmployeeProfileForm.value.userRole,
        status_id: parseInt(this.EmployeeProfileForm.value.status_id),
        // "status": 'ACTIVE'
      },
      educational_qualifications: this.educationaddformarry,
      addresses: this.addressesformarry,
      experience_details: {
        total_expiriance: parseInt(
          this.EmployeeExperienceForm.value.total_expiriance
        ),
        expiriance_in_our_institute: parseInt(
          this.EmployeeExperienceForm.value.expiriance_in_our_institute
        ),
        number_of_publications_in_national_journal: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_national_journal
        ),
        number_of_publications_in_international_journal: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_international_journal
        ),
        number_of_books_published: parseInt(
          this.EmployeeExperienceForm.value.number_of_books_published
        ),
        number_of_publications_in_national_conference: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_national_conference
        ),
        number_of_publications_in_international_conference: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_international_conference
        ),
        any_other_expirince_in_years: parseInt(
          this.EmployeeExperienceForm.value.any_other_expirince_in_years
        ),
        number_of_workshops_attended: parseInt(
          this.EmployeeExperienceForm.value.number_of_workshops_attended
        ),
        number_of_seminars_attended: parseInt(
          this.EmployeeExperienceForm.value.number_of_seminars_attended
        ),
        number_of_seminars_conducted: parseInt(
          this.EmployeeExperienceForm.value.number_of_seminars_conducted
        ),
        number_of_conferences_conducted: parseInt(
          this.EmployeeExperienceForm.value.number_of_conferences_conducted
        ),
        number_of_conferences_attended: parseInt(
          this.EmployeeExperienceForm.value.number_of_conferences_attended
        ),
        number_of_pg_projects_guided: parseInt(
          this.EmployeeExperienceForm.value.number_of_pg_projects_guided
        ),
        number_of_doctorate_students_guided: parseInt(
          this.EmployeeExperienceForm.value.number_of_doctorate_students_guided
        ),
      },
      salary_deatails: {
        pay_scale: this.EmployeeSalaryForm.value.pay_scale,
        pf_number: this.EmployeeSalaryForm.value?.pf_number?.toString(),
        esi_number: this.EmployeeSalaryForm.value?.esi_number?.toString(),
        salary_mode_id: parseInt(this.EmployeeSalaryForm.value.salary_mode_id),
        // "salary_mode": this.EmployeeSalaryForm.value.salary_mode,
        gross_pay_per_month:
          this.EmployeeSalaryForm.value?.gross_pay_per_month?.toString(),
        hra_in_rupees: this.EmployeeSalaryForm.value?.hra_in_rupees?.toString(),
        basic_pay: this.EmployeeSalaryForm.value?.basic_pay?.toString(),
        other_allowances:
          this.EmployeeSalaryForm.value?.other_allowances?.toString(),
        bank_account_number:
          this.EmployeeSalaryForm.value?.bank_account_number?.toString(),
        bank_ifsc_code:
          this.EmployeeSalaryForm?.value.bank_ifsc_code?.toString(),
        bank_name: this.EmployeeSalaryForm.value?.bank_name?.toString(),
        bank_branch_name:
          this.EmployeeSalaryForm.value?.bank_branch_name?.toString(),
      },
      joining_details: {
        employement_type_id: this.EmployeeJoiningForm.value.employement_type_id,
        professional_email: this.EmployeeJoiningForm.value.professional_email,
        // "employement_type": this.EmployeeJoiningForm?.value?.employement_type,
        appointment_type_id: this.EmployeeJoiningForm.value.appointment_type_id,
        // "appointment_type": this.EmployeeJoiningForm?.value?.appointment_type,
        department_id: this.EmployeeJoiningForm.value.department_id,
        // "department_name": this.EmployeeJoiningForm.value.EmployeeJoiningForm,
        faculty_teaching_for:
          this.EmployeeJoiningForm.value.faculty_teaching_for,
        designation_id: this.EmployeeJoiningForm.value.designation_id,
        // "designation_name": this.EmployeeJoiningForm.value.designation_name,
        date_of_joining: this.pipe.transform(
          this.EmployeeJoiningForm.value.date_of_joining,
          'MM/dd/yyyy'
        ),
        date_of_relieving: this.pipe.transform(
          this.EmployeeJoiningForm.value.date_of_relieving,
          'MM/dd/yyyy'
        ),
      },
    };

    if (this.editingEmployeeId !== null) {
      this.updateEmployee(this.editingEmployeeId)
    } else {
      this.createAPi(apiObjecttosend);
    }
  }

  createAPi(event: any) {
    this.apiservice.createemployees(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.showadd(false);
        if (this.file) {
          this.updateImage(this.file, res.data.id);
        }
      } else {
        this.toast.showToast('danger', `Error: ${res.message}`);
        this.errorMessage = res.message;
      }
    });
  }

  editdata(event: any) {

    this.showadd(true);
    this.isEditMode = true;
    let editdataappend: any;
    this.resetemployeeform();
    this.editingEmployeeId = event.id
    this.apiservice.getemployeedetails(event.id).subscribe((event: EmployeeFormdata) => {
      this.console.log("edit data", event);
      editdataappend = event;
      const editdata = {
        personal_details: {
          organization_id: editdataappend?.personal_details?.organization_id,
          employee_unique_id: editdataappend?.personal_details?.employee_unique_id,
          title: editdataappend?.personal_details?.title,
          first_name: editdataappend.personal_details.first_name,
          middle_name: editdataappend.personal_details.middle_name,
          last_name: editdataappend.personal_details.last_name,
          personal_email: editdataappend.personal_details.personal_email,
          father_name: editdataappend.personal_details.father_name,
          mother_name: editdataappend.personal_details.mother_name,
          phone_number: editdataappend.personal_details.phone_number,
          alternate_mobile_number: editdataappend.personal_details.alternate_mobile_number,
          password: "",
          religion_id: editdataappend.personal_details.religion_id,
          caste_id: editdataappend.personal_details.caste_id,
          sub_caste: editdataappend.personal_details.sub_caste,
          gender_id: editdataappend.personal_details.gender_id,
          date_of_birth: editdataappend.personal_details.date_of_birth,
          adhar_number: editdataappend.personal_details.adhar_number,
          pan_number: editdataappend.personal_details.pan_number,
          user_role_id: editdataappend.personal_details.user_role_id,
          status_id: editdataappend.personal_details.status_id,
        },
        educational_qualifications: editdataappend.educational_qualifications.map(
          (qualification: any) => ({
            id: qualification.id,
            qualification_id: qualification.qualification_id,
            start_year: qualification.start_year,
            end_year: qualification.end_year,
            university_name: qualification.university_name,
            institution_name: qualification.institution_name,
            course_name: qualification.course_name,
            percentage: qualification.percentage,
          })
        ),
        addresses: editdataappend.addresses.map((address: any) => ({
          id: address.id,
          country_id: address.country_id,
          state_id: address.state_id,
          address_line1: address.address_line1,
          address_line2: address.address_line2,
          pincode: address.pincode,
          city: address.city,
        })),
        experience_details: {
          total_expiriance: editdataappend?.experience_details.total_expiriance,
          expiriance_in_our_institute:
            editdataappend?.experience_details.expiriance_in_our_institute,
          number_of_publications_in_national_journal:
            editdataappend?.experience_details.number_of_publications_in_national_journal,
          number_of_publications_in_international_journal:
            editdataappend?.experience_details
              .number_of_publications_in_international_journal,
          number_of_books_published:
            editdataappend?.experience_details.number_of_books_published,
          number_of_publications_in_national_conference:
            editdataappend?.experience_details
              .number_of_publications_in_national_conference,
          number_of_publications_in_international_conference:
            editdataappend?.experience_details
              .number_of_publications_in_international_conference,
          any_other_expirince_in_years:
            editdataappend?.experience_details.any_other_expirince_in_years,
          number_of_workshops_attended:
            editdataappend?.experience_details.number_of_workshops_attended,
          number_of_seminars_attended:
            editdataappend?.experience_details.number_of_seminars_attended,
          number_of_seminars_conducted:
            editdataappend?.experience_details.number_of_seminars_conducted,
          number_of_conferences_conducted:
            editdataappend?.experience_details.number_of_conferences_conducted,
          number_of_conferences_attended:
            editdataappend?.experience_details.number_of_conferences_attended,
          number_of_pg_projects_guided:
            editdataappend?.experience_details.number_of_pg_projects_guided,
          number_of_doctorate_students_guided:
            editdataappend?.experience_details.number_of_doctorate_students_guided,
        },
        salary_deatails: {
          pay_scale: editdataappend?.salary_deatails.pay_scale,
          pf_number: editdataappend?.salary_deatails.pf_number,
          esi_number: editdataappend?.salary_deatails.esi_number,
          salary_mode_id: editdataappend?.salary_deatails.salary_mode_id,
          gross_pay_per_month: editdataappend?.salary_deatails.gross_pay_per_month,
          hra_in_rupees: editdataappend?.salary_deatails.hra_in_rupees,
          basic_pay: editdataappend?.salary_deatails.basic_pay,
          other_allowances: editdataappend?.salary_deatails.other_allowances,
          bank_account_number: editdataappend?.salary_deatails.bank_account_number,
          bank_ifsc_code: editdataappend?.salary_deatails.bank_ifsc_code,
          bank_name: editdataappend?.salary_deatails.bank_name,
          bank_branch_name: editdataappend?.salary_deatails.bank_branch_name,
        },
        joining_details: {
          professional_email: editdataappend?.joining_details.professional_email,
          // employement_type_id: editdataappend?.joining_details.employement_type_id,
          appointment_type_id: editdataappend?.joining_details.appointment_type_id,
          department_id: editdataappend?.joining_details.department_id,
          faculty_teaching_for: editdataappend?.joining_details.faculty_teaching_for,
          designation_id: editdataappend?.joining_details.designation_id,
          date_of_joining: editdataappend?.joining_details.date_of_joining,
          date_of_relieving: editdataappend?.joining_details.date_of_relieving,
          employement_type_id: editdataappend?.joining_details.employement_type_id
        },
      };
      this.EmployeeProfileForm.setValue(editdata?.personal_details);
      this.educationaddformarry.push(...editdata.educational_qualifications);
      this.addressesformarry.push(...editdata.addresses);
      this.EmployeeExperienceForm.setValue(editdata?.experience_details);
      this.EmployeeJoiningForm.patchValue(editdata?.joining_details);
      this.EmployeeSalaryForm.setValue(editdata?.salary_deatails);
      this.EmployeePresentAddressForm.patchValue(editdata?.addresses[0]);
      this.EmployeePermanentAddressForm.patchValue(editdata?.addresses[1]);
    });
    // this.apiservice.editEmployeeDetails(editdata, event.id).subscribe(
    //   (employeedata: any) => {
    //     this.EmployeeProfileForm.setValue(editdata?.personal_details);
    //     this.educationaddformarry.push(
    //       ...employeedata.educational_qualifications
    //     );
    //     this.EmployeeExperienceForm.setValue(editdata?.experience_details);
    //     this.EmployeeJoiningForm.setValue(editdata?.joining_details);
    //     this.EmployeeSalaryForm.setValue(editdata?.salary_deatails);
    //     this.EmployeePresentAddressForm.setValue(employeedata?.addresses[0]);
    //     this.EmployeePermanentAddressForm.setValue(employeedata?.addresses[1]);
    //     console.log('Updated employee data', employeedata);
    //   },
    //   (error: any) => {
    //     console.error('Error updating employee data', error);
    //     // Add error handling logic, like showing a toast notification or a message
    //   }
    // );
  }

  updateEmployee(employeeId: number) {
    let address: any[] = [];
    address.push(this.EmployeePresentAddressForm.getRawValue());
    address.push(this.EmployeePermanentAddressForm.getRawValue());
    address[0].id = this.addressesformarry[0].id;
    address[1].id = this.addressesformarry[1].id;
    this.console.log("address form for the update data", this.addressesformarry);
    // this.addressesformarry[0] =  this.EmployeePresentAddressForm.getRawValue();
    // this.addressesformarry[1] =  this.EmployeePermanentAddressForm.getRawValue()
    let apiObjecttosend = {
      personal_details: {
        organization_id: parseInt(
          this.EmployeeProfileForm.value.organization_id
        ),
        employee_unique_id: this.EmployeeProfileForm.value.employee_unique_id,
        title: this.EmployeeProfileForm.value.title,
        first_name: this.EmployeeProfileForm.value.first_name,
        middle_name: this.EmployeeProfileForm.value.middle_name,
        last_name: this.EmployeeProfileForm.value.last_name,
        personal_email: this.EmployeeProfileForm.value.personal_email,
        father_name: this.EmployeeProfileForm.value.father_name,
        mother_name: this.EmployeeProfileForm.value.mother_name,
        phone_number: this.EmployeeProfileForm.value.phone_number,
        alternate_mobile_number:
          this.EmployeeProfileForm.value.alternate_mobile_number,
        password: this.EmployeeProfileForm.value.password,
        religion_id: parseInt(this.EmployeeProfileForm.value.religion_id),
        // "religion": this.EmployeeProfileForm.value.religion,
        caste_id: parseInt(this.EmployeeProfileForm.value.caste_id),
        caste: this.EmployeeProfileForm.value.caste,
        sub_caste: this.EmployeeProfileForm.value.sub_caste,
        gender_id: parseInt(this.EmployeeProfileForm.value.gender_id),
        // "gender": this.EmployeeProfileForm.value.gender,
        date_of_birth: this.pipe.transform(
          this.EmployeeProfileForm.value.date_of_birth,
          'MM/dd/yyyy'
        ),
        adhar_number: this.EmployeeProfileForm.value?.adhar_number?.toString(),
        pan_number: this.EmployeeProfileForm.value.pan_number,
        user_role_id: parseInt(this.EmployeeProfileForm.value.user_role_id),
        // "user_role": this.EmployeeProfileForm.value.userRole,
        status_id: parseInt(this.EmployeeProfileForm.value.status_id),
        // "status": 'ACTIVE'
      },
      educational_qualifications: this.educationaddformarry,
      addresses: address,
      experience_details: {
        total_expiriance: parseInt(
          this.EmployeeExperienceForm.value.total_expiriance
        ),
        expiriance_in_our_institute: parseInt(
          this.EmployeeExperienceForm.value.expiriance_in_our_institute
        ),
        number_of_publications_in_national_journal: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_national_journal
        ),
        number_of_publications_in_international_journal: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_international_journal
        ),
        number_of_books_published: parseInt(
          this.EmployeeExperienceForm.value.number_of_books_published
        ),
        number_of_publications_in_national_conference: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_national_conference
        ),
        number_of_publications_in_international_conference: parseInt(
          this.EmployeeExperienceForm.value
            .number_of_publications_in_international_conference
        ),
        any_other_expirince_in_years: parseInt(
          this.EmployeeExperienceForm.value.any_other_expirince_in_years
        ),
        number_of_workshops_attended: parseInt(
          this.EmployeeExperienceForm.value.number_of_workshops_attended
        ),
        number_of_seminars_attended: parseInt(
          this.EmployeeExperienceForm.value.number_of_seminars_attended
        ),
        number_of_seminars_conducted: parseInt(
          this.EmployeeExperienceForm.value.number_of_seminars_conducted
        ),
        number_of_conferences_conducted: parseInt(
          this.EmployeeExperienceForm.value.number_of_conferences_conducted
        ),
        number_of_conferences_attended: parseInt(
          this.EmployeeExperienceForm.value.number_of_conferences_attended
        ),
        number_of_pg_projects_guided: parseInt(
          this.EmployeeExperienceForm.value.number_of_pg_projects_guided
        ),
        number_of_doctorate_students_guided: parseInt(
          this.EmployeeExperienceForm.value.number_of_doctorate_students_guided
        ),
      },
      salary_deatails: {
        pay_scale: this.EmployeeSalaryForm.value.pay_scale,
        pf_number: this.EmployeeSalaryForm.value?.pf_number?.toString(),
        esi_number: this.EmployeeSalaryForm.value?.esi_number?.toString(),
        salary_mode_id: parseInt(this.EmployeeSalaryForm.value.salary_mode_id),
        //"salary_mode": this.EmployeeSalaryForm.value.salary_mode,
        gross_pay_per_month:
          this.EmployeeSalaryForm.value?.gross_pay_per_month?.toString(),
        hra_in_rupees: this.EmployeeSalaryForm.value?.hra_in_rupees?.toString(),
        basic_pay: this.EmployeeSalaryForm.value?.basic_pay?.toString(),
        other_allowances:
          this.EmployeeSalaryForm.value?.other_allowances?.toString(),
        bank_account_number:
          this.EmployeeSalaryForm.value?.bank_account_number?.toString(),
        bank_ifsc_code:
          this.EmployeeSalaryForm?.value.bank_ifsc_code?.toString(),
        bank_name: this.EmployeeSalaryForm.value?.bank_name?.toString(),
        bank_branch_name:
          this.EmployeeSalaryForm.value?.bank_branch_name?.toString(),
      },
      joining_details: {
        employement_type_id: this.EmployeeJoiningForm.value.employement_type_id,
        professional_email: this.EmployeeJoiningForm.value.professional_email,
        // "employement_type": this.EmployeeJoiningForm?.value?.employement_type,
        appointment_type_id: this.EmployeeJoiningForm.value.appointment_type_id,
        // "appointment_type": this.EmployeeJoiningForm?.value?.appointment_type,
        department_id: this.EmployeeJoiningForm.value.department_id,
        // "department_name": this.EmployeeJoiningForm.value.EmployeeJoiningForm,
        faculty_teaching_for:
          this.EmployeeJoiningForm.value.faculty_teaching_for,
        designation_id: this.EmployeeJoiningForm.value.designation_id,
        // "designation_name": this.EmployeeJoiningForm.value.designation_name,
        date_of_joining: this.pipe.transform(
          this.EmployeeJoiningForm.value.date_of_joining,
          'MM/dd/yyyy'
        ),
        date_of_relieving: this.pipe.transform(
          this.EmployeeJoiningForm.value.date_of_relieving,
          'MM/dd/yyyy'
        ),
      },
    };
    this.apiservice.editEmployeeDetails(apiObjecttosend, employeeId).subscribe(
      (res: any) => {
        if (res.code === "204") {
          this.resetemployeeform();
          this.toast.showToast('success', 'Employee updated successfully');
          this.showadd(false);
        } else {
          this.toast.showToast('danger', `Error: ${res.message}`);
          this.errorMessage = res.message;
        }
      },
      (error) => {
        console.error("Error updating employee details", error);
        this.toast.showToast('danger', `Error: ${error.message}`);
      }
    );
  }

  csvInputChange(fileInputEvent: any) {
    this.uploaddata(fileInputEvent.target.files[0]);
  }
  uploaddata(event: any) {
    this.apiservice.bulkuploademployee(event).subscribe((res) => {
      this.getemployeelist();
    });
  }
  openUploadDialog(
    uploadType: 'details' | 'images',
    formDataKey: 'employeImages' | 'EMPLOYEES_BULKUPLOAD_TEMPLATE'
  ): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      data: { uploadType, formDataKey },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was close');
    });
  }
  pagination(event: any) {
    this.paginationdata = event;
    this.getemployeelist();
    this.filterEmployees(event);
  }
}

@NgModule({
  declarations: [EmployeeComponent],
  exports: [EmployeeComponent],
  imports: [
    PaginationComponent,
    MatPaginatorModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatCheckboxModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    ToastComponent,
  ],
})
export class EmployeeModule { }
