
<section class="container">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
            <div>
                <button mat-icon-button class="back_icon me-2" (click)="backPage()">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div *ngIf="BatchData">
                <h4 class="m-0">{{ BatchData?.start_year }} - {{ BatchData.end_year }} Batch</h4>
            </div>
        </div>
    </div>
    <div class="mt-3 semester_details_card">
        <div class="card bg-white p-3">
            <div *ngIf="BatchData">
                <span class="large-text m-0">
                    {{ BatchData?.start_year }} - {{ BatchData?.end_year }} ({{ BatchData?.code }})
                  </span>
                <div class="row">
                    <div class="col-sm-5">
                        <p class="m-0">Course Name<span class="px-2">:</span>{{ BatchData?.course_name }}</p>
                    </div>
                    <!-- <div class="edit_menu_show col-sm-2 d-flex justify-content-end">
                        <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="more option"
                            fontIcon="more_vert"></mat-icon>
                        <mat-menu #menu="matMenu">
                            <small mat-menu-item>Edit</small>
                            <small mat-menu-item (click)="createSectionState(BatchData)">
                  {{ BatchData.status === 'ACTIVE' ? 'Disable' : 'Enable' }}
                </small>
                        </mat-menu>
                    </div> -->
                </div>
                <div class="mt-3">
                    <p class="m-0 text_color_light">{{ BatchData?.description }}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="semester_details_card">
        <h4 style="font-weight: 600" class="pt-4">Section Details:</h4>
        <div class="bg-white card my-4 p-2">
            <div class="mt-3">
                <form [formGroup]="filtersectionForm">
                    <div class="row m-0">
                        <div class="col-8 col-md-10 col-sm-10 p-0">
                            <div class="row m-0">
                                <div *ngFor="let question of questions" class="mb-3 col-12 col-md-4">
                                    <app-question [question]="question" [form]="filtersectionForm"></app-question>
                                </div>
                            </div>
                        </div>
                        <div class="edit_menu_show col-md-2 d-flex justify-content-end align-items-center">
                            <mat-icon class="m-0" [matMenuTriggerFor]="createmenu" aria-hidden="false"
                                aria-label="more option" fontIcon="more_vert"></mat-icon>
                            <mat-menu #createmenu="matMenu">
                                <small mat-menu-item (click)="openCreateSectionDialog()">Add Sections</small>
                            </mat-menu>
                        </div>
                    </div>
                </form>
            </div>
            <div class="mx-3 mt-2">
                <h4 class="m-0">Students Data</h4>
            </div>
            <hr  class="mt-1 mx-3"/>
            <div>
                <table mat-table [dataSource]="studentdataSource" matSort class="mat-elevation-z8">
                    <!-- <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container> -->

                    <!-- Position Column -->
                    <!-- <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
                  <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                </ng-container> -->
                    <!-- Name Column -->
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.first_name }} {{ element.last_name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Course_name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Course</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.course_name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="phone_number">
                        <th mat-header-cell *matHeaderCellDef>Mobile Number</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.phone_number }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef>Email</th>
                        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="Dost_ID">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dost ID</th>
                        <td mat-cell *matCellDef="let element">{{ element.Dost_ID }}</td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container matColumnDef="Roll_No">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Roll No</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.roll_number }}
                        </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="Department">
                <th mat-header-cell *matHeaderCellDef>Department</th>
                <td mat-cell *matCellDef="let element">{{ element.course_name }}</td>
              </ng-container> -->
                    <ng-container matColumnDef="Academic_Year">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Academic Year
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.Academic_Year }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                        <td class="cursor_pointer" mat-cell *matCellDef="let element">
                            view
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</section>

<!-- Create Section Dialog Template -->
<ng-template #createSectionDialog>
    <app-toast></app-toast>
    <h1 mat-dialog-title>Add Section Name</h1>
    <form (ngSubmit)="onSubmit()" [formGroup]="createsectionForm">
    <div mat-dialog-content>
      <div *ngFor="let question of createsection" class="question-container">
        <app-question [question]="question" [form]="createsectionForm"></app-question>
      </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
      <button mat-flat-button (click)="onSaveClick()">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="!createsectionForm.valid">Submit</button>
    </div>
  </form>
  </ng-template>