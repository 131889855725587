import { Component, OnInit } from '@angular/core';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-leaveviews',
  standalone: true,
  imports: [MatTableModule, MatCheckboxModule, CommonModule, MatButtonModule, RouterModule, MatIconModule, MatMenuModule],
  templateUrl: './leaveviews.component.html',
  styleUrl: './leaveviews.component.scss',
  providers: [ApiservicesService]
})
export class LeaveviewsComponent {


  leaverequestdata: any = [];
  displayedColumns: string[] = [ 'leave_type', 'leave_mode', 'start_date', 'end_date', 'reason', 'status', 'actions'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  requesttype: any;

  ngOnInit(): void {
  }

  constructor(private apisevice: ApiservicesService, private router: Router, public aRoute: ActivatedRoute) {
    // this.getrequestdata()
    this.requesttype = this.aRoute.snapshot.queryParamMap.get('ReqestType')
    if(typeof this.requesttype === undefined){
      this.backnavigation()
    }else{
      this.getrequestdata(this.requesttype)
    }
  }

  getrequestdata(event:any) {
    this.apisevice.getleavestdetails(event).subscribe((res) => {
      this.leaverequestdata = res?.rows;
      this.dataSource = new MatTableDataSource<any>(res.rows)
    })
  }

  actionrequestapi(event: any, id: number) {
    const actionRequest = {
      "status": event
    }
    this.apisevice.actionleaverequest(actionRequest, id).subscribe((res) => {
      if (res.code == 204 && res.status === true) {
        this.getrequestdata(event);
      }
    })
  }

  backnavigation() {
    this.router.navigate(['/requests']);
  }

}
