import { Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { CoursesService } from '../../../inputfileds/form_services/courses/courses.service';
import { Observable, forkJoin } from 'rxjs';
import { QuestionService } from '../../../inputfileds/form_services/question/question.service';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { AddsemestersComponent } from '../semesters/addsemesters/addsemesters.component';
import { ToastComponent } from '../../../components/toast/toast.component';
import { ToastService } from '../../../Services/toast/toast.service';

export interface Coursemodel {
  organization_id: number;
  course_type_id: number;
  course_name: string;
  course_type: string;
  stream_id: number;
  stream_name: string;
  course_code: string;
  start_date: number;
  // stream_type: string,
  // stream_type_id: number;
  duration_type_id: number;
  duration_type_name: string;
  duration: string;
  description: string;
  number_of_semisters: number;
}
@Component({
  selector: 'app-addcourses',
  standalone: true,
  imports: [
    AddsemestersComponent,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    ToastComponent
  ],
  templateUrl: './addcourses.component.html',
  styleUrl: './addcourses.component.scss',
  providers: [QuestionControlService, CoursesService, ApiservicesService]
})
export class AddcoursesComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  courseform!:  FormGroup;
  datesend!: Coursemodel;
  semisterflag: boolean = false;
  coursedata: any = {};

  constructor(private dialogRef: MatDialogRef<AddcoursesComponent>,private apiservice: ApiservicesService,services: CoursesService, private router: Router,
    private qcs: QuestionControlService, private toast : ToastService
  ){
    this.questions$ = services.getcoursesform();
    this.questions$.subscribe(q => this.questions = q);
  }
  
  ngOnInit(): void {
    this.courseform = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  gotosemisters(){
    this.semisterflag = !this.semisterflag;
    // this.editcourse()
  }

  closeDialog(event:any){
    // this.dialogRef.close();
    this.gotosemisters()
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__fadeIn")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__fadeOut");
    setTimeout(()=>{this.dialogRef.close(event);}, 1000);
  }

  editcourse(){
    let editdata =  {
      "is_enabled": true,
      "is_deleted": false,
      "id": 8,
      "organization_id": 1,
      "course_type_id": 130,
      "course_name": "Venkat Course",
      "course_code":"Venkat Course123",
      "course_type": "Test123",
      "stream_id": 1,
      "stream_name": "test",
      "duration_type_id": 126,
      "duration_type_name": "Test1",
      "duration": "2",
      "description": "Ok Venkat",
      "number_of_semisters": 4,
      "created_by": 1,
      "updated_by": 1,
      "created_at": "2024-06-07T05:24:38.633Z",
      "updated_at": "2024-06-07T05:24:38.633Z",
      "semisters": [
          {
              "year": 1,
              "year_name": "1year"
          },
          {
            "year": 2,
            "year_name": "2year"
        }
      ]
  }

  this.coursedata = editdata;
  }
  
  onSubmit() {
    this.datesend = {
      organization_id: parseInt(this.courseform.value.organization_id),
      course_type_id: parseInt(this.courseform.value.course_type_id),
      course_name: this.courseform.value.course_name,
      course_type: this.courseform.value.course_type,
      course_code: this.courseform.value.course_code,
      stream_id: parseInt(this.courseform.value.stream_id),
      stream_name: this.courseform.value.stream_name,
      start_date: parseInt(this.courseform.value.start_date),
      // stream_type: string,
      // stream_type_id: number;
      duration_type_id: parseInt(this.courseform.value.duration_type_id),
      duration_type_name: this.courseform.value.duration_type,
      duration: this.courseform.value.duration,
      description: this.courseform.value.description,
      number_of_semisters: parseInt(this.courseform.value.number_of_semisters)  
    }
    // this.datesend.number_of_semisters = parseInt(this.courseform.value.number_of_semisters);

    forkJoin([
      this.apiservice.getstreamdropdown(),
      this.apiservice.getlookupcodesdropdown('COURSE_TYPES'),
      this.apiservice.getlookupcodesdropdown('DURATION_TYPES'),
      this.apiservice.getlookupcodesdropdown('STREAM_TYPES'),
    ]).subscribe(([StramRes, CourseRes, DurationRes, StramtypeRes]) => {
      const filteredStream = StramRes.find((dropfilter:any) => { return dropfilter.id === parseInt(this.courseform.value?.stream_id) });
      const filteredCourse = CourseRes.find((dropfilter: any) => { return dropfilter.id === parseInt(this.courseform.value?.course_type_id) });
      const filteredDuration = DurationRes.find((dropfilter: any) => { return dropfilter.id === parseInt(this.courseform.value?.duration_type_id)});
      // const filteredStramtype = StramtypeRes.find((dropfilter: any) => { return dropfilter.id === this.courseform.value?.gender_id });
      console.log("duration data", filteredDuration)
      this.datesend.stream_name = filteredStream?.name
      this.datesend.course_type = filteredCourse?.lookup_name 
      this.datesend.duration_type_name = filteredDuration?.lookup_name
      this.datesend.start_date = this.courseform.value?.start_date
      // this.datesend = { ...this.datesend, stream_type: filteredStramtype?.lookup_name };
      console.log("Updated payload:", this.datesend);
      
      this.createAPi(this.datesend)
    });
  }

  createAPi(event:any){
    this.apiservice.createcourses(event).subscribe((res: any) => {
      if(res.code === 201 && res.status === true){
        this.toast.showToast(
          'success',
          'Saved Successfuly',);
        this.coursedata = res.data.data;
        // this.courseform.reset();
        this.gotosemisters();
        // window.location.href = this.router.url;
        // this.closeDialog(true);
      }
      else{
          this.toast.showToast('danger', `Error: ${res.message}`);
      }
      })
  }
}
