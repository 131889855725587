import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { of, Observable } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { DatepickerQuestion } from '../../question-datepicker';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  organisationdata: any = [];
  Genderdata: any = [];
  Castesdata: any = [];
  Religionsdata: any = [];
  AcademicYearsData: any = [];
  Statuesdata: any = [];
  Userrolesdata: any = [];
  Coursesdata: any = [];
  Countrydata: any = [];
  Statedata: any = [];
  Qualificationdata: any = [];
  selectedCourseId: number | undefined;

  constructor(private apiservice: ApiservicesService) {
    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('USER_ROLES', this.Userrolesdata);
    this.loadDropdownData('GENDER', this.Genderdata);
    this.loadDropdownData('COURSES', this.Coursesdata);
    this.loadDropdownData('CASTES', this.Castesdata);
    this.loadDropdownData('RELIGIONS', this.Religionsdata);
    this.loadDropdownData('ACADEMIC_REGULATIONS', this.AcademicYearsData);
    this.loadDropdownData('STATUSES', this.Statuesdata);
    this.loadDropdownData('COUNTRY_NAMES', this.Countrydata);
    this.loadDropdownData('INDIA', this.Statedata);
    this.loadDropdownData('QUALIFICATIONS', this.Qualificationdata);
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;
  
    if (code === 'ORGANISATION') {
      observable = this.apiservice.getorganisation();
    } else if (code === 'USER_ROLES') {
      observable = this.apiservice.getstudentuserroles(code);
    } else if (code === 'COURSES') {
      observable = this.apiservice.getcoursesdetails();
    } else if (code === 'ACADEMIC_REGULATIONS') {
      observable = this.apiservice.getacademicregulationdrop();
    } else {
      observable = this.apiservice.getlookupcodesdropdown(code);
    }
  
    observable.subscribe((res: any[]) => {
      dataArray.push(
        ...res.map(item => ({
          id: item.id,
          key:
            code === 'ORGANISATION'
              ? item.short_name
              : code === 'USER_ROLES'
              ? item.name
              : code === 'COURSES'
              ? item.name
              : code === 'ACADEMIC_REGULATIONS'
              ? item.name
              : item.lookup_name,
          value: item.id,
        }))
      );
    });
  }
  


  getstudentform(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization',
        required: true,
        options: this.organisationdata,
        order: 1,
      }),
      new DropdownQuestion({
        key: 'user_role_id',
        label: 'User Role',
        options: this.Userrolesdata,
        // required: true,
        order: 2,
      }),

      new TextboxQuestion({
        key: 'first_name',
        label: 'First name',
        patterns: '[a-zA-Z ]*',
        order: 3,
      }),

      new TextboxQuestion({
        key: 'last_name',
        label: 'Last name',
        patterns: '[a-zA-Z ]*',
        order: 4,
      }),
      // new TextboxQuestion({
      //   key: 'course_name',
      //   label: 'Course Name',
      //   type: 'text',
      //   patterns: '[a-zA-Z ]*',
      //   required: true,
      //   order: 6,
      // }),
      new DropdownQuestion({
        key: 'course_id',
        label: 'Course',
        options: this.Coursesdata,
        required: true,
        order: 5,
      }),

      new TextboxQuestion({
        key: 'phone_number',
        label: 'Mobile Number',
        type: 'text',
        patterns: '^[0-9]*$',
        minLength: 10,
        maxLength: 10,
        order: 6,
      }),
      new TextboxQuestion({
        key: 'email',
        label: 'Email',
        type: 'email',
        patterns: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
        order: 7,
      }),

      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        type:'password',
        // required: true,
        order: 8,
      }),

      new TextboxQuestion({
        key: 'hallTicket_number',
        label: 'Hallticket Number',
        required: true,
        order: 9,
      }),

      new DatepickerQuestion({
        key: 'date_of_birth',
        label: 'Date Of Birth',
        type: 'datepicker',
        // value: false,
        // required: true,
        order: 10,
      }),

      new TextboxQuestion({
        key: 'adhar_number',
        label: 'Aadhaar Number',
        required: true,
        patterns:'^[0-9]{12}$',
        order: 11,
      }),

      // new DropdownQuestion({
      //   key: 'Caste',
      //   label: 'Caste',
      //   // value: 'Bombasto',
      //   type: 'text',
      //   required: true,
      //   options: this.Castesdata,
      //   order: 14,
      // }),

      new DropdownQuestion({
        key: 'caste_id',
        label: 'Caste',
        required: true,
        options: this.Castesdata,
        order: 12,
      }),

      new TextboxQuestion({
        key: 'sub_caste',
        label: 'Sub Caste',
        type: 'text',
        // value: 'Bombasto',
        required: true,
        order: 13,
      }),

      // new DropdownQuestion({
      //   key: 'gender',
      //   label: 'Gender',
      //   // required: true,
      //   order: 17,
      //   options: this.Genderdata,
      // }),

      new DropdownQuestion({
        key: 'gender_id',
        label: 'Gender',
        required: true,
        options: this.Genderdata,
        // patterns: '^d{10}$',
        order: 14,
      }),

      new DropdownQuestion({
        key: 'religion_id',
        label: 'Religion',
        type: 'text',
        // value: 'Bombasto',
        required: true,
        options: this.Religionsdata,
        order: 15,
      }),

      new DropdownQuestion({
        key: 'academic_regulation_id',
        label: 'Academic Regulations',
        type: 'text',
        required: true,
        options: this.AcademicYearsData,
        order: 16,
      }),

      new DropdownQuestion({
        key: 'year',
        label: 'Current Year of Course',
        type: 'text',
        options: [
              { key: '1', id: 1, value: 1 },
              { key: '2', id: 2, value: 2 },
              { key: '3', id: 3, value: 3 },
            ],
        required: true,
        order: 17,
      }),
      new DropdownQuestion({
        key: 'semister',
        label: 'Current Semister',
        type: 'text',
        required: true,
        options: [
              { key: '1', id: 1, value: 1 },
              { key: '2', id: 2, value: 2 },
              { key: '3', id: 3, value: 3 },
            ],
        order: 17,
      }),

      new TextboxQuestion({
        key: 'roll_number',
        label: 'Roll Number',
        type: 'text',
        required: true,
        order: 17,
      }),

      new DatepickerQuestion({
        key: 'joinging_date',
        label: 'Joinging Date',
        type: 'datepicker',
        // value: false,
        required: true,
        order: 18,
      }),

      new DatepickerQuestion({
        key: 'course_completion_date',
        label: 'Course Completion Date',
        type: 'datepicker',
        // value: false,
        required: false,
        order: 19,
      }),

      new DropdownQuestion({
        key: 'status_id',
        label: 'Status',
        required: true,
        options: this.Statuesdata,
        order: 20,
      }),
      // new DropdownQuestion({
      //   key: 'status',
      //   label: 'Status',
      //   required: true,
      //   options: this.Statuesdata,
      //   order: 33,
      // }),

      new TextboxQuestion({
        key: 'hobbies',
        label: 'Hobbies',
        type: 'text',
        required: true,
        order: 21,
      }),

      new TextboxQuestion({
        key: 'interested_extra_activities',
        label: 'Interested Extra Activities',
        type: 'text',
        required: true,
        order: 22,
      }),

      new TextboxQuestion({
        key: 'participation_in_ncc_nss',
        label: 'Participation In Ncc Nss',
        type: 'text',
        required: true,
        order: 23,
      }),

      new TextboxQuestion({
        key: 'participation_in_sports_games',
        label: 'Participation In Sports Games',
        type: 'text',
        required: true,
        order: 24,
      }),

      new TextareaQuestion({
        key: 'future_goal',
        label: 'Future Goal',
        type: 'text',
        // required: true,
        order: 25,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getFamilyForm(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'fathers_name',
        label: 'Fathers Name',
        type: 'text',
        required: true,
        order: 23,
      }),

      new TextboxQuestion({
        key: 'mothers_name',
        label: 'Mothers Name',
        type: 'text',
        required: true,
        order: 24,
      }),

      new TextboxQuestion({
        key: 'fathers_occupation',
        label: 'Fathers Occupation',
        type: 'text',
        // required: true,
        order: 25,
      }),

      new TextboxQuestion({
        key: 'mothers_occupation',
        label: 'Mothers Occupation',
        type: 'text',
        // required: true,
        order: 26,
      }),
      new TextboxQuestion({
        key: 'annual_income',
        label: 'Annual Income',
        type: 'text',
        // required: true,
        order: 27,
      }),
      new TextboxQuestion({
        key: 'parent_contact_number',
        label: 'Parent Contact Number',
        type: 'text',
        // required: true,
        order: 28,
      }),
      new TextboxQuestion({
        key: 'number_of_brothers',
        label: 'Number Of Brothers',
        type: 'number',
        // required: true,
        order: 29,
      }),
      new TextboxQuestion({
        key: 'number_of_sisters',
        label: 'Number Of Sisters"',
        type: 'number',
        // required: true,
        order: 30,
      }),
      
    ]
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getAddressForm(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'country_id',
        label: 'Country',
        required: true,
        options: this.Countrydata,
        order: 1,
      }),
      new DropdownQuestion({
        key: 'state_id',
        label: 'State',
        type: 'text',
        required: true,
        options: this.Statedata,
        order: 2,
      }),
      // new DropdownQuestion({
      //   key: 'state_id',
      //   label: 'State ID',
      //   type: 'text',
      //   required: true,
      //   order: 4,
      // }),
      new TextareaQuestion({
        key: 'address_line1',
        label: 'Address Line 1',
        type: 'text',
        required: true,
        order: 3,
      }),

      new TextareaQuestion({
        key: 'address_line2',
        label: 'Address Line 2',
        type: 'text',
        required: true,
        order: 4,
      }),

      new TextboxQuestion({
        key: 'city',
        label: 'City',
        type: 'text',
        required: true,
        order: 5,
      }),
      new TextboxQuestion({
        key: 'pin_code',
        label: 'PIN Code',
        type: 'text',
        required: true,
        order: 6,
        minLength: 6,
        maxLength: 6,
        patterns: '^[0-9]*$',
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getEducationalDetails(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [

      new DropdownQuestion({
        key: 'qualification_id',
        label: 'Qualification',
        type: 'text',
        options: this.Qualificationdata,
        required: true,
        order: 1,
      }),

      new DatepickerQuestion({
        key: 'start_year',
        label: 'Start Year',
        type: 'text',
        required: true,
        patterns: '^(19|20)d{2}$',
        order: 2,
      }),

      new DatepickerQuestion({
        key: 'end_year',
        label: 'End Year',
        type: 'text',
        required: true,
        patterns: '^(19|20)d{2}$',
        order: 3,
      }),

      new TextboxQuestion({
        key: 'university_name',
        label: 'University Name',
        type: 'text',
        required: true,
        order: 4,
      }),

      new TextboxQuestion({
        key: 'institution_name',
        label: 'Institution Name',
        type: 'text',
        required: true,
        order: 5,
      }),

      new TextboxQuestion({
        key: 'course_name',
        label: 'Course Name',
        type: 'text',
        required: true,
        order: 6,
      }),

      new TextboxQuestion({
        key: 'percentage',
        label: 'Percentage',
        type: 'number',
        patterns: '^100$|^[123456789][0-9]$|^[0-9]$',
        required: true,
        order: 7,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
  getFilterDropdowns(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'courseId',
        label: 'Course',
        type: 'text',
        options: this.Coursesdata,
        required: false,
        order: 1,
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
