import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { FooterComponent } from '../components/footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MenuItemsComponent } from './menu-items/menu-items.component';
import { HeaderComponent } from './header/header.component';
import { AuthenticationService } from '../components/Authentication/services/authentication.service';
import { LoginComponent } from '../components/login/login.component';
import { ToastComponent } from '../components/toast/toast.component';

@Component({
    selector: 'app-layout',
    standalone: true,
    imports: [
        LoginComponent,
        ToastComponent,
        NavbarComponent,
        FooterComponent,
        RouterModule,
        HeaderComponent,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatExpansionModule,
        MenuItemsComponent],
    templateUrl: './layout.component.html',
    styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {

    menu: any
    opened = true;
    userdata: any
    uiMenu: any = [
        // {
        //     title: 'Faqs',
        //     match_key: 'FAQS',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        {
            title: 'Courses',
            match_key: 'COURSES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/courses',
            color: '#3f51b5',
            subMenu: []
        },
        // {
        //     title: 'Mission',
        //     match_key: 'MISSION',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        {
            title: 'Students',
            match_key: 'STUDENTS',
            icon: '../../assets/logo/menu/students_menu_logo.svg',
            link: '/student',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Employees',
            match_key: 'EMPLOYEES',
            icon: '../../assets/logo/menu/staff_menu_logo.svg',
            link: '/employee',
            color: '#3f51b5',
            subMenu: []
        },
        // {
        //     title: 'Feedback',
        //     match_key: 'FEEDBACKS',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Time Table',
        //     match_key: 'TIME_TABLE',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'User Roles',
        //     match_key: 'USER_ROLES',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Break Time',
        //     match_key: 'BREAK_TIMES',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        {
            title: 'Departments',
            match_key: 'DEPARTMENTS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/department',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Lookup Codes',
            match_key: 'LOOKUP_CODES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/lookupcodeview',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Lookup Types',
            match_key: 'LOOKUP_TYPES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/lookuptype',
            color: '#3f51b5',
            subMenu: []
        },
        // {
        //     title: 'Organizations',
        //     match_key: 'ORGANIZATIONS',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Courses',
        //     match_key: 'COURSES',
        //     icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
        //     link: '/courses',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Module Manager',
        //     match_key: 'MODULE_MANAGER',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Student Leaves',
        //     match_key: 'STUDENT_LEAVES',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Holiday Calendar',
        //     match_key: 'HOLIDAY_CALENDAR',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'My Attendance',
        //     match_key: 'STUDENT_ATTENDANCE',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        {
            title: 'Employee Attendance',
            match_key: 'EMPLOYEE_ATTENDANCE',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/employeeatendance',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Student Attendance',
            match_key: 'STUDENT_ATTENDANCE',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/studentatendance',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Time Table',
            match_key: 'TIME_TABLE',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/timetable',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Leaves',
            match_key: 'EMPLOYEE_LEAVES',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/requests',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Leave settings',
            match_key: 'ORGANIZATION_SETTINGS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/timings',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Leave Type',
            match_key: 'LEAVE_SETTINGS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/leavetype',
            color: '#3f51b5',
            subMenu: []
        },
        {
            title: 'Sections',
            match_key: 'LEAVE_SETTINGS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            link: '/sectiondetails',
            color: '#3f51b5',
            subMenu: []
        },
        // {
        //     title: 'Employee Leaves',
        //     match_key: 'EMPLOYEE_LEAVES',
        //     icon: 'home',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Profile',
        //     match_key: 'PROFILE',
        //     icon: 'account_circle',
        //     link: '/profile',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Vision',
        //     match_key: 'VISION',
        //     icon: 'account_circle',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        // {
        //     title: 'Banners',
        //     match_key: 'BANNERS',
        //     icon: 'account_circle',
        //     link: '/dashboard',
        //     color: '#3f51b5',
        // },
        {
            title: 'Academics',
            match_key: 'ACADEMICS',
            icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
            color: '#3f51b5',
            subMenu: [
                {
                    title: 'Academic Calenders',
                    match_key: 'ACADEMIC_CALENDERS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    link: '/academicyears',
                    color: '#3f51b5',
                },
                {
                    title: 'Academic Semisters',
                    match_key: 'ACADEMIC_SEMISTERS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    color: '#3f51b5',
                    link: '/academicyears',
                },
                // {
                //     title: 'Academic Sections',
                //     match_key: 'ACADEMIC_SECTIONS',
                //     icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                //     color: '#3f51b5',
                //     link: '/sectiondetails',
                // },
                {
                    title: 'Academic Regulations',
                    match_key: 'ACADEMIC_REGULATIONS',
                    icon: '../../assets/logo/menu/dashboard_menu_logo.svg',
                    color: '#3f51b5',
                    link: '/academicregulations',
                },
            ],
        },
    ];

    constructor(private router: Router, private authenticationService: AuthenticationService) {
        if (this.authenticationService.user) {
            this.authenticationService.user.subscribe(x => {
                this.userdata = x
            });
        } else {
            this.router.navigate(['/login']);
        }
    }
    toggle(): void {
        this.opened = !this.opened;
    }

    ngOnInit(): void {
        let updatedMenu: any = [];
        let roleJson: any = JSON.parse(this.userdata)?.data.role_json.MENU;
        Object.keys(roleJson).map((a: any) => {
            if (typeof roleJson[a] != "undefined" && typeof roleJson[a].IS_ENABLED != "undefined") {
                if (roleJson[a].IS_ENABLED === true) {
                    let x: any = this.uiMenu.find((ui: any) => { return ui.match_key === a; });
                    if (typeof x != "undefined") {
                        x.subMenuflag = false;
                        x.SubMenu = []
                        x.actions = roleJson[a].ACTIONS;
                        if (x.subMenu.length > 0) {
                            x.subMenuflag = true;
                            roleJson[a].SUBMENU?.map((b: any) => {
                                if (b.IS_ENABLED === true) {
                                    let y: any = x.subMenu.find((ui: any) => { return ui?.match_key == b?.SUBMENU_NAME; });
                                    console.log("submenus data", y)
                                    if (typeof y != "undefined") {
                                        x.SubMenu.push(y)
                                    }
                                }
                            })
                        }
                        updatedMenu.push(x);
                    }
                }
            }
        });
        this.menu = updatedMenu;
    }

    modifyjson() {

    }

    // menu: Menu = [
    //     {
    //         title: 'Home',
    //         icon: 'home',
    //         link: '/dashboard',
    //         color: '#3f51b5',
    //     },
    //     {
    //         title: 'profile',
    //         icon: 'account_circle',
    //         link: '/profile',
    //         color: '#3f51b5',
    //     },
    //     {
    //         title: 'Lookup Type',
    //         icon: 'account_circle',
    //         link: '/lookuptype',
    //         color: '#3f51b5',
    //     },
    //     {
    //         title: 'Lookup code',
    //         icon: 'account_circle',
    //         link: '/lookupcodeview',
    //         color: '#3f51b5',
    //     },
    //     {
    //         title: 'Department',
    //         icon: 'account_circle',
    //         link: '/department',
    //         color: '#3f51b5'
    //     },
    //     {
    //         title: 'Student',
    //         icon: 'account_circle',
    //         link: '/student',
    //         color: '#3f51b5'
    //     },
    //     {
    //         title: 'Employee',
    //         icon: 'account_circle',
    //         link: '/employee',
    //         color: '#3f51b5'
    //     },
    //     {
    //         title: 'Statistics',
    //         icon: 'bar_chart',
    //         color: '#3f51b5',
    //         subMenu: [
    //             {
    //                 title: 'Sales',
    //                 icon: 'money',
    //                 link: '/sales',
    //                 color: '#3f51b5',
    //             },
    //             {
    //                 title: 'Customers',
    //                 icon: 'people',
    //                 color: '#3f51b5',
    //                 link: '/customers',
    //             },
    //         ],
    //     },
    // ];
}
