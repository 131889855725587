<section class="container">
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="form-grid">
      <div *ngFor="let question of questions" class="form-item">
        <app-question [question]="question" [form]="form"></app-question>
      </div>
    </div>
    <div class="form-actions">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!form.valid"
      >
        Submit
      </button>
    </div>
  </form>
</section>
