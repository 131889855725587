import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SemestersService } from '../../../inputfileds/form_services/semesters/semesters.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddsubjectsComponent } from './addsubjects/addsubjects.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { environment } from '../../../../environments/environment.prod';

export interface SemesterView {
  index: number;
  id: number;
  organization_id: number;
  course_name: string;
  course_code: string;
  department_name: string;
  number_of_semisters: number;
  description: string;
}

@Component({
  selector: 'app-semesters',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    DynamicFormQuestionComponent,
    CommonModule,
    MatTabsModule,
    MatDividerModule
  ],
  providers: [QuestionControlService, ApiservicesService, SemestersService],
  templateUrl: './semesters.component.html',
  styleUrl: './semesters.component.scss',
})
export class SemestersComponent {
  CourseData: any;
  semesterData: any;
  selected_semesterData: any;
  semestesubjectsData: any;
  dataSource = new MatTableDataSource<SemesterView>();
  step = 0;
  @Input() subjects: any[] = [];
  onerror = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png';
  editsubjectdata: any = null;
  isSubjectEnabled = false;
  assetUrl: string = environment.ASSET_URL;
  assetContainer: string = environment.ASSET_CONTAINER;

  constructor(
    private router: Router,
    private apiservice: ApiservicesService,
    private dataService: SemestersService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.CourseData = history.state.data;
    this.getsemesterDetails();
  }

  getsemesterDetails(): void {
    const data = {
      "course_ID": this.CourseData.id,
    }
    this.apiservice.getcoursewisesemester(data).subscribe((res: any) => {
      this.semesterData = res?.rows;
    })
  }

  getsemestersubjectsDetails(eventData: any): void {
    const data = {
      "semester_ID": eventData.id,
      "course_ID": eventData.course_id
    }
    this.apiservice.getsemestersubjectsDetails(data).subscribe((res: any) => {
      
      this.semestesubjectsData = res?.rows;
      console.log("---->>>>>>>>>",this.semestesubjectsData)

    })
  }

  setStep(index: number, semester: any) {
    this.step = index;
    this.selected_semesterData = semester;
    this.getsemestersubjectsDetails(semester)
  }

  sendData(): void {
    this.dataService.setData(this.semesterData);
  }

  openSubjects(event?: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '80%';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';
    const dialogRef = this.dialog.open(AddsubjectsComponent,
      {
        minWidth: '80%',  
        minHeight: '65vh', 
        maxHeight: '65vh',
        data: { semesterData: this.selected_semesterData, editdata: event }
      });
    dialogRef.afterClosed().subscribe(result => {
      this.editsubjectdata = null;
      this.getsemestersubjectsDetails(this.selected_semesterData)
    });
  }
  
  toggleSubjectState(subject: any) {
    const newStatus = subject.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const newData = {
      ACTION: newStatus === 'ACTIVE' ? 'ENABLE' : 'DISABLE'
    };
  
    this.apiservice.subjectStatus(subject.id, newData).subscribe(
      (response: any) => {
        console.log('PATCH API Response:', response);
        this._snackBar.open(`SUCCESSFULLY ${newData.ACTION}D` ,'', {
          duration: 3000
        });
        subject.status = newStatus;
      },
      (error: any) => {
        console.error('PATCH API Error:', error);
      }
    );
  }

  toggleCoursesState(course: any) {
    const newStatus = course.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    const newData = {
      ACTION: newStatus === 'ACTIVE' ? 'ENABLE' : 'DISABLE'
    };
  
    this.apiservice.courseStatus(course.id, newData).subscribe(
      (response: any) => {
        console.log('PATCH API Response:', response);
        this._snackBar.open(`SUCCESSFULLY ${newData.ACTION}D`,"", {
          duration: 3000
        });
        course.status = newStatus;
      },
      (error: any) => {
        console.error('PATCH API Error:', error);
        // Handle error if needed
      }
    );
  }

  editsubjects(editevent : any){
    this.editsubjectdata = editevent;
    this.openSubjects(editevent)
  }

  showCourses(): void {
    this.router.navigate(['/courses']).then(() => {
      this.CourseData;
    });
  }
  

  csvInputChange(fileInputEvent: any) {
    console.log("fileupload subjects", fileInputEvent)
    this.uploaddata(fileInputEvent.target.files[0])
  }

  uploaddata(event: any) {
    this.apiservice.bulkuploadsubjects(event).subscribe(res => {
      this.getsemesterDetails();
    })
  }
}
