import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatProgressBarModule],
  providers: [ApiservicesService]
})
export class UploadDialogComponent {
  selectedFile: File | null = null;
  isLoading = false;

  constructor(
    private dialogRef: MatDialogRef<UploadDialogComponent>,
    private apiservices: ApiservicesService,
    @Inject(MAT_DIALOG_DATA) public data: { uploadType: string, formDataKey: string }
  ) { }

  onFileSelected(event: Event): void {
    this.selectedFile = (event.target as HTMLInputElement).files?.[0] || null;
    console.log("this.selectedFile", this.selectedFile);
    console.log("Data aaaaaa>>>>>>", this.data, event);
  }

  handleUpload(): void {
    if (this.selectedFile) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append(this.data.formDataKey, this.selectedFile);

      // console.log('Uploading file with payload:', formData, this.selectedFile);
      const studentData = new FormData();
      studentData.append(this.data.formDataKey, this.selectedFile);

      if (this.data.formDataKey === 'EMPLOYEES_BULKUPLOAD_TEMPLATE') {
        this.apiservices.bulkuploademployee(formData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            alert('File uploaded successfully.');
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
          },
          complete: () => {
            this.isLoading = false; // Set loading to false
          }
        });
      } else if (this.data.formDataKey === 'STUDENTS_BULKUPLOAD_TEMPLATE') {
        this.apiservices.bulkuploadstudent(studentData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
            alert('Failed to upload the file. Please check the file format and try again.');
          }
        });
      }
      else {
        alert("Payload key miss match")
      }
    } else {
      alert('Please select a file to upload.');
    }
  }

  handleImagesUpload(): void {
    if (this.selectedFile) {
      this.isLoading = true;
      const formData = new FormData();
      formData.append(this.data.formDataKey, this.selectedFile);

      // console.log('Uploading file with payload:', formData, this.selectedFile);
      const studentData = new FormData();
      studentData.append(this.data.formDataKey, this.selectedFile);

      if (this.data.formDataKey === 'employeImages') {
        this.apiservices.bulkuploadimages(formData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            alert('File uploaded successfully.');
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
          },
          complete: () => {
            this.isLoading = false; // Set loading to false
          }
        });
      } else if (this.data.formDataKey === 'studentImages') {
        this.apiservices.bulkuploadimages(studentData).subscribe({
          next: (response) => {
            console.log('Upload successful', response);
            alert('File uploaded successfully.');
            this.closeDialog();
          },
          error: (error: HttpErrorResponse) => {
            console.error('Upload failed', error);
            if (error.status === 400) {
              console.error('Server response:', error.error);
            }
            alert('Failed to upload the file. Please check the file format and try again.');
          }
        });
      }
      else {
        alert("Payload key miss match");
      }
    } else {
      alert('Please select a file to upload.');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
