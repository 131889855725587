import { Component, OnInit, inject } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { read } from 'fs';

export interface lookupview {
  index: number,
  id: number,
  lookup_type_id: number,
  organization_id: number,
  lookup_type_name: string,
  lookup_name: string,
  lookup_display_name: string,
  description: string,
}

@Component({
  selector: 'app-lookupcodesview',
  standalone: true,
  imports: [MatTableModule, PaginationComponent, MatCheckboxModule, CommonModule, MatButtonModule, RouterModule],
  templateUrl: './lookupcodesview.component.html',
  styleUrl: './lookupcodesview.component.scss',
  providers: [ApiservicesService]
})

export class LookupcodesviewComponent implements OnInit {
  lookupdata = []
  displayedColumns: string[] = ['lookup_type_name', 'lookup_name', 'description'];
  dataSource = new MatTableDataSource<lookupview>();
  selection = new SelectionModel<lookupview>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  paginationdata: any;
  total_count :any ;
  constructor(private apisevice: ApiservicesService) { }

  getlookupdata(){
    this.apisevice.getlookupcodeslist(this.paginationdata).subscribe((res) => {
      this.lookupdata = res.rows;
      this.total_count = res.total_count; 
      this.dataSource = new MatTableDataSource<lookupview>(res.rows)
    })
  }

  ngOnInit(): void {
    this.getlookupdata();
  }

  pagination(event:any){
    this.paginationdata = event;
    this.getlookupdata();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: lookupview): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }
}
