import { Component } from '@angular/core';
import { ToastService } from '../../Services/toast/toast.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [
    trigger('toastTrigger', [
      state('open', style({
        opacity: 1,
        transform: 'translateX(0)',
      })),
      state('close', style({
        opacity: 0,
        transform: 'translateX(100%)',
      })),
      transition('open => close', [
        animate('0.5s')
      ]),
      transition('close => open', [
        animate('0.5s')
      ]),
    ]),
  ],
})

export class ToastComponent {
  // Change the default values to types  
  toastClass: string[] = ['toast-class'];  
  toastMessage: string = '';  
  showsToast: boolean = false;  

  constructor(public toastservice: ToastService ) { } // We inject the toast.service here as 'public'  

  ngOnInit(): void {  }  
  truncateMessage(message: string | null): string {
    if (!message) {
      return ''; // Return an empty string if the message is null
    }
    return message.length > 25 ? message.substring(0, 25) + '...' : message;
  }

  // We'll add this to the dismiss button in the template  
  dismiss(): void {    
    this.toastservice.dismissToast();  
    this.showsToast = false;
  }
}
