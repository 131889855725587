<section class="container">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div>
        <button mat-icon-button class="back_icon me-2" (click)="showCourses()">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div>
        <h4 class="m-0">Course Details</h4>
      </div>
    </div>
    <div>
      <div class="">
        <div class="bulk_button">
          <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="csvInputChange($event)" />
          <button mat-raised-button class="bulk_button_add" (click)="csvInput.click()">
            <img src="../../../assets/icons/fileupload.svg" class="me-2" alt="upload icon" />
            <span>Bulk Upload</span></button>
            <button mat-raised-button [matMenuTriggerFor]="menu" class="m-0 p-0 bulk_button_view">
              <mat-icon class="m-0">expand_more</mat-icon>
            </button>
        </div>
        <mat-menu #menu="matMenu" class="download_menu">
          <button mat-menu-item>
            <span>Download Sample</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>

  <div class="mt-3 semester_details_card">
    <div class="card bg-white p-3">
      <div *ngIf="CourseData">
        <div class="row">
          <div class="col-sm-5">
            <p class="m-0 fs-5">{{ CourseData?.course_name }}</p>
            <small class="m-0">Organisation Name: {{CourseData.organization_name}}</small>
          </div>
          <div class="col-sm-5">
            <p class="m-0">Course code<span class="px-2">:</span>{{ CourseData?.course_code }}</p>
            <p class="m-0">Semesters<span class="px-2">:</span>{{ CourseData?.number_of_semisters }}</p>
          </div>
          <div class="edit_menu_show col-sm-2 d-flex justify-content-end">
            <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false" aria-label="more option"
              fontIcon="more_vert"></mat-icon>
            <mat-menu #menu="matMenu">
              <!-- <small mat-menu-item>Edit</small> -->
              <small mat-menu-item (click)="toggleCoursesState(CourseData)">
                {{CourseData.status === 'ACTIVE' ? 'Disable' : 'Enable'}}
              </small>
            </mat-menu>
          </div>
        </div>
        <div class="mt-3">
          <p class="m-0 text_color_light">
            {{ CourseData?.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="semester_details_card">
    <div class="bg-white card my-4 p-2">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Semesters">
          <div class="mt-3">
            <mat-accordion class="example-headers-align bg-card-color">
              <div *ngFor="let semester of semesterData; let i=index">
                <mat-expansion-panel [expanded]="step === i" (opened)="setStep(i, semester)">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Semester {{semester.semister}}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <hr class="m-0" />
                  <div class="m-3">
                    <div>
                      <button mat-flat-button color="primary" class="form-control" color="primary"
                        (click)='openSubjects()'>
                        <mat-icon>add</mat-icon>
                        Add Subjects
                      </button>
                    </div>
                    <div *ngIf="semestesubjectsData?.length > 0; else Nodata">
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-sm-4" *ngFor="let subjects of semestesubjectsData">
                            <div>
                              <div class="card mt-2" [ngClass]="{'dimmed': subjects.status === 'INACTIVE'}" style="background-color: #EDEDF4;">
                                <div class="row g-0">
                                  <div class="col-md-4">
                                    <img #img
                                    [src]="assetUrl + assetContainer + '/' + subjects?.image_path"
                                    (error)="img.src = onerror"
                                    class="img-fluid rounded-start h-100" alt="...">
                                  </div>
                                  <div class="col-md-8 position-relative">
                                    <div class="card-body py-2 px-2">
                                      <p class="card-title bold">{{subjects.subject_name}}</p>
                                      <p class="m-0"><small class="card-text">Code <span class="mx-2">:</span>
                                          {{subjects.subject_code}}</small></p>
                                      <p class="m-0"><small class="card-text">Credits <span class="mx-2">:</span>
                                        {{subjects.subject_credits}}</small></p>
                                    </div>
                                    <div class="d-flex justify-content-end p-1">
                                      <div class="edit_menu_show col-sm-2 d-flex justify-content-end">
                                        <mat-icon [matMenuTriggerFor]="menu" aria-hidden="false"
                                          aria-label="more option" fontIcon="more_vert"></mat-icon>
                                        <mat-menu #menu="matMenu">
                                          <small mat-menu-item (click)="editsubjects(subjects)">Edit</small>
                                          <small mat-menu-item (click)="toggleSubjectState(subjects)">
                                            {{subjects.status === 'ACTIVE' ? 'Disable' : 'Enable'}}
                                          </small>
                                        </mat-menu>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ng-template #Nodata>
                      <div class="my-4">No Subjects</div>
                    </ng-template>
                  </div>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>
        </mat-tab>
        <mat-tab label="History"> No Data </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>