<section class="container">
    <div class="d-flex align-items-center">
        <!-- <div>
          <button mat-icon-button class="back_icon me-2"><mat-icon>arrow_back</mat-icon></button>
        </div> -->
        <div>
            <h4 class="m-0">Academic Year</h4>
        </div>
    </div>
    <div class="bg-white p-3 mt-2">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Working Hours">
                <div class="">
                    <div class="text-end mt-2"><button mat-flat-button color="primary"
                            (click)="openShiftDialog()"><mat-icon>add</mat-icon> <span>Add Shift</span></button></div>
                    <div><app-shift></app-shift></div>
                </div>
            </mat-tab>
            <mat-tab label="Week Off">
                <div class="">
                    <div class="text-end mt-2"><button mat-flat-button color="primary"
                            (click)="openweekoffDialog()"><mat-icon>add</mat-icon> <span>Add Week Off</span></button>
                    </div>
                    <div><app-weekoff></app-weekoff> </div>
                </div>
            </mat-tab>
            <mat-tab label="Holidays">
                <div class="">
                    <div class="text-end mt-2"><button mat-flat-button color="primary"
                            (click)="openholidayDialog()"><mat-icon>add</mat-icon> <span>Add Holiday</span></button>
                    </div>
                    <div><app-holidays></app-holidays></div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>