import { API_URL_CONFIG, ASSET_CONTAINER_NAME, ASSET_URL_CONFIG, isProduction } from "../../env";
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: isProduction,
  API_URL: API_URL_CONFIG, //'https://devapi.dbraei.edu.in/api/v1/'
  ASSET_URL: ASSET_URL_CONFIG,
  ASSET_CONTAINER: ASSET_CONTAINER_NAME
};
