import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-classes',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
  ],
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.scss']
})
export class ClassesComponent {
  selectedDate = new Date(); 
  classData = [
    {
      "id": 1,
      "subject_name": "Mathematics",
      "degree_type": "MPC",
      "year": "1st Year",
      "section": "A",
      "timing": "9:00 AM - 10:00 AM",
      "room": "101",
      "instructor": "Siva",
      "attendance_status": "Done"
    },
    {
      "id": 2,
      "subject_name": "Physics",
      "degree_type": "MPC",
      "year": "1st Year",
      "section": "A",
      "timing": "10:00 AM - 11:00 AM",
      "room": "102",
      "instructor": "Dr. Kumar",
      "attendance_status": "Pending"
    }
  ];
  constructor(private router: Router) {}

  get filteredClasses() {
    return this.classData;
  }

  onDateChange(event: any) {
    if (event.value) {
      this.selectedDate = event.value;
      this.filterClassesByDate();
    }
  }
  

  previousDate() {
    const previousDay = new Date(this.selectedDate);
    previousDay.setDate(this.selectedDate.getDate() - 1);
    this.selectedDate = previousDay;
    this.filterClassesByDate();
  }

  // Function to handle next date navigation
  nextDate() {
    const nextDay = new Date(this.selectedDate);
    nextDay.setDate(this.selectedDate.getDate() + 1);
    this.selectedDate = nextDay;
    this.filterClassesByDate();
  }

  filterClassesByDate() {
    // Add your logic here to filter classes based on `this.selectedDate`
    console.log('Filtering classes for:', this.selectedDate);
    // Example: Update `filteredClasses` based on the selected date.
  }

  viewDetails(classItem: any) {
    console.log('Viewing details for:', classItem);
    // Add logic to view class details
  }

  enroll(classItem: any) {
    console.log('Enrolling in:', classItem);
    // Add logic to enroll in class
  }

  openClasses(item: any) {
    this.router.navigate(['/classview'], { state: { data: item}});
  }
}
