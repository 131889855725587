import { Component } from '@angular/core';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { PaginationComponent } from '../../../../../components/pagination/pagination.component';
import { AcademicyearsformService } from '../../../../../inputfileds/form_services/academics/academicyearsform/academicyearsform.service';


export interface Holidayview {
  index: number,
  id: number,
  organization_id: number,
  date: string,
  holiday_type_id: number,
  holiday_type: string,
  holiday_name: string,
  description: string,
}


@Component({
  selector: 'app-holidays',
  standalone: true,
  imports: [MatTableModule, MatCheckboxModule, CommonModule, MatButtonModule, RouterModule,PaginationComponent],
  templateUrl: './holidays.component.html',
  styleUrl: './holidays.component.scss',
  providers:[ApiservicesService]
})
export class HolidaysComponent {

  weekoffdata = []
  displayedColumns: string[] = ['date','holiday_name','holiday_type','description'];
  dataSource = new MatTableDataSource<Holidayview>();
  selection = new SelectionModel<Holidayview>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  total_count: any;
  paginationData: any;

  constructor(private apisevice: ApiservicesService, private streamService : AcademicyearsformService) {
    this.getHolidayDetails()
  }
  
  getHolidayDetails(){
    this.apisevice.getholidaydetails(this.paginationData).subscribe((res) => {
      this.weekoffdata = res?.rows
      this.total_count= res.total_count;
      this.dataSource = new MatTableDataSource<Holidayview>(res.rows)
    })
  }

  ngOnInit(): void {
    this.getHolidayDetails();
    this.streamService.refreshStreamList$.subscribe(() => {
      this.getHolidayDetails();
    });
  }
  
  pagination(event: any) {
    this.paginationData = event;
    this.getHolidayDetails();
  }
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Holidayview): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }
  
}
