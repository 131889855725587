import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';
import { CheckboxQuestion } from '../../question-checkbox';
import { DatepickerQuestion } from '../../question-datepicker';
import { FileuploadQuestion } from '../../question-fileuplaod';
import { TextareaQuestion } from '../../question-textarea';
import { File } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  getProfileservice() {

    const questions: Inputfiledsbase<string>[] = [
      
      new FileuploadQuestion({
        key: 'upload_data',
        label: 'Upload Data',
        required: true,
        type:'File',
        order: 1
      }),
      
      new TextboxQuestion({
        key: 'firstName',
        label: 'First name',
        // value: 'Bombasto',
        required: true,
        patterns:'[a-zA-Z ]*',
        order: 1
      }),

      new TextboxQuestion({
        key: 'lastName',
        label: 'Last name',
        // value: 'Bombasto',
        required: true,
        patterns:'[a-zA-Z ]*',
        order: 2
      }),

      new TextboxQuestion({
        key: 'emailAddress',
        label: 'Email',
        type: 'email',
        patterns: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
        required: true,
        order: 3
      }),

      
      new CheckboxQuestion({
        key: 'agreebox',
        label: 'Agree to terms and conditions',
        type: 'checkbox',
        // value: false,
        required: true,
        order: 9
      }),

      new DatepickerQuestion({
        key: 'dob',
        label: 'DOB',
        type: 'datepicker',
        // value: false,
        required: true,
        order: 6
      }),

      new TextboxQuestion({
        key: 'mobile_number',
        label: 'Mobile Number',
        type: 'text',
        required: true,
        patterns:'^[0-9]*$',
        minLength: 10,
        maxLength: 10,
        order: 4
      }),

      new TextboxQuestion({
        key: 'Address',
        label: 'Address',
        type:'text',
        required: true,
        order: 5
      }),

      new TextboxQuestion({
        key: 'Address1',
        label: 'Address1',
        type:'text',
        required: true,
        order: 7
      }),

      new TextboxQuestion({
        key: 'Address2',
        label: 'Address2',
        type:'text',
        required: true,
        order: 8
      }),

      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        type:'text',
        required: true,
        order: 9
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
