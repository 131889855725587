import { Component,AfterViewInit,ViewChild, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CollegedetailsService } from '../../../Services/collegedetails/collegedetails.service';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';

export interface DepartmentView {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-collegedetails',
  templateUrl: './collegedetails.component.html',
  styleUrls: ['./collegedetails.component.scss'],
  standalone: true,
  imports: [MatIconModule,CommonModule,MatTableModule, MatPaginatorModule]
})
export class CollegedetailsComponent implements AfterViewInit {
  organizationData: any;
  displayedColumns: string[] = ['Course', 'code', 'duration', 'action'];
  dataSource = new MatTableDataSource<DepartmentView>();

  constructor(
    private router: Router,
    private dataService: CollegedetailsService,
    private apiService: ApiservicesService
  ) { }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.getOrganizationDetails();
  }

  getOrganizationDetails(): void {
    this.apiService.getorganizationdetails().subscribe((res: any) => {
      this.organizationData = res.rows;
      console.log("Fetched Organization Data:", this.organizationData);
      this.sendData();
    });
  }

  sendData(): void {
    this.dataService.setData(this.organizationData);
  }

  showOrganizations(): void {
    if (this.router.url === '/collegedetails') {
      this.router.navigate(['/organizations']);
    }
  }
}
