import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DatepickerQuestion } from '../../question-datepicker';
import { TextboxQuestion } from '../../question-textbox';
import { TimepickerQuestion } from '../../question-timepicker';
import { DropdownQuestion } from '../../question-dropdown';
import { MatCardImage } from '@angular/material/card';
import { FileuploadQuestion } from '../../question-fileuplaod';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  constructor() { }
  getOrganizationservices(){
    const questions: Inputfiledsbase<string>[] = [
      new TextboxQuestion({
        key: 'organization_name',
        label: 'Organization Name',
        type: 'text',
        required: true,
        order : 1,
      }),
      new TextboxQuestion({
        key: 'organization_code',
        label: 'Organization Code',
        type: 'text',
        required: true,
        order : 2,
      }),
      new TimepickerQuestion({
        key: 'shift_Date',
        label: 'Start Date',
        type: 'time',
        required: true,
        order: 3,
      }),
      new DropdownQuestion({
        key: 'state',
        label: 'State',
        required: true,
        // options: this.duration_type,
        order: 4
      }),
      new DropdownQuestion({
        key: 'district',
        label: 'District',
        required: true,
        // options: ,
        order: 5
      }),
      new DropdownQuestion({
        key: 'duration_type_id',
        label: 'Duration Type',
        required: true,
        // options: this.duration_type,
        order: 6
      }),
      new TextboxQuestion({
        key: 'village',
        label: 'Village/City',
        type: 'text',
        required: true,
        order : 7,
      }),
      new TextboxQuestion({
        key: 'dr.no',
        label: 'Dr.No, Street, Road no.',
        type: 'text',
        required: true,
        order : 8,
      }),
      new TextboxQuestion({
        key: 'Landmark',
        label: 'Landmark',
        type: 'text',
        required: true,
        order : 9,
      }),
      new TextboxQuestion({
        key: 'pincode',
        label: 'Pincode',
        type: 'text',
        required: true,
        order : 10,
      }),
      new TextboxQuestion({
        key: 'contact_number',
        label: 'Contact Number',
        type: 'number',
        required: true,
        order : 11,
      }),
      new TextboxQuestion({
        key: 'email',
        label: 'Email Id',
        type: 'email',
        required: true,
        order : 12,
      }),
      new FileuploadQuestion({
        key: 'upload_logo',
        label: 'Upload Logo {PNG, JPEG,JPG Only}',
        required: true,
        type:'File',
        order: 13
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
