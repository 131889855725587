import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';

export interface Weekoffview {
  index: number,
  id: number,
  organization_id: number,
  day: string,
  weekoff_type_id: string,
  weekoff_type: string,
}

@Component({
  selector: 'app-weekoff',
  standalone: true,
  imports: [MatTableModule, MatCheckboxModule, CommonModule, MatButtonModule, RouterModule],
  templateUrl: './weekoff.component.html',
  styleUrl: './weekoff.component.scss',
  providers:[ApiservicesService]
})
export class WeekoffComponent {

  weekoffdata = []
  displayedColumns: string[] = ['select', 'day','weekoff_type'];
  dataSource = new MatTableDataSource<Weekoffview>();
  selection = new SelectionModel<Weekoffview>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  constructor(private apisevice: ApiservicesService) {
    this.apisevice.getweekoffdetails().subscribe((res) => {
      this.weekoffdata = res?.rows
      this.dataSource = new MatTableDataSource<Weekoffview>(res.rows)
    })
  }
  
  
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Weekoffview): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }
}
