import { Component } from '@angular/core';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Inputfiledsbase } from '../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { ProfileService } from '../../inputfileds/form_services/profile/profile.service';
import { MatButtonModule } from '@angular/material/button';
import { PageHeaderComponent } from '../../layout/page-header/page-header.component';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';

@Component({
  selector: 'app-profile',
  standalone: true,
  providers: [QuestionControlService, ProfileService],
  imports: [PageHeaderComponent, CommonModule, DynamicFormQuestionComponent, ReactiveFormsModule, MatButtonModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  Profileform!: FormGroup;
  payLoad = '';
  edit_data = { "firstName": "anith kumar", "emailAddress": "anith@gmail.com", "mobile_number": "08106969279", "Address": "High-tech city", "agreebox": true }

  constructor(private qcs: QuestionControlService, service: ProfileService,) {
    this.questions$ = service.getProfileservice();
    this.questions$.subscribe(q => this.questions = q);
  }

  ngOnInit() {
    this.Profileform = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  editform() {
    // this.Profileform = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    // this.Profileform.get('firstName')?.patchValue(this.edit_data.firstName); 
    // this.Profileform.get('emailAddress')?.patchValue(this.edit_data.emailAddress); 
    // this.Profileform.get('mobile_number')?.patchValue(this.edit_data.mobile_number); 
    // this.Profileform.get('Address')?.patchValue(this.edit_data.Address); 
    Object.entries(this.edit_data).forEach(([key, value]) => {
      this.Profileform.get(key)?.patchValue(value);
    })
  }


  onSubmit() {

    const formData = this.Profileform.getRawValue();
    this.payLoad = JSON.stringify(formData);
    // this.apiservice.CreatestudentProfile(this.payLoad).subscribe((res:any) => {
    //   console.log(res);
    // })
    this.Profileform.reset();
  }

}