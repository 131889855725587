import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseChartDirective } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

Chart.register(...registerables);

@Component({
  selector: 'app-attendance',
  templateUrl: './classesview.component.html',
  styleUrls: ['./classesview.component.scss'],
})
export class ClassesviewComponent implements OnInit {
  selectedDate = new Date();
  displayedColumns: string[] = ['id', 'name', 'status', 'tillDate'];
  attendanceData: any[] = [];
  presentData: any[] = [];
  absenteesData: any[] = [];
  showPresent: boolean = true;
  classesData: any;
  chart: any;

  public config: any = {
    type: 'doughnut',
    data: {
      labels: ['Present', 'Absent'],
      datasets: [
        {
          data: [60, 40], // Initial dummy data, will be updated dynamically
          backgroundColor: ['#4CAF50', '#F44336'],
          responsive: true,
        },
      ],
    },
    options: {
      aspectRatio: 1,
    },
  };

  constructor(public router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.fetchAttendanceData();
    this.classesData = history.state.data;
    this.chart = new Chart('MyChart', this.config);
  }

  fetchAttendanceData() {
    const apiData = [
      { id: 301, name: 'Akula Lavanya', tillDate: 70 },
      { id: 302, name: 'Devane Vaishnavi', tillDate: 90 },
      { id: 303, name: 'Student 3', tillDate: 40 }
    ];
    this.attendanceData = apiData.map((student) => ({
      ...student,
      status: 'Present',
    }));
    this.updateAttendanceLists();
  }

  toggleStatus(element: any) {
    element.status = element.status === 'Absent' ? 'Present' : 'Absent';
    this.updateAttendanceLists();
  }

  updateAttendanceLists() {
    this.presentData = this.attendanceData.filter(
      (student) => student.status === 'Present'
    );
    this.absenteesData = this.attendanceData.filter(
      (student) => student.status === 'Absent'
    );
  }

  onDateChange(event: any) {
    if (event.value) {
      this.selectedDate = event.value;
    }
  }

  previousDate() {
    const previousDay = new Date(this.selectedDate);
    previousDay.setDate(this.selectedDate.getDate() - 1);
    this.selectedDate = previousDay;
  }

  nextDate() {
    const nextDay = new Date(this.selectedDate);
    nextDay.setDate(this.selectedDate.getDate() + 1);
    this.selectedDate = nextDay;
  }

  closeClasses() {
    this.router.navigate(['classes']);
  }
}

@NgModule({
  declarations: [ClassesviewComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatDatepickerModule,
    MatTableModule,
    MatFormFieldModule,
    BaseChartDirective,
    MatSlideToggleModule
  ],
  providers: [],
  exports: [ClassesviewComponent],
})
export class ClassesViewModule {}
