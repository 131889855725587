import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { AcademicyearsformService } from '../../../../inputfileds/form_services/academics/academicyearsform/academicyearsform.service';
import { Observable, forkJoin } from 'rxjs';
import { QuestionService } from '../../../../inputfileds/form_services/question/question.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { SectionsComponent } from '../../academicregulations/sections/sections.component';
import { ToastService } from '../../../../Services/toast/toast.service';
import { ToastComponent } from '../../../../components/toast/toast.component';
export interface Coursemodel {
  organization_id: number;
  start_date: number;
  end_date: number;
  batches: [{}];
}

@Component({
  selector: 'app-addacademicregulations',
  standalone: true,
  imports: [
    SectionsComponent,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    ToastComponent
  ],
  providers: [
    QuestionControlService,
    AcademicyearsformService,
    ApiservicesService,
  ],
  templateUrl: './addacademicyears.component.html',
  styleUrl: './addacademicyears.component.scss',
})
export class AddacademicyearsComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  academicsform!: FormGroup;
  datesend!: Coursemodel;
  semisterflag: boolean = false;
  academicsdata: any = {};
  selectedBatchId: number = 0

  constructor(
    private dialogRef: MatDialogRef<SectionsComponent>,
    private apiservice: ApiservicesService,
    services: AcademicyearsformService,
    private router: Router,
    private qcs: QuestionControlService,
    private toast: ToastService
  ) {
    this.questions$ = services.getacadamicyearsform();
    this.questions$.subscribe((q) => (this.questions = q));
  }

  ngOnInit(): void {
    this.academicsform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );
    this.academicsform.addControl(
      'start_year',
      new FormControl('', [Validators.required])
    );
    this.academicsform.addControl(
      'end_year',
      new FormControl('', [Validators.required])
    );
    this.academicsform.setValidators(this.dateRangeValidator.bind(this));
  }

  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const startYear = control.get('start_year')?.value;
    const endYear = control.get('end_year')?.value;
    const startYearValue = startYear instanceof Date ? startYear.getFullYear() : parseInt(startYear, 10);
    const endYearValue = endYear instanceof Date ? endYear.getFullYear() : parseInt(endYear, 10);

    if (startYearValue && endYearValue && endYearValue !== startYearValue && endYearValue !== startYearValue + 1) {
      return { dateRangeInvalid: true };
    }
    return null;
  };

  gotosemisters() {
    this.semisterflag = !this.semisterflag;
    // this.editcourse()
  }

  closeDialog(event: any) {
    // this.dialogRef.close();
    this.gotosemisters();
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      this.dialogRef.close(event);
    }, 1000);
  }

  editcourse() {
    let editdata = {
      organization_id: 1,
      start_year: '01/01/2015',
      end_year: '02/01/2015',
      code: 'R10',
      courses: [
        {
          id: 1,
          name: 'test',
        },
        {
          id: 2,
          name: 'test1',
        },
      ],
    };

    this.academicsdata = editdata;
  }

  onSubmit() {
    this.academicsform.markAllAsTouched();
    const organizationData = localStorage.getItem('user');
    let organizationId: number | null = null;

    if (organizationData) {
      try {
        const parsedData = JSON.parse(organizationData);
        if (parsedData.data && parsedData.data.organization_id) {
          organizationId = parsedData.data.organization_id;
        } else {
          console.error('Parsed data does not contain expected structure');
        }
      } catch (error) {
        console.error('Error parsing data from localStorage:', error);
      }
    }
    if (organizationId === null) {
      console.error('Organization ID not found');
      return;
    }
    console.log("Accademic data", this.academicsform.value)
    const batchData = this.academicsform.value.batch;
    const formattedBatches = batchData.map((batch: any) => batch);
    this.datesend = {
      organization_id: organizationId,
      // start_date: new Date(this.academicsform.value.start_year).getFullYear(),
      start_date: this.academicsform.value.start_year.toJSON().slice(0, 10),
      // end_date: new Date(this.academicsform.value.end_year).getFullYear(),
      end_date: this.academicsform.value.end_year.toJSON().slice(0, 10),
      batches: formattedBatches,
    };

    this.createAPi(this.datesend);
  }

  createAPi(event: any) {
    this.apiservice.createacadamicyears(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast('success', `Successfully created`);
        this.academicsdata = res.data;
        this.closeDialog(true);
      }
      else {
        this.toast.showToast('danger', `Error: ${res.message}`);
      }
    });
  }
}
