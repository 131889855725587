import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterModule } from '@angular/router';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { environment } from '../../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SectionsService } from '../../../../inputfileds/form_services/academics/sections/sections.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { QuestionService } from '../../../../inputfileds/form_services/question/question.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SelectionModel } from '@angular/cdk/collections';
import { AddsectionsComponent } from './addsections/addsections.component';

export interface sectionView {
  index: number;
  id: number;
  organization_id: number;
  name: string;
  sequence: number;
}

@Component({
  selector: 'app-sections',
  standalone: true,
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss'],
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    DynamicFormQuestionComponent,
    CommonModule,
    MatTabsModule,
    MatDividerModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  providers: [QuestionControlService, ApiservicesService, SectionsService]
})
export class SectionsComponent {
  displayedColumns: string[] = [
    'id',
    'name',
    'sequence',
    'actions'
  ];
  dataSource = new MatTableDataSource<sectionView>();
  selection = new SelectionModel<SectionsComponent>(true, []);
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  createsection: Inputfiledsbase<any>[] | null = [];
  createsection$: Observable<Inputfiledsbase<any>[]> | null;
  sectionForm!: FormGroup;
  createsectionForm!: FormGroup;
  BatchData: any;
  datasend: any;
  sectionData: any;
  selected_semesterData: any;
  semestesubjectsData: any;
  step = 0;
  @Input() subjects: any[] = [];
  onerror = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png';
  editsubjectdata: any = null;
  isSubjectEnabled = false;
  assetUrl: string = environment.ASSET_URL;
  assetContainer: string = environment.ASSET_CONTAINER;
  isLoadingResults = true;
  isRateLimitReached = false;

  @ViewChild('createSectionDialog') createSectionDialog!: TemplateRef<any>;

  constructor(
    private router: Router,
    private apiservice: ApiservicesService,
    private dataService: SectionsService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private qcs: QuestionControlService
  ) {
    this.questions$ = dataService.getRegulationstudentfilterForm();
    this.questions$.subscribe(q => this.questions = q);
    this.createsection$ = dataService.getCreateSectionForm();
    this.createsection$.subscribe(questions => this.createsection = questions);
  }

  ngOnInit(): void {
    // this.BatchData = history.state.data;
    this.sectionForm = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.createsectionForm = this.qcs.toFormGroup(this.createsection as Inputfiledsbase<string>[]);
    this.getSectionsDetails();
  }

  getSectionsDetails(): void {
    this.apiservice.getSectionDetails().subscribe((res: any) => {
      this.sectionData = res?.rows;
      this.dataSource = new MatTableDataSource<sectionView>(res.rows);
      this.isLoadingResults = false;
      console.log("Section Data", this.sectionData);
    })
  }

  setStep(index: number, semester: any) {
    this.step = index;
    this.selected_semesterData = semester;
    // this.getsemestersubjectsDetails(semester)
  }

  openCreateSectionDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '80%';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';
    
    const dialogRef = this.dialog.open(AddsectionsComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  closeDialog(event: any) {
    // this.dialogRef.close();
    const dialogRef = this.dialog.open(AddsectionsComponent);
    this.getSectionsDetails();
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      dialogRef.close(event);
    }, 1000);
  }

  onSaveClick(): void {
    this.dialog.closeAll();
  }

  backPage(): void {
    this.router.navigate(['/academicyears']).then(() => {
      this.BatchData;
    });
  }

  onSubmit() {
    this.datasend = {
      organization_id: parseInt(this.sectionData.organization_id),
      academic_regulation_id: this.sectionData.academic_regulation_id,
      section: this.createsectionForm.value.section,
      semister: this.sectionData.semister,
      course_id: this.sectionData.course_id,
    };
    this.createAPi(this.datasend);
  }

  createAPi(event: any) {
    this.apiservice.createacademicsections(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        // this.academicsdata = res.data;
        this.closeDialog(true);
      }
    });
  }
}
