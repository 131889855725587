import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatacontrolService {

  private leaverequestcontrol = new BehaviorSubject<any>('PENDING');
  getleaverequestcontrol = this.leaverequestcontrol.asObservable();
  message: any;

  constructor() { }

  setleaverequestcontrol(request: any) {
    this.leaverequestcontrol.next(request);
    this.leaverequestcontrol.subscribe(msg => {
      this.message = msg
    })
  }
}
