<section class="container">
  <table mat-table [dataSource]="timeslotslist" class="mat-elevation-z8">
    <ng-container matColumnDef="academic_year">
      <th mat-header-cell *matHeaderCellDef> Academic Year </th>
      <td mat-cell *matCellDef="let slot"> {{slot.start_date | date: 'yyyy'}} - {{slot.end_date | date: 'yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="shift_name">
      <th mat-header-cell *matHeaderCellDef> Shift Name </th>
      <td mat-cell *matCellDef="let slot"> {{slot.shift_name}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let slot">
        <button mat-raised-button (click)="viewSlot(slot)">View</button>
      </td>
    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>
