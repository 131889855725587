import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { Observable, of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DatepickerQuestion } from '../../question-datepicker';
import { TimepickerQuestion } from '../../question-timepicker';

@Injectable({
  providedIn: 'root'
})
export class ApplyleavesService {

  organisationdata: any = [];
  leavetypedata: any = [];
  leavemodedata: any = [];

   constructor(private apiservice: ApiservicesService) {
    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('LEAVE_TYPES', this.leavetypedata);
    this.loadDropdownData('LEAVE_MODES', this.leavemodedata)
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.apiservice.getorganisation();
    } else if (code === 'LEAVE_TYPES') {
      observable = this.apiservice.getleavetypedetails();
    } else {
      observable = this.apiservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code ===    'ORGANISATION') {
          lookupname = res[i].short_name;
        } else if (code === 'LEAVE_TYPES') {
          lookupname = res[i].name;
        } else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  getApplyedLeaves(){
    const questions: Inputfiledsbase<string>[] = [
    
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization ID',
        required: true,
        options: this.organisationdata,
        order: 1
      }),

      new DropdownQuestion({
        key: 'leave_type_id',
        label: 'Leave Type',
        required: true,
        options: this.leavetypedata,
        order: 1
      }),

      new DatepickerQuestion({
        key: 'start_date',
        label: 'From Date',
        required: true,
        order: 2
      }),

      new DatepickerQuestion({
        key: 'end_date',
        label: 'To Date',
        required: true,
        order: 2
      }),

      new DropdownQuestion({
        key: 'leave_mode_id',
        label: 'Leave Mode',
        required: true,
        options: this.leavemodedata,
        order: 1
      }),

      new TextareaQuestion ({
        key: 'reason',
        label: 'Reason for Leave',
        required: true,
        order: 2
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
  getCompoffLeaves(){
    const questions: Inputfiledsbase<string>[] = [
      new TimepickerQuestion({
        key: 'time',
        label: 'Time',
        type: 'datetime',
        required: true,
        order: 1
      }),
      new DatepickerQuestion({
        key: 'from_date',
        label: 'From Date',
        required: true,
        order: 2
      }),
      new TextareaQuestion ({
        key: 'reason',
        label: 'Reason for Leave',
        required: true,
        order: 3
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
