<section class="container">
    <div class="d-flex align-items-center">
        <div>
            <button  mat-icon-button class="back_icon me-2" (click)="backnavigation()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div>
            <h4 class="m-0">Requests</h4>
        </div>
    </div>
    <div class="mt-2 table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row; columns: displayedColumns;">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> actions </th>
                <td mat-cell *matCellDef="let row">
                    <button  mat-icon-button [matMenuTriggerFor]="actionrequest" aria-label="menu" >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionrequest="matMenu">
                        <button mat-menu-item (click)="actionrequestapi('APPROVED', row?.id)">
                          <span>Approved</span>
                        </button>
                        <button mat-menu-item (click)="actionrequestapi('REJECTED', row?.id)">
                          <span>Rejected</span>
                        </button>
                      </mat-menu>
                </td>
            </ng-container>

            <ng-container matColumnDef="leave_type">
                <th mat-header-cell *matHeaderCellDef> Leave Type </th>
                <td mat-cell *matCellDef="let element"> {{element.leave_type}} </td>
            </ng-container>

            <ng-container matColumnDef="leave_mode">
                <th mat-header-cell *matHeaderCellDef> Leave Mode </th>
                <td mat-cell *matCellDef="let element"> {{element.leave_mode}} </td>
            </ng-container>

            <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef> From </th>
                <td mat-cell *matCellDef="let element"> {{element.start_date}} </td>
            </ng-container>

            <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef> To </th>
                <td mat-cell *matCellDef="let element"> {{element.end_date}} </td>
            </ng-container>

            <ng-container matColumnDef="break_type">
                <th mat-header-cell *matHeaderCellDef> Brake Type </th>
                <td mat-cell *matCellDef="let element"> {{element.break_type}} </td>
            </ng-container>

            <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef> Reason</th>
                <td mat-cell *matCellDef="let element"> {{element.reason}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="actions" sticky>
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef="let element"> 
                <div>
                    <button  mat-icon-button [matMenuTriggerFor]="actionrequest" aria-label="menu" >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #actionrequest="matMenu">
                        <button mat-menu-item (click)="actionrequestapi('APPROVED', element)">
                          <span>Approved</span>
                        </button>
                        <button mat-menu-item (click)="actionrequestapi('REJECTED', element)">
                          <span>Rejected</span>
                        </button>
                      </mat-menu>
                </div> 
            </td>
        </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
    </div>
</section>