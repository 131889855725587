<section class="container h-100">
    <div class="header justify-content-between d-flex">
        <h2>Time Table</h2>
        <div>
            <button mat-raised-button class="m-2" color="primary" type="submit">
                <!-- (click)="onSave()"
        [disabled]="!isFormValid() || isTimeSlotsEmpty()" -->
                Save
            </button>
            <button mat-raised-button color="primary" type="button" (click)="onExit()">
                Exit
            </button>
        </div>
    </div>
    <div class="">
        <form [formGroup]="timeslotform">
            <div class="row mt-4">
                <div *ngFor="let question of questions"
                    class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2">
                    <app-question [question]="question" [form]="timeslotform"></app-question>
                </div>
            </div>
        </form>
        <!-- <form [formGroup]="addTimeslotform">
            <div *ngFor="let Addquestion of timeSlots"
                class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3">
                {{Addquestion}}
            </div>
        </form> -->

        <ul class="p-0 mt-4" *ngFor="let Addquestion of timeSlots; let i = index ">
           <li class="d-flex">
            <div class="me-2">
                <label class="form-label">Time</label>
                <input class="form-control" [disabled]="true" [value]="Addquestion.start_time + '-' + Addquestion.end_time" [formControlName]="Addquestion.shift_id + i">
            </div>
            <div class="me-2">
                <label class="form-label">Day </label>
                <input class="form-control" [disabled]="true" [value]="Addquestion.start_day" [formControlName]="Addquestion.start_day + i">
            </div>
            <div class="me-2">
                <label class="form-label">Subject</label>
                <select class="form-select" [value]="Addquestion.subject_id" [formControlName]="Addquestion.subject_id + i">
                <option selected disabled [value]="0">Choose Options</option>
            </select>
            </div>
            <div class="me-2">
                <label class="form-label">Employee </label>
                <select class="form-select" [value]="Addquestion.employee_id" [formControlName]="Addquestion.employee_id + i">
                <option selected disabled [value]="0">Choose Options</option>
                </select>
            </div>
            <div class="d-flex align-items-end">
                <button mat-raised-button class="me-2" color="primary" type="button" (click)="onExit()">
                    Edit
                </button>

                <button mat-raised-button  color="primary" type="submit">
                    <!-- (click)="onSave()"
            [disabled]="!isFormValid() || isTimeSlotsEmpty()" -->
                    Save
                </button>
            
            </div>
           </li> 
        </ul>

        <!-- <mat-divider></mat-divider>
      <form [formGroup]="addTimeslotform" (ngSubmit)="addTimeSlot()">
        <div class="row mt-3 container">
          <div
            *ngFor="let Addquestion of AddSlotQuestions"
            class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3"
          >
            <app-question
              [question]="Addquestion"
              [form]="addTimeslotform"
            ></app-question>
          </div>
        </div>
        <button *ngIf="!editMode" mat-raised-button color="primary" type="submit">
          + Add Time Slot
        </button>
        <button *ngIf="editMode" mat-raised-button color="primary" type="submit">
          Update Time Slot
        </button>
      </form> -->
    </div>
</section>