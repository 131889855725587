import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { sectionView } from '../sections/sections.component';
import { environment } from '../../../../../environments/environment';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { SectionsService } from '../../../../inputfileds/form_services/academics/sections/sections.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToastComponent } from '../../../../components/toast/toast.component';
import { ToastService } from '../../../../Services/toast/toast.service';

export interface Student {
  index: number;
  Name: number;
  Dost_ID: string;
  Roll_No: string;
  Department: string;
  Academic_Year: string;
  Action: any;
}

@Component({
  selector: 'app-academicregulationsview',
  standalone: true,
  templateUrl: './academicregulationsview.component.html',
  styleUrl: './academicregulationsview.component.scss',
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatTableModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatExpansionModule,
    DynamicFormQuestionComponent,
    CommonModule,
    MatTabsModule,
    MatDividerModule,
    MatDialogModule,
    MatCheckboxModule,
    ToastComponent
  ],
  providers: [QuestionControlService, ApiservicesService, SectionsService]
})
export class AcademicregulationsviewComponent {

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  createsection: Inputfiledsbase<any>[] | null = [];
  createsection$: Observable<Inputfiledsbase<any>[]> | null;
  filtersectionForm!: FormGroup;
  createsectionForm!: FormGroup;
  BatchData: any;
  datasend: any;
  sectionData: any;
  selected_semesterData: any;
  semestesubjectsData: any;
  dataSource = new MatTableDataSource<sectionView>();
  step = 0;
  onerror = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png';
  editsubjectdata: any = null;
  isSubjectEnabled = false;
  assetUrl: string = environment.ASSET_URL;
  assetContainer: string = environment.ASSET_CONTAINER;
  displayedColumns: string[] = ['Roll_No', 'Name', 'Course_name', 'phone_number', 'email', 'Action'];
  studentdataSource = new MatTableDataSource<Student>();
  selection = new SelectionModel<Student>(true, []);
  total_count: any;
  paginationdata: any;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  file: File | null = null;
  filterdata: any[] | undefined;
  filters: any | undefined;

  @ViewChild('createSectionDialog') createSectionDialog!: TemplateRef<any>;

  constructor(
    private router: Router,
    private apiservice: ApiservicesService,
    private dataService: SectionsService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private qcs: QuestionControlService,
    private toastService: ToastService
    // private dialogRef: MatDialogRef<createSectionDialog>
  ) {
    this.questions$ = dataService.getRegulationstudentfilterForm();
    this.questions$.subscribe(q => this.questions = q);
    this.createsection$ = dataService.getCreatebatchSectionForm();
    this.createsection$.subscribe(questions => this.createsection = questions);
  }

  ngOnInit(): void {
    this.BatchData = history.state.data;
    this.filtersectionForm = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    this.createsectionForm = this.qcs.toFormGroup(this.createsection as Inputfiledsbase<string>[]);
    this.getSectionsDetails();
    this.getstudentdetails();
  }

  getSectionsDetails(): void {
    const data = {
      "id": this.BatchData.id,
      "code": this.BatchData.code
    }
    this.apiservice.getBatchDetails(data).subscribe((res: any) => {
      this.sectionData = res;
      console.log("Section Data", this.sectionData);
    })
  }

  getstudentdetails(semester_id?: number, id?: number) {
    this.filters = { semester_id, id }
    this.apiservice.getacademicstudent(this.paginationdata, this.filters).subscribe((res: any) => {
      this.studentdataSource = new MatTableDataSource<Student>(res?.rows);
      this.total_count = res.total_count;
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.studentdataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.studentdataSource.data);
  }

  checkboxLabel(row?: Student): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  pagination(event: any) {
    this.paginationdata = event;
    console.log('event from pagination', event);
    this.getstudentdetails();
    this.filterStudents(event);
  }

  filterStudents(event: Event) {
    const formValues = this.filtersectionForm.value;
    this.getstudentdetails(formValues.semester_id,formValues.id);
  }


  setStep(index: number, semester: any) {
    this.step = index;
    this.selected_semesterData = semester;
    // this.getsemestersubjectsDetails(semester)
  }

  openCreateSectionDialog(): void {
    const dialogRef = this.dialog.open(this.createSectionDialog, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  closeDialog(event: any) {
    this.dialog.closeAll();
    // const dialogRef = this.dialog.open(this.createSectionDialog);
    this.getSectionsDetails();
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      this.dialog.closeAll();
    }, 1000);
  }

  onSaveClick(): void {
    this.dialog.closeAll();
  }

  backPage(): void {
    this.router.navigate(['/academicregulations']).then(() => {
      this.BatchData;
    });
  }

  onSubmit() {
    // const datastringfy =  JSON.stringify(this.createsectionForm.value.section_id);
    // const datapared = JSON.parse(datastringfy);
    // console.log("data of object dropdown", datapared, this.createsectionForm.value.section_id)
    this.datasend = {
      organization_id: parseInt(this.sectionData.organization_id),
      courses: [{id:this.sectionData.course_id, name:this.sectionData.course_name}],
      start_year: this.sectionData.start_year,
      end_year: this.sectionData.end_year,
      code: this.sectionData.code,
      section_id: parseInt(this.createsectionForm.value.section_id)
    };
    this.createAPi(this.datasend);
  }

  createAPi(event: any) {
    this.apiservice.createacadamics(event).subscribe(
      (res: any) => {
        if (res.code === 201 && res.status === true) {
          this.toastService.showToast('success', 'Saved Successfully');
          this.closeDialog(true);
        } else if (res.status === false) {
          this.toastService.showToast('danger', res.message);
        }
      },
      (error) => {
        this.toastService.showToast('warning', `Error: ${error.message}`);
      }
    );
  }
}
