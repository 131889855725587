import { Component, NgModule, OnInit, Input, ViewChild, ElementRef, model } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from "../../inputfileds/dynamic-form-question/dynamic-form-question.component";
import { StudentService } from '../../inputfileds/form_services/student/student.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { PaginationComponent } from '../../components/pagination/pagination.component';
import { UploadDialogComponent } from '../../components/upload-dialog/upload-dialog.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatOption, MatSelect } from '@angular/material/select';
import { ToastService } from '../../Services/toast/toast.service';
import { ToastComponent } from '../../components/toast/toast.component';

export interface Student {
  index: number;
  Name: number;
  Dost_ID: string;
  Roll_No: string;
  Department: string;
  Academic_Year: string;
  Action: any;
}

export interface StudentFormdata {
  "personal_details": {
    "organization_id": number,
    "user_role_id": number,
    "first_name": string,
    "last_name": string,
    "course_id": number,
    "phone_number": string,
    "email": string,
    "password": string,
    "hallTicket_number": number,
    "dost_id": number,
    "date_of_birth": string,
    "adhar_number": string,
    "caste_id": number,
    "sub_caste": string,
    "gender_id": number,
    "religion_id": number,
    "academic_regulation_id": number,
    "year": string,
    "semister": string,
    "roll_number": string,
    "status_id": number,
    "joinging_date": string,
    "course_completion_date": string,
    "hobbies": string,
    "interested_extra_activities": string,
    "participation_in_ncc_nss": string,
    "participation_in_sports_games": string,
    "future_goal": string
  },
  "education_qualifications": [
    {
      "qualification_id": number,
      "start_year": string,
      "end_year": string,
      "university_name": string,
      "institution_name": string,
      "course_name": string,
      "percentage": string
    }
  ],
  "addresses": [
    {
      "country_id": number,
      "state_id": number,
      "address_line1": string,
      "address_line2": string,
      "city": string,
      "pincode": string
    },
    {
      "country_id": number,
      "state_id": number,
      "address_line1": string,
      "address_line2": string,
      "city": string,
      "pincode": string
    }
  ],
  "family_details": {
    "fathers_name": string,
    "mothers_name": string,
    "fathers_occupation": string,
    "mothers_occupation": string,
    "annual_income": string,
    "parent_contact_number": string,
    "number_of_brothers": string,
    "number_of_sisters": string
  }
}

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss'],
})
export class StudentComponent implements OnInit {
  StudentPersonalForm!: FormGroup;
  StudentEducationForm!: FormGroup;
  StudentAddressForm!: FormGroup;
  StudentFamilyForm!: FormGroup;
  StudentFilterForm!: FormGroup;
  StudentPresentAddressForm!: FormGroup;
  StudentPermanentAddressForm!: FormGroup;
  payLoad = '';
  StudentEducationalFormQuestions: any[] | null = [];
  StudentFamilyFormQuestions: any[] | null = [];
  StudentPersonalFormQuestions: any[] = [];
  StudentAddressFormQuestions: any[] = [];
  StudentFilterFormQuestions: any[] = [];
  StudentPersonalFormQuestions$: Observable<any[]> | null = null;
  StudentEduactionFormQuestions$: Observable<any[]> | null = null;
  StudentFamilyFormQuestions$: Observable<any[]> | null = null;
  StudentAddressFormQuestions$: Observable<any[]> | null = null;
  StudentFilterFormQuestions$: Observable<any[]> | null = null;
  getStudentData = [];
  filterdata: any[] | undefined;
  url: string | ArrayBuffer | null | undefined;
  showaddflag: boolean = false;
  @ViewChild(MatSort) sort!: MatSort;
  selectedFilter = '';
  @ViewChild(MatMenuTrigger) filterMenu!: MatMenuTrigger;
  filters: any | undefined;
  checked: boolean = false;
  readonly indeterminate = model(false);
  errorMessage: string | null = null;
  educationaddformarry: any = [];
  addressesformarry: any = [];
  pipe = new DatePipe('en-US');
  total_count: any;
  paginationdata: any;
  fileName: string | null = null;
  selectedImageUrl: string | ArrayBuffer | null = null;
  file: File | null = null;
  dataId: number | null = null;
  editingStudentId: number | null = null;
  isEditMode: boolean = false;
  editstudentarrayflag: boolean = true;
  editeducationindex: any;
  editeducationid: any;
  editeducationarrayflag: boolean = true;

  constructor(
    private fb: FormBuilder,
    private studentService: StudentService,
    private apiservice: ApiservicesService,
    private router: Router,
    public dialog: MatDialog,
    private toast: ToastService
  ) {
    this.StudentFilterForm = this.fb.group({
      courseId: [''],
      id: ['']
    });
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  addresscheckbox() {
    if (this.checked) {
      this.StudentPermanentAddressForm.setValue(this.StudentPresentAddressForm.getRawValue())
    } else {
      this.StudentPermanentAddressForm.reset();
    }
  }

  getstudentdetails(courseId?: number, id?: number) {
    this.filters = { courseId, id }
    this.apiservice.getstudentlist(this.paginationdata, this.filters).subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<Student>(res?.rows);
      this.total_count = res.total_count;
    });
  }

  displayedColumns: string[] = ['Roll_No', 'Name', 'Course_name', 'phone_number', 'email', 'Action'];
  dataSource = new MatTableDataSource<Student>(this.getStudentData);
  selection = new SelectionModel<Student>(true, []);
  students: Student[] = this.getStudentData;

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: Student): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  pagination(event: any) {
    this.paginationdata = event;
    console.log('event from pagination', event);
    this.getstudentdetails();
    this.filterStudents(event);
  }



  ngOnInit() {

    this.getstudentdetails();

    this.StudentPersonalForm = this.fb.group({});
    this.StudentEducationForm = this.fb.group({});
    this.StudentFamilyForm = this.fb.group({});
    this.StudentAddressForm = this.fb.group({});
    this.StudentPresentAddressForm = this.fb.group({});
    this.StudentPermanentAddressForm = this.fb.group({});
    this.StudentPersonalFormQuestions$ = this.studentService.getstudentform();
    this.StudentEduactionFormQuestions$ = this.studentService.getEducationalDetails();
    this.StudentFamilyFormQuestions$ = this.studentService.getFamilyForm();
    this.StudentAddressFormQuestions$ = this.studentService.getAddressForm();
    this.StudentFilterFormQuestions$ = this.studentService.getFilterDropdowns();

    this.StudentPersonalFormQuestions$?.subscribe((q) => {
      this.StudentPersonalFormQuestions = q;
      this.populateFormGroups();
    });

    this.StudentEduactionFormQuestions$.subscribe((q) => {
      this.StudentEducationalFormQuestions = q;
      this.populateFormGroups();
    });

    this.StudentFamilyFormQuestions$.subscribe((q) => {
      this.StudentFamilyFormQuestions = q;
      this.populateFormGroups();
    });

    this.StudentAddressFormQuestions$.subscribe((q) => {
      this.StudentAddressFormQuestions = q;
      this.populateFormGroups();
    });
    this.StudentFilterFormQuestions$.subscribe((q) => {
      this.StudentFilterFormQuestions = q;
    });
    this.filterdata = [...this.StudentPersonalFormQuestions]
    this.filterdata.splice(0, 6);
  }

  populateFormGroups() {
    for (const question of this.StudentPersonalFormQuestions) {
      this.StudentPersonalForm.addControl(question.key, this.fb.control('', question?.required ? Validators.required : []));
    }
    for (const question of this.StudentEducationalFormQuestions || []) {
      this.StudentEducationForm.addControl(question.key, this.fb.control('', question?.required ? Validators.required : []));
    }
    for (const question of this.StudentFamilyFormQuestions || []) {
      this.StudentFamilyForm.addControl(question.key, this.fb.control('', question?.required ? Validators.required : []));
    }
    // for (const question of this.StudentAddressFormQuestions || []) {
    //   this.StudentAddressForm.addControl(question.key, this.fb.control('', question?.required ? Validators.required : []));
    // }
    for (const question of this.StudentAddressFormQuestions || []) {
      this.StudentPresentAddressForm.addControl(question.key, this.fb.control('', <any>question?.required ? Validators.required : []));
    }
    for (const question of this.StudentAddressFormQuestions || []) {
      this.StudentPermanentAddressForm.addControl(question.key, this.fb.control('', <any>question?.required ? Validators.required : []));
    }
    for (const question of this.StudentFilterFormQuestions || []) {
      this.StudentFilterForm.addControl(question.key, this.fb.control('', <any>question?.required ? Validators.required : []));
      console.log("inside for loop===>", this.StudentFilterForm.value);
    }
  }

  resetform() {
    // Reseting form / empty form feilds 
    this.StudentPersonalForm.reset();
    this.StudentEducationForm.reset();
    this.StudentFamilyForm.reset();
    this.StudentAddressForm.reset();
  }

  showadd(event: boolean) {
    this.showaddflag = event;
    this.StudentPersonalForm.reset();
    this.StudentEducationForm.reset();
    this.StudentFamilyForm.reset()
    this.educationaddformarry = [];
    this.addressesformarry = [];
    this.StudentPresentAddressForm.reset();
    this.StudentPermanentAddressForm.reset();
    this.addeducation();
    if (!event) {
      this.getstudentdetails();
    }
  }

  gotostudentview(event: any) {
    this.router.navigate(['studentview/'+ event.id ])
  }

  changingids(event: any, index: number) {
    event.id = event.key + index
    return event
  }

  filterStudents(event: Event) {
    const formValues = this.StudentFilterForm.value;
    this.getstudentdetails(formValues.courseId, formValues.id);
    this.filterMenu.closeMenu();
  }

  clearFilter() {
    this.StudentFilterForm.reset();
    this.getstudentdetails();
    this.filterMenu.closeMenu();
  }

  arraySubmit() {
    if (this.StudentEducationForm.valid) {
      this.educationaddformarry.push(this.StudentEducationForm.getRawValue());
      // this.openSnackBar();
      this.StudentEducationForm.reset();
    }
  }

  // editarrySubmit(index:Number){
  //   this.educationaddformarry[index].append(this.EmployeeEducationForm.getRawValue())
  // }

  addeducation() {
    this.StudentEducationForm.reset();
  }

  editarrayform(event: any, index: number) {
    this.editeducationarrayflag = false;
    this.editeducationindex = index;
    this.editeducationid = event.id;
    this.StudentEducationForm.patchValue(event)
  }

  editarrySubmit() {
    console.log("index for edit", this.editeducationindex)
    // this.educationaddformarry[this.editeducationindex].append(
    //   this.EmployeeEducationForm.getRawValue()
    // );
    this.educationaddformarry[this.editeducationindex] = this.StudentEducationForm.getRawValue();
    this.educationaddformarry[this.editeducationindex].id = this.editeducationid;
    this.reseteducationform();
    console.log("education edit data", this.educationaddformarry);
    this.editeducationarrayflag = true;
    this.editeducationindex = null;
  }
  reseteducationform() {
    console.log('Array of education', this.educationaddformarry);
    this.StudentEducationForm.reset();
  }

  deletearrayform(event: number) {
    this.educationaddformarry.splice(event, 1);
  }

  imageInputChange(fileInputEvent: any) {
    this.file = fileInputEvent.target.files[0];
    this.fileName = fileInputEvent.target.files[0].name;
    if (this.file) {
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = () => {
        this.selectedImageUrl = reader.result;
      };
    }
  }

  updateImage(file: File, id: number): void {
    this.apiservice.updatestudentimage(file, id).subscribe(res => {
      this.getstudentdetails();
    }, err => {
      console.error("Image upload error", err);
      this.errorMessage = "Image upload failed. Please try again.";
    });
  }

  onSubmit() {
    let address: any[] = [];
    // let education: any[] = [];

    // Get form values
    // education.push(this.StudentEducationForm.getRawValue());
    address.push(this.StudentPresentAddressForm.getRawValue());
    address.push(this.StudentPermanentAddressForm.getRawValue());

    // Prepare the student object to send to the API
    let apistudentObjecttosend = {
      "personal_details": {
        "organization_id": this.StudentPersonalForm.value.organization_id ? parseInt(this.StudentPersonalForm.value.organization_id) : null,
        "user_role_id": this.StudentPersonalForm.value.user_role_id ? parseInt(this.StudentPersonalForm.value.user_role_id) : null,
        "first_name": this.StudentPersonalForm.value.first_name,
        "last_name": this.StudentPersonalForm.value.last_name,
        "course_id": this.StudentPersonalForm.value.course_id ? parseInt(this.StudentPersonalForm.value.course_id) : null,
        "phone_number": this.StudentPersonalForm.value.phone_number,
        "email": this.StudentPersonalForm.value.email,
        "password": this.StudentPersonalForm.value.password,
        "hallTicket_number": this.StudentPersonalForm.value.hallTicket_number,
        "date_of_birth": this.pipe.transform(this.StudentPersonalForm.value.date_of_birth, 'MM/dd/yyyy'),
        "adhar_number": this.StudentPersonalForm.value.adhar_number,
        "caste_id": this.StudentPersonalForm.value.caste_id ? parseInt(this.StudentPersonalForm.value.caste_id) : null,
        "sub_caste": this.StudentPersonalForm.value.sub_caste,
        "gender_id": this.StudentPersonalForm.value.gender_id ? parseInt(this.StudentPersonalForm.value.gender_id) : null,
        "religion_id": this.StudentPersonalForm.value.religion_id ? parseInt(this.StudentPersonalForm.value.religion_id) : null,
        "academic_regulation_id": this.StudentPersonalForm.value.academic_regulation_id ? parseInt(this.StudentPersonalForm.value.academic_regulation_id) : null,
        "year": this.StudentPersonalForm.value.year ? parseInt(this.StudentPersonalForm.value.year) : null,
        "semister": this.StudentPersonalForm.value.semister ? parseInt(this.StudentPersonalForm.value.semister) : null,
        "roll_number": this.StudentPersonalForm.value.roll_number,
        "joinging_date": this.pipe.transform(this.StudentPersonalForm.value.joinging_date, 'MM/dd/yyyy'),
        "course_completion_date": this.pipe.transform(this.StudentPersonalForm.value.course_completion_date, 'MM/dd/yyyy'),
        "status_id": this.StudentPersonalForm.value.status_id ? parseInt(this.StudentPersonalForm.value.status_id) : null,
        "hobbies": this.StudentPersonalForm.value.hobbies,
        "interested_extra_activities": this.StudentPersonalForm.value.interested_extra_activities,
        "participation_in_ncc_nss": this.StudentPersonalForm.value.participation_in_ncc_nss,
        "participation_in_sports_games": this.StudentPersonalForm.value.participation_in_sports_games,
        "future_goal": this.StudentPersonalForm.value.future_goal,
      },
      "educational_qualifications": this.educationaddformarry,
      "addresses": address,
      "family_details": {
        "fathers_name": this.StudentFamilyForm.value.fathers_name,
        "mothers_name": this.StudentFamilyForm.value.mothers_name,
        "fathers_occupation": this.StudentFamilyForm.value.fathers_occupation,
        "mothers_occupation": this.StudentFamilyForm.value.mothers_occupation,
        "annual_income": this.StudentFamilyForm.value.annual_income,
        "parent_contact_number": this.StudentFamilyForm.value.parent_contact_number,
        "number_of_brothers": parseInt(this.StudentFamilyForm.value.number_of_brothers),
        "number_of_sisters": parseInt(this.StudentFamilyForm.value.number_of_sisters)
      }
    };
    console.log("Student ID check==>", this.editingStudentId)
    if (this.editingStudentId !== null) {
      this.updateStudent(this.editingStudentId);
    } else {
      this.createAPi(apistudentObjecttosend);
    }
  }

  createAPi(event: any) {
    this.apiservice.createstudents(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast(
          'success',
          'Submited Successfuly',);
        this.showadd(false);
        if (this.file) {
          this.updateImage(this.file, res.data.id);
        }
      } else {
        this.toast.showToast('danger', `Error: ${res.message}`);
        this.errorMessage = res.message;
      }
    });
  }

  updateStudent(studentId: number) {
    // Prepare the student data object, similar to the one in onSubmit
    let address: any[] = [];
    // let education: any[] = [];

    // Get form values
    // education.push(this.StudentEducationForm.getRawValue());
    address.push(this.StudentPresentAddressForm.getRawValue());
    address.push(this.StudentPermanentAddressForm.getRawValue());
    address[0].id = this.addressesformarry[0].id;
    address[1].id = this.addressesformarry[1].id;
    let apistudentObjecttosend = {
      "personal_details": {
        "organization_id": this.StudentPersonalForm.value.organization_id ? parseInt(this.StudentPersonalForm.value.organization_id) : null,
        "user_role_id": this.StudentPersonalForm.value.user_role_id ? parseInt(this.StudentPersonalForm.value.user_role_id) : null,
        "first_name": this.StudentPersonalForm.value.first_name,
        "last_name": this.StudentPersonalForm.value.last_name,
        "course_id": this.StudentPersonalForm.value.course_id ? parseInt(this.StudentPersonalForm.value.course_id) : null,
        "phone_number": this.StudentPersonalForm.value.phone_number,
        "email": this.StudentPersonalForm.value.email,
        "password": this.StudentPersonalForm.value.password,
        "hallTicket_number": this.StudentPersonalForm.value.hallTicket_number,
        "date_of_birth": this.pipe.transform(this.StudentPersonalForm.value.date_of_birth, 'MM/dd/yyyy'),
        "adhar_number": this.StudentPersonalForm.value.adhar_number,
        "caste_id": this.StudentPersonalForm.value.caste_id ? parseInt(this.StudentPersonalForm.value.caste_id) : null,
        "sub_caste": this.StudentPersonalForm.value.sub_caste,
        "gender_id": this.StudentPersonalForm.value.gender_id ? parseInt(this.StudentPersonalForm.value.gender_id) : null,
        "religion_id": this.StudentPersonalForm.value.religion_id ? parseInt(this.StudentPersonalForm.value.religion_id) : null,
        "academic_regulation_id": this.StudentPersonalForm.value.academic_regulation_id ? parseInt(this.StudentPersonalForm.value.academic_regulation_id) : null,
        "year": this.StudentPersonalForm.value.year ? parseInt(this.StudentPersonalForm.value.year) : null,
        "semister": this.StudentPersonalForm.value.semister ? parseInt(this.StudentPersonalForm.value.semister) : null,
        "roll_number": this.StudentPersonalForm.value.roll_number,
        "joinging_date": this.pipe.transform(this.StudentPersonalForm.value.joinging_date, 'MM/dd/yyyy'),
        "course_completion_date": this.pipe.transform(this.StudentPersonalForm.value.course_completion_date, 'MM/dd/yyyy'),
        "status_id": this.StudentPersonalForm.value.status_id ? parseInt(this.StudentPersonalForm.value.status_id) : null,
        "hobbies": this.StudentPersonalForm.value.hobbies,
        "interested_extra_activities": this.StudentPersonalForm.value.interested_extra_activities,
        "participation_in_ncc_nss": this.StudentPersonalForm.value.participation_in_ncc_nss,
        "participation_in_sports_games": this.StudentPersonalForm.value.participation_in_sports_games,
        "future_goal": this.StudentPersonalForm.value.future_goal,
      },
      "educational_qualifications": this.educationaddformarry, // Updated to use the local variable
      "addresses": address, // Updated to use the local variable
      "family_details": {
        "fathers_name": this.StudentFamilyForm.value.fathers_name,
        "mothers_name": this.StudentFamilyForm.value.mothers_name,
        "fathers_occupation": this.StudentFamilyForm.value.fathers_occupation,
        "mothers_occupation": this.StudentFamilyForm.value.mothers_occupation,
        "annual_income": this.StudentFamilyForm.value.annual_income,
        "parent_contact_number": this.StudentFamilyForm.value.parent_contact_number,
        "number_of_brothers": parseInt(this.StudentFamilyForm.value.number_of_brothers),
        "number_of_sisters": parseInt(this.StudentFamilyForm.value.number_of_sisters)
      }
    };
    this.apiservice.editStudentDetails(studentId, apistudentObjecttosend).subscribe(
      (res: any) => {
        if (res.code == 204) {
          this.editeducationarrayflag = true
          this.toast.showToast('success', 'Student updated successfully');
          // Optionally, redirect or show a success message to the user
          this.showadd(false); // Close the form if needed
        } else {
          this.toast.showToast('danger', `Error: ${res.message}`);
          this.errorMessage = res.message;
        }
      },
      (error) => {
        // Handle the error
        console.error("Error updating student details", error);
        this.toast.showToast('danger', `Error: ${error.message}`);
      }
    );
  }


  // onFilterChange(event: any) {
  //   console.log('Selected filter:', event.value);
  // }

  // filterStudents(event: any) {
  //   const selectedCourseId = this.StudentFilterForm.get('selectedCourse')?.setValue(event.value);;
  //   console.log('Selected students:', selectedCourseId)
  //   this.paginationdata = { course_id: selectedCourseId };
  //   this.getstudentFilterList(selectedCourseId);
  // }

  menuClosed(event: any) {
    console.log('Menu closed:', event);
  }

  csvInputChange(fileInputEvent: any) {
    this.uploaddata(fileInputEvent.target.files[0])
  }

  uploaddata(event: any) {
    this.apiservice.bulkuploadstudent(event).subscribe(res => {
      console.log("student image upload", res);
      this.getstudentdetails();
    })
  }

  editForm(event: any) {
    this.showadd(true);
    this.isEditMode = true;
    this.editingStudentId = event.id
    this.apiservice.getstudentdetails(event.id).subscribe(
      (studentData: StudentFormdata) => {
        const educationalQualifications = studentData.education_qualifications || [];
        const addresses = studentData.addresses || [];
        const editData = {
          personal_details: {
            organization_id: studentData?.personal_details?.organization_id,
            user_role_id: studentData?.personal_details?.user_role_id,
            first_name: studentData?.personal_details?.first_name,
            last_name: studentData?.personal_details?.last_name,
            course_id: studentData?.personal_details?.course_id,
            phone_number: studentData?.personal_details?.phone_number,
            email: studentData?.personal_details?.email,
            date_of_birth: studentData?.personal_details?.date_of_birth,
            adhar_number: studentData?.personal_details?.adhar_number,
            caste_id: studentData?.personal_details?.caste_id,
            password: "",
            hallTicket_number: studentData?.personal_details?.hallTicket_number,
            academic_regulation_id: studentData?.personal_details?.academic_regulation_id,
            sub_caste: studentData?.personal_details?.sub_caste,
            gender_id: studentData?.personal_details?.gender_id,
            religion_id: studentData?.personal_details?.religion_id,
            year: studentData?.personal_details?.year,
            semister: studentData?.personal_details?.semister,
            roll_number: studentData?.personal_details?.roll_number,
            status_id: studentData?.personal_details?.status_id,
            joinging_date: studentData?.personal_details?.joinging_date,
            course_completion_date: studentData?.personal_details?.course_completion_date,
            hobbies: studentData?.personal_details?.hobbies,
            interested_extra_activities: studentData?.personal_details?.interested_extra_activities,
            participation_in_ncc_nss: studentData?.personal_details?.participation_in_ncc_nss,
            participation_in_sports_games: studentData?.personal_details?.participation_in_sports_games,
            future_goal: studentData?.personal_details?.future_goal
          },
          education_qualifications: educationalQualifications.map(
            (qualification: any) => (
              {
                id: qualification.id,
                qualification_id: qualification.qualification_id,
                start_year: qualification.start_year,
                end_year: qualification.end_year,
                university_name: qualification.university_name,
                institution_name: qualification.institution_name,
                course_name: qualification.course_name,
                percentage: qualification.percentage
              })
          ),
          addresses: addresses.map((address: any) => ({
            id: address.id,
            country_id: address.country_id,
            state_id: address.state_id,
            address_line1: address.address_line1,
            address_line2: address.address_line2,
            pin_code: address.pincode,
            city: address.city
          })),
          family_details: {
            fathers_name: studentData?.family_details?.fathers_name,
            mothers_name: studentData?.family_details?.mothers_name,
            fathers_occupation: studentData?.family_details?.fathers_occupation,
            mothers_occupation: studentData?.family_details?.mothers_occupation,
            annual_income: studentData?.family_details?.annual_income,
            parent_contact_number: studentData?.family_details?.parent_contact_number,
            number_of_brothers: studentData?.family_details?.number_of_brothers,
            number_of_sisters: studentData?.family_details?.number_of_sisters
          }
        };
        console.log("Number of addresses:", editData.addresses[0]);
        this.StudentPersonalForm.setValue(editData.personal_details);
        this.educationaddformarry = [...editData.education_qualifications];
        this.addressesformarry = [...editData.addresses];
        this.StudentPresentAddressForm.patchValue(editData.addresses[0]);
        this.StudentPermanentAddressForm.patchValue(editData.addresses[1]);

        this.StudentFamilyForm.patchValue(editData.family_details);
      },
      (error) => {
        console.error("Error fetching student details:", error);
      }
    );
  }



  openUploadDialog(uploadType: 'details' | 'images', formDataKey: 'studentImages' | 'STUDENTS_BULKUPLOAD_TEMPLATE'): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      data: { uploadType, formDataKey }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

@NgModule({
  declarations: [
    StudentComponent,
  ],
  exports: [StudentComponent],
  imports: [
    PaginationComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonModule,
    MatSortModule,
    MatInputModule,
    DynamicFormQuestionComponent,
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatIconModule,
    MatSelect,
    MatOption,
    ToastComponent
  ]
})
export class StudentModule { }
