import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, skip } from 'rxjs/operators';
import { SettingServiceService } from '../setting-service.service';
import { json } from 'stream/consumers';
import { environment } from '../../../environments/environment.prod';
import { format } from 'path';
import { ToastService } from '../toast/toast.service';
import { AuthenticationService } from '../../components/Authentication/services/authentication.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {
  private userdata: any;
  private userdatta: any;
  public demodata: Observable<Object> | undefined;
  private header: any;
  private fileheader: any;
  constructor(private http: HttpClient, private router: Router, private toast: ToastService, private AuthenticationService: AuthenticationService) {
    if (typeof localStorage !== 'undefined') {
      this.userdata = JSON.parse(`${typeof localStorage !== 'undefined' ? localStorage.getItem('user') : undefined}`);
      this.userdatta = this.userdata;
      this.header = {
        'token': this.userdatta?.data.token,
        'content-type': 'application/json'
      };
      this.fileheader = {
        'token': this.userdatta?.data.token
      };
    } else {
      console.warn('localStorage is not available in this environment');
      this.userdata = null;
      this.userdatta = null;
      this.header = null;
    }
  }

  getpasswordemployee() {
    return this.http.post(`${environment.API_URL}` + 'commonservice/employeePassword', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getpasswordstudent() {
    return this.http.post(`${environment.API_URL}` + 'commonservice/studentPassword', { headers: { 'token': this.userdatta?.data.token } }).pipe(catchError(this.handleError))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getlookupcodeslist(paginationdata: any) {
    return this.http.get<any>(`${environment.API_URL}` + 'lookupcodes?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcastesdetails(data: any) {
    return this.http.get<any>(`${environment.API_URL}` + '&lookupType=USER_ROLES', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getassignleavelist() {
    return this.http.get<any>(`${environment.API_URL}` + 'assignedEmployeeLeaves?type=type=list&limit=1000', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcourseslist(paginationdata?: any) {
    return this.http.get<any>(`${environment.API_URL}` + 'courses?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getTimeslotsByShiftId(shift_id: number) {
    return this.http.get<any>(`${environment.API_URL}` + 'timeslots?type=list&shiftId=' + shift_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getTimeTabledata(event: any) {
    const body = event;
    return this.http.get<any>(`${environment.API_URL}` + 'timetables?type=create&shift_id=' + event.shift_id + '&course_id=' + event.course_id + '&semister_id=' + event.semister_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
          return data
        }
      }))
  }

  getviewTimeTabledata(event: any) {
    const body = event;
    return this.http.get<any>(`${environment.API_URL}` + 'timetables/' +  event.timetable_id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
          return data
        }
      }))
  }

  createTimeTabledata(event: any) {
    const body = event;
    return this.http.post<any>(`${environment.API_URL}` + 'timetables', body, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
          return data
        }
      }))
  }

  gettimetablelist() {
    return this.http.get<any>(`${environment.API_URL}` + 'timetables?type=list', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getstreamlist(paginationdata: any) {
    return this.http.get(`${environment.API_URL}` + 'streams?type=list&limit='+ paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createlookupcode(lookupcode: any): Observable<any> {
    // const header = { 'content-type': 'application/json'};
    const body = lookupcode;
    return this.http.post<any>(`${environment.API_URL}` + 'lookupcodes', body, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
          return data
        }
      }))
  }
  bulkuploadstudent(uploaddata: any): Observable<any> {
    // let fomdata: FormData = new FormData();
    // fomdata.append('STUDENTS_BULKUPLOAD_TEMPLATE', uploaddata);
    const body = uploaddata;
    return this.http.post<any>(`${environment.API_URL}` + 'students/bulkupload', body, { headers: this.fileheader }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
        }
      }))
    //   console.log("api upload data", uploaddata);
    //   const header = { 
    //     'token': this.userdatta?.data.token,
    //     'content-type':  'multipart/form-data',
    //     'Accept' : 'application/json'
    // }   
    //   let filesdata  = new FormData();   
    //   filesdata.append('EMPLOYEES_BULKUPLOAD_TEMPLATE' ,uploaddata);
    //   console.log("files data", filesdata)
    //   // const body = {"EMPLOYEES_BULKUPLOAD_TEMPLATE" : JSON.stringify(uploaddata)};
    //   return this.http.post<any>(`${environment.API_URL}` + 'students/bulkupload', filesdata, { headers: header }).pipe(
    //     map(data => {
    //       if (data?.code == '200' && data?.status === true) {
    //         return data
    //       } else {
    //         catchError(this.handleError)
    //       }
    //     }))
  }
  bulkuploademployee(uploaddata: any): Observable<any> {
    // let formData = new FormData();
    // formData.append('EMPLOYEES_BULKUPLOAD_TEMPLATE', uploaddata);
    const body = uploaddata;
    return this.http.post<any>(`${environment.API_URL}` + 'employees/bulkupload', body, { headers: this.fileheader }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
        }
      }))
  }

  bulkuploadimages(uploaddata: any): Observable<any> {
    // let fomdata: FormData = new FormData();
    // fomdata.append('EMPLOYEES_BULKUPLOAD_TEMPLATE', uploaddata);
    const body = uploaddata;
    return this.http.post<any>(`${environment.API_URL}` + 'commonservice/bulkimagesUpload', body, { headers: this.fileheader }).pipe(
      map(data => {
        if (data?.code == '200') {
          return data
        } else {
          catchError(this.handleError)
        }
      }))
  }


  bulkuploadsubjects(uploaddata: any): Observable<any> {
    let fomdata: FormData = new FormData();
    fomdata.append('subjectsBulkuploadFile', uploaddata);
    const body = fomdata;
    return this.http.post<any>(`${environment.API_URL}` + 'subjects/bulkupload', body, { headers: this.header }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
        }
      }))
  }

  createorganization(data: any): Observable<any> {
    const body = (data);
    return this.http.post(`${environment.API_URL}` + 'organizations', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createlookuptype(lookuptype: any): Observable<any> {
    const header = {
      'content-type': 'application/json',
      'token': this.userdatta?.data.token
    };
    const body = lookuptype;
    return this.http.post(`${environment.API_URL}` + 'lookuptypes', body, { 'headers': header }).pipe(map(Data => {
      this.dismissError();
      return Data
    }))
  }

  createdepartment(departments: any): Observable<any> {
    const body = departments;
    return this.http.post(`${environment.API_URL}` + 'departments', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createcourses(courses: any): Observable<any> {
    const body = courses;
    return this.http.post(`${environment.API_URL}` + 'courses', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createtimeslots(timeslots: any): Observable<any> {
    const body = timeslots;
    return this.http.post(`${environment.API_URL}` + 'timeslots', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createsemester(semester: any): Observable<any> {
    const body = semester;
    return this.http.post(`${environment.API_URL}` + 'semisters', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createacadamics(courses: any): Observable<any> {
    const body = courses;
    return this.http.post(`${environment.API_URL}` + 'academicregulations', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createacadamicssections(courses: any): Observable<any> {
    const body = courses;
    return this.http.post(`${environment.API_URL}` + 'organizationwisesections', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createacadamicyears(batches: any): Observable<any> {
    const body = batches;
    console.log("create batches", body);
    return this.http.post(`${environment.API_URL}` + 'academiccalendar', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createacademicyearsemisters(courses: any): Observable<any> {
    const body = courses;
    return this.http.post(`${environment.API_URL}` + 'academicyearsemisters', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createacademicsections(courses: any): Observable<any> {
    const body = courses;
    return this.http.post(`${environment.API_URL}` + 'academicsections', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createsections(sections: any): Observable<any> {
    const body = sections;
    return this.http.post(`${environment.API_URL}` + 'organizationacademicsections', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  Editsemester(semester: any, semester_id: number): Observable<any> {
    const body = semester;
    return this.http.put(`${environment.API_URL}` + 'semisters/' + semester_id, body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  Deletesemester(semester_id: number): Observable<any> {
    return this.http.delete(`${environment.API_URL}` + 'semisters/' + semester_id, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createsubjects(subjects: any): Observable<any> {
    delete subjects.subjectImage;
    const body = subjects;
    return this.http.post(`${environment.API_URL}` + 'subjects', body, { headers: this.header }).pipe(
      map((data: any) => {
        return data
      }),
      catchError(this.handleError))
  }

  editsubjects(subjects: any, subject_id: number): Observable<any> {
    delete subjects.subjectImage;
    const body = subjects;
    return this.http.put(`${environment.API_URL}` + 'subjects/' + subject_id, body, { headers: this.header }).pipe(
      map((data: any) => {
        return data
      }),
      catchError(this.handleError))
  }

  editStudentDetails(studentId: number, studentData: any): Observable<any> {
    const body = studentData;
    return this.http.put(`${environment.API_URL}` + 'students/' + studentId, body, { headers: this.header }).pipe(
      map((data: any) => {
        return data
      }),
      catchError(this.handleError))
  }

  editEmployeeDetails(employees: any, employee_id: number): Observable<any> {
    delete employees.employeesImage;
    const body = employees;
    return this.http.put(`${environment.API_URL}` + 'employees/' + employee_id, body, { headers: this.header }).pipe(
      map((data: any) => {
        return data
      }),
      catchError(this.handleError))
  }

  createsubjectsimage(ID: number, imagedata: any): Observable<any> {
    let formdata: FormData = new FormData();
    formdata.append('subjectImage', imagedata);
    const body = formdata;
    return this.http.put(`${environment.API_URL}` + 'subjects/' + ID, body, { headers: this.fileheader }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createstudents(data: any): Observable<any> {
    const body = (data);
    console.log('creating', data);
    return this.http.post(`${environment.API_URL}` + 'students', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createleavetypes(data: any): Observable<any> {
    const body = (data);
    return this.http.post(`${environment.API_URL}` + 'leavetypes', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createemployees(data: any): Observable<any> {
    const body = (data);
    return this.http.post(`${environment.API_URL}` + 'employees', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createholidays(data: any): Observable<any> {
    const body = (data);
    return this.http.post(`${environment.API_URL}` + 'holidaycalendar', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  createweekoff(data: any): Observable<any> {
    const body = (data);
    return this.http.post(`${environment.API_URL}` + 'weekoffcalendar', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createassignedleaves(data: any): Observable<any> {
    const body = (data);
    return this.http.post(`${environment.API_URL}` + 'assignedemployeeleaves', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  CreatestudentProfile(data: any): Observable<any> {
    const header = { 'content-type': 'application/json' };
    const body = JSON.stringify(data);
    return this.http.post(`${environment.API_URL}` + 'profile', body, { 'headers': header })
  }

  createstreams(courses: any): Observable<any> {
    const body = courses;
    return this.http.post(`${environment.API_URL}` + 'streams', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getloggerdata(date: any) {
    let body = date
    return this.http.post(`${environment.API_URL}` + 'employees/getlogger', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  updatestudentimage(student: File, ID: any) {
    let fomdata: FormData = new FormData();
    fomdata.append('studentImage', student);
    const body = fomdata;
    return this.http.put<any>(`${environment.API_URL}` + 'students/' + ID, body, { headers: this.fileheader }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
        }
      }))
  }

  updateemployeeimage(employee: any, ID: number) {
    let fomdata: FormData = new FormData();
    fomdata.append('employeImage', employee);
    const body = fomdata;
    return this.http.put<any>(`${environment.API_URL}` + 'employees/' + ID, body, { headers: this.fileheader }).pipe(
      map(data => {
        if (data?.code == '200' && data?.status === true) {
          return data
        } else {
          catchError(this.handleError)
        }
      }))
  }

  updateTimeSlots(slotData: any, ID: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('slotData', JSON.stringify(slotData));
    console.log(slotData, "any", ID, "number")
    return this.http.put<any>(`${environment.API_URL}` + 'timeslots/' + ID, formData, { headers: this.fileheader }).pipe(
      map((data) => {
        if (data?.code === '200' && data?.status === true) {
          return data;
        } else {
          throw new Error('Unexpected response from the server');
        }
      }),
      catchError(this.handleError)
    );
  }

  getstudentlist(paginationdata: any, filters: any) {
    let params = new URLSearchParams();
    params.set('type', 'list');
    params.set('limit', paginationdata?.pageSize);
    params.set('skip', (paginationdata?.pageIndex + 1).toString());

    Object.keys(filters).forEach(key => {
      if (filters[key] !== undefined && filters[key] !== null) {
        params.set(key, filters[key]);
      }
    });
    const url = `${environment.API_URL}students?${params.toString()}`;
    return this.http.get(url, { headers: this.header }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getacademicstudent(paginationdata: any, filters: any) {
    let params = new URLSearchParams();
    console.log("student filter", filters)
    params.set('type', 'list');
    params.set('limit', paginationdata?.pageSize);
    params.set('skip', (paginationdata?.pageIndex + 1).toString());

    Object.keys(filters).forEach(key => {
      if (filters[key] !== undefined && filters[key] !== null) {
        params.set(key, filters[key]);
      }
    });
    const url = `${environment.API_URL}academicsstudentsInfo?${params.toString()}`;
    return this.http.get(url, { headers: this.header }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getemployeelist(paginationdata: any, filters: any) {
    let params = new URLSearchParams();

    params.set('type', 'list');
    params.set('limit', paginationdata?.pageSize);
    params.set('skip', (paginationdata?.pageIndex + 1).toString());
    console.log(filters);
    Object.keys(filters).forEach((key: any) => {
      if (filters[key] !== undefined && filters[key] !== null) {
        params.set(key, filters[key]);
      }
    });
    const url = `${environment.API_URL}employees?${params.toString()}`;
    return this.http.get(url, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getselectedstudentProfile(data: any): Observable<any> {
    const header = { 'content-type': 'application/json' };
    const body = JSON.stringify(data);
    return this.http.post(`${environment.API_URL}` + 'profile', body)
  }

  getstudentdetails(data: number): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'students' + '/' + data, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getemployeedetails(data: number): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'employees' + '/' + data, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        this.dismissError();
        return data
      }),
      catchError(this.handleError))
  }

  getdepartmentdetails(paginationdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'departments?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacadamicslist(paginationdata: any): Observable<any> {
    return this.http.get(`${environment.API_URL}` + 'academicregulations?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsectionlist(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academicsections?type=list', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getBatchDetails(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academicregulations/' + eventdata.id, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getSectionDetails(paginationdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'organizationacademicsections?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getAcademicYearsSection(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academiccalendar?type=list&limit=100&courseId=' + eventdata.course_ID, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getAcademicSemister(eventdata: any, paginationdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'academicyearsemisters?type=list&courseId=' + eventdata.course_ID + '&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getacadamicyearslist(paginationdata: any): Observable<any> {
    return this.http.get(`${environment.API_URL}` + 'academiccalendar?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getlookuptypedetails(paginationdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'lookuptypes?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  Studentforgotpassword(role: any, email: any): Observable<any> {
    const body = { email: email };
    let urlnavigate
    if (role === 'stiudent') {
      urlnavigate = 'students'
    } else {
      urlnavigate = 'employees'
    }
    return this.http.patch<any>(`${environment.API_URL}` + urlnavigate + '/forgotPassword', body).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  verifyOTP(role: any, email: any, OTP: any): Observable<any> {
    const body = {
      email: email,
      otp: OTP
    };
    let urlnavigate
    if (role === 'stiudent') {
      urlnavigate = 'students'
    } else {
      urlnavigate = 'employees'
    }
    return this.http.patch<any>(`${environment.API_URL}` + urlnavigate + '/verifyEmailOtp', body).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  passwordupdate(role: any, password: any, repassword: any, ID: number): Observable<any> {
    const body = {
      password: password,
      confirm_password: repassword,
      id: ID
    };
    let urlnavigate
    if (role === 'stiudent') {
      urlnavigate = 'students'
    } else {
      urlnavigate = 'employees'
    }
    return this.http.patch<any>(`${environment.API_URL}` + urlnavigate + '/updatePassword', body).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getattendancedetails(year: number, month: number): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'employeeattendance?type=list&limit=100&employeeId=' + this.userdatta?.data?.id + '&year=' + year + '&month=' + ("0" + month).slice(-2), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getstudentattendancedetails(year: number, month: number): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'studentattendance?type=list&limit=100&studentId=' + this.userdatta?.data?.id + '&year=' + year + '&month=' + ("0" + month).slice(-2), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getshiftdetails(paginationdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'breaktimes?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getweekoffdetails(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'weekoffcalendar?type=list&limit=100', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getholidaydetails(paginationdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'holidaycalendar?type=list&limit=' + paginationdata?.pageSize + '&skip=' + (paginationdata?.pageIndex + 1), { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getleavetypedetails(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'leavetypes?type=list&organization_id=' + this.userdata.data.id, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }
  getApplyLeavetypes():Observable<any>{
    return this.http.get<any>(`${environment.API_URL}` + 'leavetypes?type=drop', { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getorganizationdetails(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'organizations?type=list&limit=1000', { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getleaverequestdetails(actionRequest: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'employeeleaves?type=list&managerId=' + this.userdatta?.data.id + '&status=' + actionRequest, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getleavestdetails(actionRequest: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'employeeleaves?type=list&employeeId=' + this.userdatta?.data.id + '&status=' + actionRequest, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getleaverequeststats(): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'employeeleaves?type=list&stats=true&employeeId=' + this.userdatta?.data.id, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsemesteryearwise(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'semisters?type=list&limit=100&courseId=' + eventdata.course_ID + '&year=' + eventdata.year, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getcoursewisesemester(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'semisters?type=list&limit=100&courseId=' + eventdata.course_ID, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  getsemestersubjectsDetails(eventdata: any): Observable<any> {
    return this.http.get<any>(`${environment.API_URL}` + 'subjects?type=list&limit=100&courseId=' + eventdata.course_ID + '&semisterId=' + eventdata.semester_ID, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  actionleaverequest(action: any, id: number): Observable<any> {
    const body = action;
    return this.http.patch<any>(`${environment.API_URL}` + 'employeeleaves/' + id, body, { headers: { 'token': this.userdatta?.data.token } }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  applyemployeeleaves(data: any): Observable<any> {
    let body = (data);
    body = { ...body, employee_id: this.userdatta.data.id }
    return this.http.post(`${environment.API_URL}` + 'employeeleaves', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  createshift(data: any): Observable<any> {
    let body = (data);
    return this.http.post(`${environment.API_URL}` + 'breaktimes', body, { headers: this.header }).pipe(
      map(data => {
        return data
      }),
      catchError(this.handleError))
  }

  subjectStatus(id: number, data: any): Observable<any> {
    const url = `${environment.API_URL}subjects/${id}`;
    const headers = new HttpHeaders().set('token', this.userdata?.data.token ?? '');
    return this.http.patch(url, data, { headers }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  courseStatus(id: number, data: any): Observable<any> {
    const url = `${environment.API_URL}courses/${id}`;
    const headers = new HttpHeaders().set('token', this.userdata?.data.token ?? '');
    return this.http.patch(url, data, { headers }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  handleError(errorResponse: HttpErrorResponse) {
    console.log("errorResponse", errorResponse);
    if (errorResponse.status === 401) {
      localStorage.removeItem("user");
      window.location.reload();
      this.AuthenticationService.logout();
    }
    return throwError(errorResponse.message || 'Server error');
  }

  private dismissError(): void {
    setTimeout(() => {
      this.toast.dismissToast();
    }, 3000);
  }

}
