import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../components/Authentication/services/authentication.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatExpansionModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  styles: [
    `
      .spacer {
        flex: 1 1 auto;
      }

      .title {
        cursor: pointer;
      }

      .welcome-text {
        font-size: smaller;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit{

  @Output() menuToggled = new EventEmitter<boolean>();

  user: string = 'user';
  userdata: any;

  // constructor(private authService: AuthService) { }

  constructor(private authservice : AuthenticationService, private router: Router) {
    if (this.authservice.user) {
      this.authservice.user.subscribe(x => {
        this.userdata = JSON.parse(x)
    });
  }
  }

  ngOnInit(): void {
    this.user = this.userdata?.data.first_name;
  }

  logout() {
    this.authservice.logout();
}
gotoprofile(){
  if(this.userdata?.data?.user_role === 'STUDENTS'){
    this.router.navigate(['/studentview']);
  }else{
    this.router.navigate(['/employeeview'])
  }
}
}
