import { Injectable, OnInit } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { of, Observable } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { DatepickerQuestion } from '../../question-datepicker';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { CheckboxQuestion } from '../../question-checkbox';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService implements OnInit {
  password: any;
  organisationdata: any = [];
  Genderdata: any = [];
  DepartmentName: any = [];
  Castesdata: any = [];
  Religionsdata: any = [];
  Statuesdata: any = [];
  Employeetype: any = [];
  Appointenttype: any = [];
  Coursesdata: any = [];
  Countrydata: any = [];
  Statedata: any = [];
  Qualificationdata: any = [];
  Designeationdata: any = [];
  Salarydata: any = [];
  Userrolesdata: any = [];

  constructor(private apiservice: ApiservicesService) {
    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('EMPLOYMENT_TYPES', this.Employeetype);
    this.loadDropdownData('APPOINTMENT_TYPES', this.Appointenttype);
    this.loadDropdownData('DEPARTMENTS', this.DepartmentName);
    this.loadDropdownData('DESIGNATIONS', this.Designeationdata);
    this.loadDropdownData('GENDER', this.Genderdata);
    this.loadDropdownData('SALARY_MODES', this.Salarydata);
    this.loadDropdownData('USER_ROLES', this.Userrolesdata);
    this.loadDropdownData('COURSES', this.Coursesdata);
    this.loadDropdownData('CASTES', this.Castesdata);
    this.loadDropdownData('RELIGIONS', this.Religionsdata);
    this.loadDropdownData('STATUSES', this.Statuesdata);
    this.loadDropdownData('COUNTRY_NAMES', this.Countrydata);
    this.loadDropdownData('INDIA', this.Statedata);
    this.loadDropdownData('QUALIFICATIONS', this.Qualificationdata);
    // this.loadDropdownData('PASSWORD', this.password);
  }
  ngOnInit(): void {
    this.apiservice.getpasswordemployee().subscribe((res) => {
      console.log('password', res);
      this.password = res.data.password;
    });
  }

  title_dropdown: any = [
    { key: 'Mr.', id: 'Mr', value: 'Mr.' },
    { key: 'Ms.', id: 'Ms', value: 'Ms.' },
    { key: 'Mrs.', id: 'Mrs', value: 'Mrs.' },
  ];

  Faculty_teaching: any = [
    { key: 'UG', id: 'UG', value: 'UG' },
    { key: 'PG', id: 'PG', value: 'PG' },
  ];
  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.apiservice.getorganisation();
    } else if (code === 'DEPARTMENTS') {
      observable = this.apiservice.getdepartmentdrop();
    } else if (code === 'USER_ROLES') {
      observable = this.apiservice.getuserroledropdown();
    } else {
      observable = this.apiservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      // if(res instanceof Array){
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'ORGANISATION') {
          lookupname = res[i].short_name;
        } else if (code === 'DEPARTMENTS') {
          lookupname = res[i].department_name;
        } else if (code === 'USER_ROLES') {
          lookupname = res[i].name;
        } else {
          lookupname = res[i].lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
      // }else{
      //   this.password = res.password
      // }
    });
  }

  getemployeeform() {
    const questions: Inputfiledsbase<string | Number>[] = [

      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization',
        required: true,
        options: this.organisationdata,
        order: 1,
      }),

      new TextboxQuestion({
        key: 'employee_unique_id',
        label: 'Employee Unique Id',
        required: true,
        order: 2,
      }),

      new DropdownQuestion({
        key: 'title',
        label: 'Title',
        required: true,
        options: this.title_dropdown,
        value: 'Mr.',
        order: 4,
      }),

      new TextboxQuestion({
        key: 'first_name',
        label: 'First Name',
        // value: 'Bombasto',
        required: true,
        patterns: '[a-zA-Z ]*',
        order: 5,
      }),

      new TextboxQuestion({
        key: 'middle_name',
        label: 'Middle Name',
        required: false,
        patterns: '[a-zA-Z ]*',
        order: 6,
      }),

      new TextboxQuestion({
        key: 'last_name',
        label: 'Last name',
        required: true,
        patterns: '[a-zA-Z ]*',
        order: 7,
      }),

      new DropdownQuestion({
        key: 'gender_id',
        label: 'Gender',
        required: false,
        // patterns: '^d{10}$',
        options: this.Genderdata,
        order: 25,
      }),

      new DatepickerQuestion({
        key: 'date_of_birth',
        label: 'Date Of Birth',
        type: 'datepicker',
        required: true,
        order: 26,
      }),

      new DropdownQuestion({
        key: 'religion_id',
        label: 'Religion',
        type: 'text',
        options: this.Religionsdata,
        required: false,
        order: 21,
      }),

      new DropdownQuestion({
        key: 'caste_id',
        label: 'Caste',
        required: true,
        options: this.Castesdata,
        // patterns: '^d{10}$',
        order: 23,
      }),

      new TextboxQuestion({
        key: 'sub_caste',
        label: 'Sub Caste',
        type: 'text',
        // value: 'Bombasto',
        required: true,
        order: 24,
      }),

      new TextboxQuestion({
        key: 'adhar_number',
        label: 'Aadhaar Number',
        type: 'text',
        required: false,
        patterns: '^[0-9]{12}$',
        minLength: 12,
        maxLength: 12,
        order: 28,
      }),

      new TextboxQuestion({
        key: 'pan_number',
        label: 'Pan Number',
        type: 'text',
        required: false,
        patterns: '^[A-Z]{5}[0-9]{4}[A-Z]$',
        minLength: 10,
        maxLength: 10,
        order: 29,
      }),

      new TextboxQuestion({
        key: 'phone_number',
        label: 'Mobile Number',
        type: 'text',
        required: true,
        patterns: '^[0-9]*$',
        minLength: 10,
        maxLength: 10,
        order: 11,
      }),

      new TextboxQuestion({
        key: 'alternate_mobile_number',
        label: 'Alternate Mobile Number',
        type: 'text',
        required: false,
        patterns: '^[0-9]*$',
        minLength: 10,
        maxLength: 10,
        order: 12,
      }),

      new TextboxQuestion({
        key: 'personal_email',
        label: 'Personal Email',
        type: 'email',
        patterns: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
        required: true,
        order: 8,
      }),

      new TextboxQuestion({
        key: 'father_name',
        label: 'Father Name',
        required: true,
        patterns: '[a-zA-Z ]*',
        order: 9,
      }),

      new TextboxQuestion({
        key: 'mother_name',
        label: 'Mother Name',
        required: true,
        patterns: '[a-zA-Z ]*',
        order: 10,
      }),

      new TextboxQuestion({
        key: 'password',
        label: 'Password',
        type: 'password',
        required: true,
        minLength: 12,
        maxLength: 100,
        order: 13,
      }),

      new DropdownQuestion({
        key: 'user_role_id',
        label: 'User Role',
        required: false,
        patterns: '^\\d+$',
        options: this.Userrolesdata,
        order: 36,
      }),

      new DropdownQuestion({
        key: 'status_id',
        label: 'Status',
        options: this.Statuesdata,
        required: true,
        order: 57,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getExperienceForm() {
    const questions: Inputfiledsbase<Number>[] = [
      new TextboxQuestion({
        key: 'total_expiriance',
        label: 'Over all Teaching Experience ( Yrs )',
        type: 'number',
        required: true,
        patterns: '^\\d{1,2}(\\.\\d{1,2})?$',
        order: 38,
      }),

      new TextboxQuestion({
        key: 'expiriance_in_our_institute',
        label: 'Experience in Our Institute (Yrs)',
        type: 'number',
        required: false,
        patterns: '^\\d{1,2}(\\.\\d{1,2})?$',
        order: 39,
      }),

      new TextboxQuestion({
        key: 'any_other_expirince_in_years',
        label: 'Any Other Expirince (Yrs)',
        type: 'number',
        required: false,
        order: 49,
      }),

      new TextboxQuestion({
        key: 'number_of_publications_in_national_journal',
        label: 'Number of Publications in National Journal',
        type: 'number',
        required: false,
        order: 44,
      }),

      new TextboxQuestion({
        key: 'number_of_publications_in_international_journal',
        label: 'Number of Publications in International Journal',
        type: 'number',
        required: false,
        order: 45,
      }),

      new TextboxQuestion({
        key: 'number_of_books_published',
        label: 'Number of Books Published',
        type: 'number',
        required: false,
        order: 46,
      }),

      new TextboxQuestion({
        key: 'number_of_publications_in_national_conference',
        label: 'Number of Publications in National Conference',
        type: 'number',
        required: false,
        order: 47,
      }),

      new TextboxQuestion({
        key: 'number_of_publications_in_international_conference',
        label: 'Number of Publications in International Conference',
        type: 'number',
        required: false,
        order: 48,
      }),

      new TextboxQuestion({
        key: 'number_of_workshops_attended',
        label: 'Number of Workshops Attended',
        type: 'number',
        required: false,
        order: 49,
      }),

      new TextboxQuestion({
        key: 'number_of_seminars_attended',
        label: 'Number of Seminars Attended',
        type: 'number',
        required: false,
        order: 50,
      }),

      new TextboxQuestion({
        key: 'number_of_seminars_conducted',
        label: 'Number of Seminars Conducted',
        type: 'number',
        required: false,
        order: 51,
      }),

      new TextboxQuestion({
        key: 'number_of_conferences_conducted',
        label: 'Number of Conferences Conducted',
        type: 'number',
        required: false,
        order: 52,
      }),

      new TextboxQuestion({
        key: 'number_of_conferences_attended',
        label: 'Number of Conferences Attended',
        type: 'number',
        required: false,
        order: 53,
      }),

      new TextboxQuestion({
        key: 'number_of_pg_projects_guided',
        label: 'Number of PG Projects Guided',
        type: 'number',
        required: false,
        order: 54,
      }),

      new TextboxQuestion({
        key: 'number_of_doctorate_students_guided',
        label: 'Number of Doctorate Students Guided',
        type: 'number',
        required: false,
        order: 55,
      })

    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getJoiningFrom() {
    const questions: Inputfiledsbase<String | Number>[] = [
      new DatepickerQuestion({
        key: 'date_of_joining',
        label: 'Date of Joining',
        type: 'datepicker',
        // value: false,
        required: true,
        order: 1,
      }),

      new DatepickerQuestion({
        key: 'date_of_relieving',
        label: 'Date of Relieving',
        type: 'datepicker',
        // value: false,
        required: false,
        order: 2,
      }),

      new TextboxQuestion({
        key: 'professional_email',
        label: 'Professional Email',
        type: 'email',
        patterns: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
        required: false,
        order: 3,
      }),

      new DropdownQuestion({
        key: 'employement_type_id',
        label: 'Employee Type',
        required: true,
        options: this.Employeetype,
        order: 4,
      }),

      new DropdownQuestion({
        key: 'appointment_type_id',
        label: 'Appointment Type',
        required: true,
        options: this.Appointenttype,
        order: 5,
      }),

      new DropdownQuestion({
        key: 'department_id',
        label: 'Department Name',
        options: this.DepartmentName,
        required: true,
        order: 6,
      }),

      new DropdownQuestion({
        key: 'faculty_teaching_for',
        label: 'Faculty Teaching For',
        options: this.Faculty_teaching,
        required: true,
        order: 7,
      }),

      new DropdownQuestion({
        key: 'designation_id',
        label: 'Designation',
        required: false,
        options: this.Designeationdata,
        order: 8,
      }),
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getSalaryForm() {
    const questions: Inputfiledsbase<String | Number>[] = [
      new TextboxQuestion({
        key: 'pay_scale',
        label: 'Pay Scale',
        type: 'Number',
        required: true,
        patterns: '^\\d+(\\.\\d{1,2})?$',
        order: 18,
      }),

      new TextboxQuestion({
        key: 'gross_pay_per_month',
        label: 'Gross Pay Per Month',
        type: 'text',
        required: false,
        patterns: '^\\d+(\\.\\d{1,2})?$',
        minLength: 1,
        maxLength: 10,
        order: 34,
      }),

      new TextboxQuestion({
        key: 'hra_in_rupees',
        label: 'HRA in Rs.',
        type: 'text',
        required: false,
        patterns: '^\\d+(\\.\\d{1,2})?$',
        order: 35,
      }),

      new TextboxQuestion({
        key: 'basic_pay',
        label: 'Basic pay in Rs.',
        type: 'text',
        required: false,
        patterns: '^\\d+(\\.\\d{1,2})?$',
        order: 35,
      }),

      new TextboxQuestion({
        key: 'other_allowances',
        label: 'Other Allowances in Rs.',
        type: 'text',
        required: false,
        patterns: '^\\d+(\\.\\d{1,2})?$',
        order: 35,
      }),

      new DropdownQuestion({
        key: 'salary_mode_id',
        label: 'Salary Mode',
        required: false,
        options: this.Salarydata,
        order: 33,
      }),

      new TextboxQuestion({
        key: 'bank_name',
        label: 'Bank Name',
        type: 'text',
        required: false,
        order: 42,
      }),

      new TextboxQuestion({
        key: 'bank_account_number',
        label: 'Bank Account Number',
        type: 'text',
        required: false,
        patterns: '^[0-9]{11,18}$',
        minLength: 11,
        maxLength: 18,
        order: 40,
      }),

      new TextboxQuestion({
        key: 'bank_branch_name',
        label: 'Bank Branch Name',
        type: 'text',
        required: false,
        patterns: "^[a-zA-Zsd-&.'()]*$",
        order: 43,
      }),

      new TextboxQuestion({
        key: 'bank_ifsc_code',
        label: 'IFSC Code',
        type: 'text',
        required: false,
        minLength: 11,
        maxLength: 11,
        order: 41,
      }),

      new TextboxQuestion({
        key: 'pf_number',
        label: 'PF Number',
        type: 'text',
        required: false,
        patterns: '^[a-zA-Z0-9]{6,12}$',
        minLength: 6,
        maxLength: 12,
        order: 30,
      }),

      new TextboxQuestion({
        key: 'esi_number',
        label: 'ESIC Number',
        type: 'text',
        required: false,
        patterns: '^[a-zA-Z0-9]{10,17}$',
        minLength: 10,
        maxLength: 17,
        order: 31,
      }),
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }
  getAddressForm() {
    const questions: Inputfiledsbase<String | Number>[] = [
      new DropdownQuestion({
        key: 'country_id',
        label: 'Country',
        type: 'text',
        required: true,
        options: this.Countrydata,
        order: 2,
      }),

      new DropdownQuestion({
        key: 'state_id',
        label: 'State Name',
        type: 'text',
        required: true,
        options: this.Statedata,
        order: 4,
      }),

      new TextareaQuestion({
        key: 'address_line1',
        label: 'Address Line 1',
        type: 'text',
        required: true,
        order: 5,
      }),

      new TextareaQuestion({
        key: 'address_line2',
        label: 'Address Line 2',
        type: 'text',
        required: true,
        order: 6,
      }),

      new TextboxQuestion({
        key: 'city',
        label: 'City',
        type: 'text',
        required: true,
        order: 7,
      }),

      new TextboxQuestion({
        key: 'pincode',
        label: 'PIN Code',
        type: 'text',
        required: true,
        order: 6,
        minLength: 6,
        maxLength: 6,
        patterns: '^[0-9]*$',
      })

    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getPermanetcheckbox() {
    const questions: Inputfiledsbase<String | Number>[] = [
      new CheckboxQuestion({
        key: 'is_permanent',
        label: 'is permanent',
        required: true,
        order: 1,
      }),
    ];
    return of(questions.sort((a, b) => a.order - b.order));
  }

  getEducationalDetails() {
    const questions: Inputfiledsbase<String | Number>[] = [
      new DropdownQuestion({
        key: 'qualification_id',
        label: 'Qualification',
        type: 'text',
        options: this.Qualificationdata,
        required: true,
        order: 2,
      }),

      new DatepickerQuestion({
        key: 'start_year',
        label: 'Start Year',
        type: 'text',
        required: true,
        patterns: '^(19|20)d{2}$',
        order: 3,
      }),

      new DatepickerQuestion({
        key: 'end_year',
        label: 'End Year',
        type: 'text',
        required: true,
        patterns: '^(19|20)d{2}$',
        order: 4,
      }),

      new TextboxQuestion({
        key: 'university_name',
        label: 'University Name',
        type: 'text',
        required: true,
        order: 5,
      }),

      new TextboxQuestion({
        key: 'institution_name',
        label: 'Institution Name',
        type: 'text',
        required: true,
        order: 6,
      }),

      new TextboxQuestion({
        key: 'course_name',
        label: 'Course Name',
        type: 'text',
        required: true,
        order: 7,
      }),

      new TextboxQuestion({
        key: 'percentage',
        label: 'Percentage',
        type: 'number', // Assuming the percentage is entered as text
        required: true,
        patterns: '^100$|^[123456789][0-9]$|^[0-9]$', // Pattern to match percentages from 0 to 100
        order: 8,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }

  getFilterDropdowns(): Observable<Inputfiledsbase<string>[]> {
    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion({
        key: 'department_id',
        label: 'Department Name',
        options: this.DepartmentName,
        required: false,
        order: 1,
      }),
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
