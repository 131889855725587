import { Injectable } from '@angular/core';

// import { DropdownQuestion } from './question-dropdown';
// import { QuestionBase } from './question-base';
// import { TextboxQuestion } from './question-textbox';
import { of } from 'rxjs';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';

@Injectable()
export class QuestionService {

  // TODO: get from a remote source of question metadata
  getQuestions() {

    const questions: Inputfiledsbase<string>[] = [

      new DropdownQuestion({
        key: 'dropdown',
        label: 'dropdown fileds',
        options: [
          {id: 1, key: 'option1',  value: 1},
          {id: 2, key: 'option2',  value: 2},
          {id: 3, key: 'option3',  value: 3},
          {id: 4, key: 'option4',  value: 4}
        ],
        required: true,
        order: 2
      }),

      new TextboxQuestion({
        key: 'firstName',
        label: 'First name',
        // value: 'Bombasto',
        required: true,
        order: 1
      }),

      new TextboxQuestion({
        key: 'emailAddress',
        label: 'Email',
        type: 'email',
        required: true,
        order: 3
      }),

      new TextboxQuestion({
        key: 'Address',
        label: 'Address',
        required: true,
        order: 4
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}