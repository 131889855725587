import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { Inputfiledsbase } from '../models/inputfiledsbase';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@Component({
  standalone: true,
  selector: 'app-question',
  styleUrl: './dynamic-form-question.component.scss',
  templateUrl: './dynamic-form-question.component.html',
  providers: [provideNativeDateAdapter()],
  imports: [
    CommonModule, 
    ReactiveFormsModule, 
    FormsModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    MatCheckboxModule, 
    MatInputModule, 
    MatDatepickerModule,
    NgxMaterialTimepickerModule
  ],
})
export class DynamicFormQuestionComponent {
  @Input() question!: Inputfiledsbase<string>;
  @Input () Addressquestions! : Inputfiledsbase<string>; 
  @Input() form!: FormGroup;
  url: string | ArrayBuffer | null | undefined;

  get isValid(): boolean {
    // Check if the form control is valid based on specific conditions
    if (this.question.key === 'agreebox') {
      // Custom validation logic for 'agreebox' form control
      return this.form.controls[this.question.key]?.value ? this.form.controls[this.question.key]?.valid : this.form.controls[this.question.key]?.invalid;
    } else {
      // Default validation for other form controls
      return this.form.controls[this.question.key]?.valid;
    }
  }
  get isValidt(): boolean {
    // Check if the form control is touched
    return !!this.form.controls[this.question.key] && this.form.controls[this.question.key].touched;
  }

  get iserror() {
    if (this.form.controls[this.question.key].errors) {
      return this.form.controls[this.question.key].errors
    } else {
      return false
    }
  }

  get patternError() {
    return  this.form?.controls[this.question.key]?.hasError('required') || this.form?.controls[this.question.key]?.hasError('pattern') || this.form?.controls[this.question.key]?.hasError('email');
  }
  
  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {
        this.url = event.target?.result;
      }
    }
  }
}