import { RouterModule, Routes } from '@angular/router';
import { LookupcodesComponent } from './pages/lookupcodes/lookupcodes.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout.component';
import { LookuptypesComponent } from './pages/lookuptypes/lookuptypes.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DepartmentComponent } from './pages/department/department.component';
import { StudentComponent } from './pages/student/student.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { AuthGuard } from './components/Authentication/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { LookupcodesviewComponent } from './lookupcodes/lookupcodesview/lookupcodesview.component';
import {
  EmployeeAtendanceModule,
  EmployeeatendanceComponent,
} from './pages/attendance/employeeatendance/employeeatendance.component';
import { StudentprofileviewComponent } from './profile/studentprofileview/studentprofileview.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { StreamComponent } from './pages/stream/stream.component';
import { NgModule } from '@angular/core';
import { EmployeeprofileviewComponent } from './profile/employeeprofileview/employeeprofileview.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ToastComponent } from './components/toast/toast.component';
import { StudentattendanceComponent } from './pages/attendance/studentattendance/studentattendance.component';
import { WorkingdaysComponent } from './pages/settings/workingdays/workingdays.component';
import { LeavetypesComponent } from './pages/attendance/leaveutilities/leavetypes/leavetypes.component';
import { LeaverequestComponent } from './pages/attendance/leaveutilities/leaverequest/leaverequest.component';
import { LeaverequestviewComponent } from './pages/attendance/leaveutilities/leaverequestview/leaverequestview.component';
import { LeaveviewsComponent } from './pages/attendance/leaveutilities/leaveviews/leaveviews.component';
import { AddorganizationComponent } from './pages/organization/organization.component';
import { CollegedetailsComponent } from './pages/organization/collegedetails/collegedetails.component';
import { LeavesassignedComponent } from './pages/leavesassigned/leavesassigned/leavesassigned.component';
import { SemestersComponent } from './pages/courses/semesters/semesters.component';
import { SectionsComponent } from './pages/academics/academicregulations/sections/sections.component';
import { AcademicregulationsComponent } from './pages/academics/academicregulations/academicregulations.component';
import { AcademicyearsComponent } from './pages/academics/academicyears/academicyears.component';
import { LoggerComponent } from './pages/logger/logger.component';
import { AcademicyearsdetailsComponent } from './pages/academics/academicyears/academicyearsdetails/academicyearsdetails.component';
import { AcademicregulationsviewComponent } from './pages/academics/academicregulations/academicregulationsview/academicregulationsview.component';
import { TimetableComponent } from './pages/timetable/timetable.component';
import { AddtimetablesComponent } from './pages/timetable/timetables/addtimetables/addtimetables.component';
import { AddTimeslotComponent } from './pages/timetable/timeslot/addtimeslot/addtimeslot.component';
import { ClassesComponent } from './pages/classes/classes.component';
import { ClassesviewComponent } from './pages/classes/classesview/classesview.component';

export const routes: Routes = [
  // {path: '', redirectTo:'dashboard', pathMatch: 'full',  canActivate: [AuthGuard] },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login/:role',
    component: LoginComponent
  },
  {
    path: 'loginfp',
    component: ForgotpasswordComponent
  },
  {
    path: 'toast',
    component: ToastComponent
  },
  {
    path: 'layout',
    component: LayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'lookupcodeview',
    component: LookupcodesviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'department',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lookupcode',
    component: LookupcodesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'lookuptype',
    component: LookuptypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'student',
    component: StudentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'studentview',
    component: StudentprofileviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'employeeview',
    component: EmployeeprofileviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'employeeatendance',
    component: EmployeeatendanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'studentatendance',
    component: StudentattendanceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'organizations',
    component: AddorganizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'collegedetails',
    component: CollegedetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timings',
    component: WorkingdaysComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'employee',
    component: EmployeeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'courses',
    component: CoursesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'stream',
    component: StreamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'semesterdetails',
    component: SemestersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'leavetype',
    component: LeavetypesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assignedleaves',
    component: LeavesassignedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requests',
    component: LeaverequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'requestsview',
    component: LeaverequestviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'academicregulations',
    component: AcademicregulationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sectiondetails',
    component: SectionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'academicregulationsview',
    component: AcademicregulationsviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'academicyears',
    component: AcademicyearsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'academicyearsdetails',
    component: AcademicyearsdetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'timetable',
    component: TimetableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'addtimeslots',
    component: AddTimeslotComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'leaveview',
    component: LeaveviewsComponent,
    canActivate: [AuthGuard],
  },
  {
    path:'classes',
    component: ClassesComponent,
    canActivate: [AuthGuard],
  },
  { 
    path:'classview',
    component: ClassesviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logger',
    component: LoggerComponent,
    canActivate: [AuthGuard]
  },
  { path: '**', component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule],
})
export class AppRoutingModule { }
