import { Component, NgModule, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../Authentication/services/authentication.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { environment } from '../../../environments/environment.prod';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    standalone: true,
    imports: [
        ForgotpasswordComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule
    ],
    providers: [AuthenticationService]
})
export class LoginComponent implements OnInit {
    loginForm!: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string | undefined;
    error = '';
    forgotpasswordflag : boolean = false;
    form: FormGroup = new FormGroup({
        userid: new FormControl(''),
        password: new FormControl(''),
    });

    loginerror: boolean | undefined;
    selectedrole: any;
    loginbanner: string;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.userValue !== undefined) {
            // console.log("auth service", this.authenticationService.userValue)
            this.router.navigate(['/dashboard']);
            // window.location.reload();
        }
        this.route.queryParamMap.subscribe( (params) =>{
            // console.log('prams con', params)
            this.selectedrole = params.get('role')
        }
        )
        if(this.route.snapshot.params['role'] !== null || this.route.snapshot.params['role'] !== undefined){
            this.selectedrole = this.route.snapshot.params['role'];
            // console.log("this is prams from methode this.route.snapshot.params['role']", this.route.snapshot.params['role'])
        }
        this.loginbanner = environment.ASSET_URL + environment.ASSET_CONTAINER + "/assets/banners/LoginBanner.jpg";
    }

    ngOnInit() {
        this.route.queryParamMap.subscribe( params =>{
            // console.log('prams', params)
            this.selectedrole = params.get('role')
        }
        )
        // this.selectedrole = this.route.snapshot.params['role']
        // console.log("selected role", this.selectedrole );
        this.form = this.formBuilder.group(
            {
                userid: ['', [Validators.required, Validators.email]],
                password: ['', [Validators.required]]
            }
        );
    }

    // convenience getter for easy access to form fields
    // get f() { return this.loginForm.controls; }
    get f() {
        return this.form.controls;
    }
    forgotflag(){
        this.forgotpasswordflag = true;
    }
    onSubmit() {
        this.submitted = true;
        this.loginerror = false;
        if (this.form.invalid) {
            return;
        }
        // this.authenticationService.login(this.f['email'].value, this.f['password'].value)
        this.authenticationService.login(this.form.value.userid, this.form.value.password, this.selectedrole)
            .pipe(first())
            .subscribe(
                data => {
                    // this.router.navigate([this.returnUrl]);
                    if (data.status) {
                        if(this.selectedrole === 'student'){
                            this.router.navigate(['/studentview']).then(() => {
                                window.location.href = this.router.url;
                            });
                        } else{
                            this.router.navigate(['/employeeview']).then(() => {
                                window.location.href = this.router.url;
                            });
                        }
                    } else {
                        this.loginerror = true
                    }
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }

    addItem(newItem: boolean) {
        this.forgotpasswordflag = newItem ;
      }
}
