import { Injectable } from '@angular/core';
import { TextboxQuestion } from '../../question-textbox';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { of } from 'rxjs';
import { TextareaQuestion } from '../../question-textarea';
import { DropdownQuestion } from '../../question-dropdown';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { DropdownservicesService } from '../../../Services/Apis/Dropdownservices/dropdownservices.service';

@Injectable({
  providedIn: 'root'
})

export class DepartmentService {
  
  organisationdata: any = [];
  departmentlist: any = [];

  constructor(private apiservice: ApiservicesService, private dropdownservice: DropdownservicesService) {
    this.dropdownservice.getorganizationdrop().subscribe((res:any) => {
      // if (res.status === true || res.code === 200) {
      for (let i = 0; i <= res.length; i++) {
        this.organisationdata?.push({ id: res[i]?.id, key: res[i]?.short_name, value: res[i]?.id })
      }
    // }
    // else{
    //   console.log("--->",res);
    // }
    });

    this.dropdownservice.getemployeedrop().subscribe((depart:any) => {
      for (let i = 0; i <= depart?.length; i++) {
        this.departmentlist?.push({ id: depart[i].id, key: depart[i].name, value: depart[i].id })
      }
    });
  }
  
  getdepartmentform(){
    const questions: Inputfiledsbase<string>[] = [
    
      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization ID',
        options: this.organisationdata,
        required: true,
        order: 1
      }),

      new TextboxQuestion ({
        key: 'department_name',
        label: 'Department Name',
        required: true,
        order: 2
      }),
      
      new TextboxQuestion ({
        key: 'department_code',
        label: 'Department Code',
        required: true,
        order: 3
      }),

      new DropdownQuestion ({
        key: 'department_manager_id',
        label: 'Department Manager ID',
        options: this.departmentlist,
        required: true,
        order: 4
      }),

      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order: 5
      })
    ];
    
    return of(questions.sort((a, b) => a.order - b.order));
  }
}
