import { API_URL_CONFIG, ASSET_CONTAINER_NAME, ASSET_URL_CONFIG, isProduction } from "../../env";

export const environment = {
  production: isProduction,
  API_URL: API_URL_CONFIG, // 'https://devapi.dbraei.edu.in/api/v1/'
  ASSET_URL: ASSET_URL_CONFIG,
  ASSET_CONTAINER: ASSET_CONTAINER_NAME,
  API_URL_CONFIG: API_URL_CONFIG

};
