<!-- <ng-container *ngIf="lookupdata.length > 0"> -->
<section class="container">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <h4 class="m-0">Lookup Codes</h4>
        </div>
        <div>
            <button mat-raised-button color="primary" routerLink="/lookupcode" class="px-5">Add</button>
        </div>
    </div>
    <div class="mt-2 table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- Checkbox Column -->
            <!-- <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                </td>
            </ng-container> -->

            <!-- Name Column -->
            <ng-container matColumnDef="lookup_type_name">
                <th mat-header-cell *matHeaderCellDef> Lookup type </th>
                <td mat-cell *matCellDef="let element"> {{element.lookup_type_name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="lookup_name">
                <th mat-header-cell *matHeaderCellDef> Lookup Name </th>
                <td mat-cell *matCellDef="let element"> {{element.lookup_name}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
    </div>
    <div *ngIf="total_count > 0">
        <app-pagination [datafromparent]="total_count" (onDatePicked)="pagination($event)"></app-pagination>
      </div>
</section>

<!-- </ng-container> -->
<!-- <ng-container *ngIf="lookupdata.length <= 0">
<p>No data Found</p>
</ng-container> -->