<!-- <ng-container *ngIf="lookupdata.length > 0"> -->
<section class="container">
  <app-toast></app-toast>
  <ng-container *ngIf="!showaddflag; else addForm">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h4 class="m-0">Leave Type</h4>
      </div>
      <div>
        <button mat-raised-button color="primary" (click)="showadd()" class="px-5">Add</button>
      </div>
    </div>
    <div class="mt-2 table-container">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <!-- <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container> -->
        <!-- Weight Column -->
        <ng-container matColumnDef="leave_name">
          <th mat-header-cell *matHeaderCellDef>Leave Name</th>
          <td mat-cell *matCellDef="let element"> {{element.leave_name || '-'}} </td>
        </ng-container>
        <ng-container matColumnDef="leave_code">
          <th mat-header-cell *matHeaderCellDef>Leave Code</th>
          <td mat-cell *matCellDef="let element"> {{element.leave_code || '-'}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef>Designation</th>
          <td mat-cell *matCellDef="let element"> {{element.designation || '-'}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="department_manager_id">
                  <th mat-header-cell *matHeaderCellDef>Department Manager</th>
                  <td mat-cell *matCellDef="let element"> {{element.maximum_allowed_leaves}} </td>
              </ng-container> -->

        <!-- Symbol Column -->
        <ng-container matColumnDef="maximum_allowed_leaves">
          <th mat-header-cell *matHeaderCellDef> Maximum Allowed Leaves </th>
          <td mat-cell *matCellDef="let element"> {{element.maximum_allowed_leaves || '-'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-template #addForm>
    <div class="d-flex align-items-center">
      <div>
        <button mat-icon-button class="back_icon me-2" (click)="showadd()"><mat-icon>arrow_back</mat-icon></button>
      </div>
      <div>
        <h4 class="m-0">Add Leave Type</h4>
      </div>
    </div>

    <div class="bg-white p-3 mt-2">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="row">
          <div *ngFor="let question of questions" class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4">
            <app-question [question]="question" [form]="form"></app-question>
          </div>
        </div>
        <div class="form-row">
          <button mat-raised-button color="primary" class="px-5" type="submit" [disabled]="!form.valid">
            Submit
          </button>
        </div>
      </form>


      <!-- <div *ngIf="payLoad" class="form-row">
            <strong>Saved the following values</strong><br>{{payLoad}}
          </div> -->
    </div>
  </ng-template>
</section>