import { Component, OnInit, inject } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { DepartmentService } from '../../inputfileds/form_services/department/department.service';
import { Inputfiledsbase } from '../../inputfileds/models/inputfiledsbase';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SettingServiceService } from '../../Services/setting-service.service';
import { Observable } from 'rxjs/internal/Observable';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatIconModule } from '@angular/material/icon';
import { ToastService } from '../../Services/toast/toast.service';
import { ToastComponent } from '../../components/toast/toast.component';
export interface departmentView {
  index: number,
  id: number,
  organization_id: number,
  department_manager_id: number,
  department_code: string,
  department_name: string,
  description: string,
}

@Component({
  selector: 'app-department-view',
  standalone: true,
  imports: [
    DynamicFormQuestionComponent, 
    MatTableModule, 
    MatIconModule, 
    MatCheckboxModule, 
    CommonModule, 
    MatButtonModule, 
    RouterModule, 
    MatInputModule, 
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    ToastComponent
],
  templateUrl: './department.component.html',
  styleUrl: './department.component.scss',
  providers: [ApiservicesService, QuestionControlService, DepartmentService]
})

export class DepartmentComponent implements OnInit {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad = '';
  departmentData = []
  displayedColumns: string[] = ['department_code', 'department_name', 'description'];
  dataSource = new MatTableDataSource<departmentView>();
  selection = new SelectionModel<departmentView>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;

  constructor(private apiservice: ApiservicesService, private qcs: QuestionControlService, service: DepartmentService, private toast: ToastService) {
    this.questions$ = service.getdepartmentform();
    this.questions$.subscribe(q => this.questions = q);

    this.apiservice.getdepartmentdetails().subscribe((res) => {
      this.departmentData = res.rows
      this.dataSource = new MatTableDataSource<departmentView>(res.rows)
      console.log("=>",res)
    })
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }

  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: departmentView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

  onSubmit() {
    this.payLoad = this.form.getRawValue();
    this.datesend = this.form.value;
    this.datesend.department_manager_id = parseInt(this.form.value.department_manager_id);
    this.datesend.organization_id = parseInt(this.form.value.organization_id);
    console.log(this.payLoad);
    this.createAPi(this.datesend)
  }

createAPi(event: any) {
  this.apiservice.createdepartment(event).subscribe((res: any) => {
      if (res.code === 201 && res.status === true) {
        this.toast.showToast(
          'success',
          'Saved Successfuly',);
          this.form.reset();
      } else{
        this.toast.showToast('danger', `Error: ${res.message}`);
      }
    },
    (error) => {
      this.toast.showToast(
        'warning',
        error.message);
    }
  );
}}
