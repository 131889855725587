import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import {MatDatepickerInputEvent, MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { provideNativeDateAdapter } from '@angular/material/core';
import { CommonModule } from '@angular/common'; 

@Component({
  selector: 'app-logger',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, MatDatepickerModule],
  providers:[ApiservicesService, provideNativeDateAdapter()],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './logger.component.html',
  styleUrl: './logger.component.scss'
})
export class LoggerComponent implements OnInit{

  loggerdata:any;
  loggerdataflag: boolean = false;

  constructor(
    private apiservice: ApiservicesService,
  ){}
  ngOnInit(): void {
    this.getloogerdetails()
  }

  getloogerdetails(){
    let body={
      "date":"2024-06-26"
    }
    this.apiservice.getloggerdata(body).subscribe((res:any) => {
      console.log('logger data', res.data)
      this.loggerdata = res.data
      this.loggerdataflag = true
    });
  }
}
