import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { CoursesService } from '../../../inputfileds/form_services/courses/courses.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AddacademicregulationsComponent } from './addacademicregulations/addacademicregulations.component';
import { AcademicsService } from '../../../inputfileds/form_services/academics/academics.service';
import { ToastComponent } from '../../../components/toast/toast.component';
import { PaginationComponent } from '../../../components/pagination/pagination.component';
import { ToastService } from '../../../Services/toast/toast.service';
import { SectionsService } from '../../../inputfileds/form_services/academics/sections/sections.service';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';

export interface AcademicsView {
  index: number;
  id: number;
  organization_id: number;
  code: string;
  courses: { id: number; name: string }[];
  start_year: number;
  end_year: number;
}

@Component({
  selector: 'app-academicregulations',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatProgressSpinnerModule,
    CommonModule,
    ToastComponent,
    PaginationComponent,
  ],
  providers: [QuestionControlService, ApiservicesService, SectionsService],
  templateUrl: './academicregulations.component.html',
  styleUrls: ['./academicregulations.component.scss'],
})
export class AcademicregulationsComponent implements OnInit {
  displayedColumns: string[] = ['code', 'courses', 'start_year', 'Sections', 'actions'];

  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null | undefined;
  createsectionForm!: FormGroup;
  createsection: Inputfiledsbase<any>[] | null = [];
  createsection$: Observable<Inputfiledsbase<any>[]> | null;
  BatchData: any;
  sectionData: any;
  dataSource = new MatTableDataSource<AcademicsView>();
  selection = new SelectionModel<AcademicsView>(true, []);
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  acadamicsList: any;
  isLoadingResults = true;
  isRateLimitReached = false;
  courseData: any;
  total_count: any;
  paginationData: any;
  datasend: any;
  @ViewChild('createSectionDialog') createSectionDialog!: TemplateRef<any>;
  selectedregulationdata: any;
  constructor(
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
    private router: Router,
    private qcs: QuestionControlService,
    private toastService: ToastService,
    private dataService: SectionsService,
    private service: AcademicsService
  ) {
    this.questions$ = dataService.getRegulationstudentfilterForm();
    this.questions$.subscribe(q => this.questions = q);
    this.createsection$ = dataService.getCreatebatchSectionForm();
    this.createsection$.subscribe(questions => this.createsection = questions);
  }

  ngOnInit(): void {
    this.createsectionForm = this.qcs.toFormGroup(this.createsection as Inputfiledsbase<string>[]);
    this.getacadamicsdetails();
  }
  pagination(event: any) {
    this.paginationData = event;
    this.getacadamicsdetails();
  }

  getacadamicsdetails() {
    this.apiservice.getacadamicslist(this.paginationData).subscribe((res) => {
      this.acadamicsList = res.rows;
      this.total_count = res.total_count;
      this.dataSource = new MatTableDataSource<AcademicsView>(
        this.acadamicsList
      );
      this.isLoadingResults = false;
      this.isRateLimitReached = res === null;
    });
  }

  openAddAcademicsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated', 'animate__fadeIn'];
    dialogConfig.minWidth = '80%';
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';
    const dialogRef = this.dialog.open(
      AddacademicregulationsComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      console.log('dialog result', result);
      if (result) {
        this.getacadamicsdetails();
        console.log('Dialog result:', result);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: AcademicsView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.index + 1
    }`;
  }

  showsemesterdetails(id: any) {
    this.router.navigate(['/academicregulationsview'], { state: { data: id } });
  }
  
  openCreateSectionDialog(event:any): void {
    console.log('selected data', event)
    this.selectedregulationdata = event;
    const dialogRef = this.dialog.open(this.createSectionDialog, {
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  closeDialog(event: any) {
    this.dialog.closeAll();
    // const dialogRef = this.dialog.open(this.createSectionDialog);
    // this.getSectionsDetails();
    this.getacadamicsdetails()
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.remove('animate__fadeIn');
    document
      .getElementsByClassName('animate__animated')[0]
      .classList.add('animate__fadeOut');
    setTimeout(() => {
      this.dialog.closeAll();
    }, 1000);
  }

  onSaveClick(): void {
    this.dialog.closeAll();
  }

  // backPage(): void {
  //   this.router.navigate(['/academicregulations']).then(() => {
  //     this.BatchData;
  //   });
  // }

  onSubmit() {
    this.datasend = {
      organization_id: this.selectedregulationdata.organization_id,
      course_id: this.selectedregulationdata.course_id,
      academic_regulation_id: this.selectedregulationdata.id,
      // academic_year_id: this.selectedregulationdata.academic_year_id,
      sections:  this.createsectionForm.value.section_id,
    };
    this.createAPi(this.datasend);
  }

  createAPi(event: any) {
    this.apiservice.createacadamicssections(event).subscribe(
      (res: any) => {
        if (res.code === 201 && res.status === true) {
          this.toastService.showToast('success', 'Saved Successfully');
          this.closeDialog(true);
        } else if (res.status === false) {
          this.toastService.showToast('danger', res.message);
        }
      },
      (error) => {
        this.toastService.showToast('warning', `Error: ${error.message}`);
      }
    );
  }
}
