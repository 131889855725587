<div class="mt-2 table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <!-- <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container> -->

        <ng-container matColumnDef="shift_name">
            <th mat-header-cell *matHeaderCellDef> Shift </th>
            <td mat-cell *matCellDef="let element"> {{element.shift_name}} </td>
        </ng-container>

        <ng-container matColumnDef="shift_start_time">
            <th mat-header-cell *matHeaderCellDef> Start Time </th>
            <td mat-cell *matCellDef="let element"> {{element.shift_start_time}} </td>
        </ng-container>

        <ng-container matColumnDef="shift_end_time">
            <th mat-header-cell *matHeaderCellDef> End Time </th>
            <td mat-cell *matCellDef="let element"> {{element.shift_end_time}} </td>
        </ng-container>

        <ng-container matColumnDef="shit_end_day">
            <th mat-header-cell *matHeaderCellDef> End Day </th>
            <td mat-cell *matCellDef="let element"> {{element.shift_end_day}} </td>
        </ng-container>

        <ng-container matColumnDef="break_type">
            <th mat-header-cell *matHeaderCellDef> Brake Type </th>
            <td mat-cell *matCellDef="let element"> {{element.break_type}} </td>
        </ng-container>

        <ng-container matColumnDef="break_start_time">
            <th mat-header-cell *matHeaderCellDef> Brake Time</th>
            <td mat-cell *matCellDef="let element"> {{element.break_start_time}} - {{element.break_end_time}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
        </tr>
    </table>
</div>