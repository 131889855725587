import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { QuestionService } from './inputfileds/form_services/question/question.service';
import { AsyncPipe, CommonModule } from '@angular/common';
import { LookuptypesComponent } from './pages/lookuptypes/lookuptypes.component';
import { PageHeaderComponent } from './layout/page-header/page-header.component';
import { HeaderComponent } from './layout/header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { User } from './Services/Models/user';
import { AuthenticationService } from './components/Authentication/services/authentication.service';
import { ToastComponent } from './components/toast/toast.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastComponent, AsyncPipe, CommonModule, LoginComponent, LookuptypesComponent, PageHeaderComponent, HeaderComponent, LayoutComponent],
  providers: [QuestionService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'visaka_erp';
  ngOnInit() {
  }
  user: any | undefined;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    if (this.authenticationService.user) {
      this.authenticationService.user.subscribe(x => {
        this.user = x
    });
    }
  }
  ngAfterContentChecked() {
    if (this.authenticationService.user) {
      this.authenticationService.user.subscribe(x => this.user = x);
    }
  }
}
