import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { TimetablesService } from '../../../../inputfileds/form_services/timetable/timetables.service';
import { QuestionControlService } from '../../../../inputfileds/form_services/question-control.service';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../inputfileds/models/inputfiledsbase';
import { filter, Observable } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicFormQuestionComponent } from '../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-addtimetables',
  standalone: true,
  templateUrl: './addtimetables.component.html',
  styleUrl: './addtimetables.component.scss',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    DynamicFormQuestionComponent,
    MatIconModule,
    MatTableModule,
    MatCardModule,
  ],
  providers: [QuestionControlService, TimetablesService, ApiservicesService],
})

export class AddtimetablesComponent implements OnInit {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  AddSlotQuestions: Inputfiledsbase<string>[] | null = [];
  AddSlotQuestions$: Observable<Inputfiledsbase<any>[]> | null;
  addTimeslotform!: FormGroup;
  timeslotform!: FormGroup;
  showForm: boolean = false;
  organisationdata: any = [];
  errormessage: any;
  editMode: boolean = false;
  currentEditingSlotIndex: number | null = null;
  timeSlots: any[] = [];
  TimeSlotsData: any;

  constructor(
    private apiservice: ApiservicesService,
    private services: TimetablesService,
    private router: Router,
    private fb: FormBuilder,
    private qcs: QuestionControlService,
  ) {
    this.questions$ = services.getAddTimetableform();
    this.questions$.subscribe((q) => (this.questions = q));
    this.AddSlotQuestions$ = services.getAddTimeSlotform();
    this.AddSlotQuestions$.subscribe((q) => (this.AddSlotQuestions = q));

    this.addTimeslotform = this.fb.group({
      academic_calender_id: [''],
      organization_id: [''],
      start_day: [''],
      end_day: [''],
      start_time: [''],
      end_time: [''],
    });
  }

  ngOnInit(): void {
    this.timeslotform = this.qcs.toFormGroup(
      this.questions as Inputfiledsbase<string>[]
    );
    // this.addTimeslotform = this.qcs.toFormGroup(
    //   this.AddSlotQuestions as Inputfiledsbase<string>[]
    // );
    this.gettimetabledata();
  }

  onExit() {
    this.router.navigate(['timetable']);
  }


  gettimetabledata(){
   const demodata = {
      "organization_id": 1,
      "course_id": 1,
      "semister_id": 1,
      "shift_id":1,
      "academic_year_id": 3,
      "section_id":1,
      "section":"1"
    }

    this.apiservice.getTimeTabledata(demodata).subscribe({
      next: (res: any) => {
        console.log("API Response time table:", res); // Log full response
        if (res && res.data) {
          this.timeSlots = res.rows;
          console.log("Updated timeSlots:", this.timeSlots);
        } else {
          // console.warn('No timeslots found for the given shift_id:', shift_id);
        }
      },
      error: (err) => {
        console.error('Error fetching timeslots:', err)
      },
    });
  }
}
