<!--We canged the toastTrigger and class-->
<div class="position-relative">
    <div [@toastTrigger]="(toastservice.showsToast$ | async) ? 'open' : 'close'"
        [class]="toastservice.toastState$ | async" style="background-color: #fff;">
        <div>
            <!--We access the toastMessage$ observable in the service-->
            {{ truncateMessage(toastservice.toastMessage$ | async ) }}
        </div>
        <div class="position-absolute top-0 end-0 p-1" (click)="dismiss()">
            <mat-icon aria-hidden="false" aria-label="Cancel" fontIcon="close"></mat-icon>
        </div>
    </div>
</div>