import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatPaginatorModule,
    JsonPipe,
  ],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnInit {
  

  @Input() datafromparent :number | undefined ;
  @Output() onDatePicked = new EventEmitter<any>()

  length: number | undefined ;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent!: PageEvent;

  constructor(){
    console.log("tsudfgsjbfkd", this.datafromparent)
  } 

  ngOnInit(): void {
    console.log("total count", this.datafromparent)
   this.length = this.datafromparent
    this.pageEvent = {
      length:5864,
      pageIndex: this.pageIndex,
      pageSize:  this.pageSize,
      previousPageIndex:0
    }
    this.onDatePicked.emit(this.pageEvent);
  }

  handlePageEvent(e: PageEvent) {
    console.log("datafromparent", this.datafromparent)
    console.log("datatoparent", e)
    this.onDatePicked.emit(e);
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
  }
  
}
