import { Injectable } from '@angular/core';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';
import { of } from 'rxjs';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { TimepickerQuestion } from '../../question-timepicker';
@Injectable({
  providedIn: 'root'
})
export class TimetablesService {
  academicyeardata: any = []; 
  shiftdropdata: any = [];
  weekdays: { key: string; id: string; value: number; }[] = [
    { key: 'monday', id: 'MONDAY', value: 1 },
    { key: 'tuesday', id:'TUESDAY', value: 2 },
    { key: 'wednesday', id:'WEDNESDAY', value: 3 },
    { key: 'thursday', id: 'THURSDAY', value: 4 },
    { key: 'friday', id:'FRIDAY', value: 5 },
    { key: 'saturday', id:'SATURDAY', value: 6 },
    { key: 'sunday', id: 'SUNDAY', value: 7 }
  ];

  constructor(private apiservices: ApiservicesService) {
    this.apiservices.getacademicyeardrop().subscribe((res: any) => 
      {
        for(let i=0; i < res.length; i++){
          this.academicyeardata?.push({id:res[i].id, key: res[i].name, value:res[i].id})
        }
      });
      this.apiservices.getshiftdrop().subscribe((res: any) =>{
        for(let i=0; i < res.length; i++){
          this.shiftdropdata?.push({id:res[i].id, key: res[i].name, value:res[i].id})
        }
      });
   }
   
  getTimeSlotsform(){

    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion ({
        key: 'academic_calender_id',
        label: 'Academic Year',
        type: 'Number',
        required: true,
        options: this.academicyeardata,
        order: 1
      }),
      new DropdownQuestion ({
        key: 'shift_id',
        label: 'Shifts',
        type: "Number",
        required: true,
        options: this.shiftdropdata,
        order: 2
      }),
  ];
  return of (questions.sort((a, b) => a.order - b.order));
  }

  getAddTimeSlotform(){

    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion ({
        key: 'start_day',
        label: 'Start Day',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 1
      }),
      new TimepickerQuestion ({
        key: 'start_time',
        label: 'Start Time',
        type: "time",
        required: true,
        order: 2
      }),
      new TimepickerQuestion ({
        key: 'end_time',
        label: 'End Time',
        type: "time",
        required: true,
        order: 3
      }),
      new DropdownQuestion ({
        key: 'end_day',
        label: 'End Day',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 4
      }),
  ];
  return of (questions.sort((a, b) => a.order - b.order));
  }

  getAddTimetableform(){

    const questions: Inputfiledsbase<string>[] = [
      new DropdownQuestion ({
        key: 'organization_id',
        label: 'Organization',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 6
      }),
      new DropdownQuestion ({
        key: 'academic_year_id',
        label: 'Academic Year',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 1
      }),
      new DropdownQuestion ({
        key: 'section_id',
        label: 'Section',
        type: "number",
        required: true,
        options: this.weekdays,
        order: 2
      }),
      new DropdownQuestion ({
        key: 'shift_id',
        label: 'Shift',
        type: "number",
        required: true,
        options: this.weekdays,
        order: 3
      }),
      new DropdownQuestion ({
        key: 'course_id',
        label: 'Course',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 4
      }),
      new DropdownQuestion ({
        key: 'semister_id',
        label: 'Semister',
        type: 'Number',
        required: true,
        options: this.weekdays,
        order: 5
      }),
  ];
  return of (questions.sort((a, b) => a.order - b.order));
  }

}
