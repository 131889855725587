<div class="feature-header justify-content-between align-items-center">
    <div class="start">
      <div class="title">
        <mat-icon  class="feature-icon mr-3">
          {{ icon }}
        </mat-icon>
        <ng-content select="h2"></ng-content>
      </div>

      <ng-content select="p"></ng-content>
    </div>

    <ng-content select=".action"></ng-content>
  </div>