import { Component, Inject } from '@angular/core';
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { CommonModule } from '@angular/common';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { CompoffService } from '../../../../../inputfileds/form_services/Compoff/compoff.service';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable } from 'rxjs';
import { ApplyleavesService } from '../../../../../inputfileds/form_services/applyleaves/applyleaves.service';

@Component({
  selector: 'app-compoffdialogbox',
  standalone: true,
  // imports: [],
  templateUrl: './compoffdialogbox.component.html',
  styleUrl: './compoffdialogbox.component.scss',
  imports: [MatIconModule, MatDividerModule, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, CompoffService, ApiservicesService]
})
export class CompoffdialogboxComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad = '';
  CompoffData = []
  showaddflag: boolean = false;

  constructor(public dialogRef: MatDialogRef<CompoffdialogboxComponent>, private apiservice: ApiservicesService, private qcs: QuestionControlService, service: ApplyleavesService) {
    this.questions$ = service.getCompoffLeaves();
    this.questions$.subscribe(q => this.questions = q);

    this.apiservice.getcoursesdetails().subscribe((res) => {
      this.CompoffData = res.rows
      // this.dataSource = new MatTableDataSource<CourseView>(res.rows)
      console.log("=>", res)
    })
  }

  showadd() {
    this.showaddflag = !this.showaddflag;
  }
  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
  }

  onSubmit() {

  }

  Canceldailog(): void {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInRight")
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutRight");
    setTimeout(() => { this.dialogRef.close(); }, 1000);
  }
}
