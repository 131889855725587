import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Inputfiledsbase } from '../../../../../inputfileds/models/inputfiledsbase';
import { Observable, forkJoin } from 'rxjs';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiservicesService } from '../../../../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../../../../inputfileds/form_services/question-control.service';
import { workService } from '../../../../../inputfileds/form_services/workservice/workservice.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicFormQuestionComponent } from '../../../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { ToastService } from '../../../../../Services/toast/toast.service';

@Component({
  selector: 'app-addholidays',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule,MatIconModule, MatDividerModule,FormsModule, MatFormFieldModule,MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, workService, ApiservicesService],
  templateUrl: './addholidays.component.html',
  styleUrl: './addholidays.component.scss'
})
export class AddholidaysComponent {
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    questions: Inputfiledsbase<string>[] | null = [];
    questions$: Observable<Inputfiledsbase<any>[]> | null;
    holidayForm!: FormGroup;
    payLoad : any;
    permissionData = []
    showaddflag: boolean = false;
  
    constructor(
      public dialogRef: MatDialogRef<AddholidaysComponent>,private apiservice: ApiservicesService,private toast : ToastService, private qcs: QuestionControlService, service: workService) {
        this.questions$ = service.getHolidayservices();
        this.questions$.subscribe(q => this.questions = q);
      }
  
    ngOnInit(): void {
      this.holidayForm = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
    }

    onSubmit() {
      this.payLoad = this.holidayForm.getRawValue();
      if (this.payLoad.date) {
        const localDate = new Date(this.payLoad.date);
        const utcDate = new Date(Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate()
        ));
        this.payLoad.date = utcDate.toISOString();
      }
      forkJoin([
        this.apiservice.getlookupcodesdropdown('HOLIDAY_TYPES'),
      ]).subscribe(([holidaytypeRes]) => {
        const filteredStatus = holidaytypeRes.find((dropfilter:any)=>{ return dropfilter.id == this.holidayForm.value.holiday_type_id });
        this.payLoad = { ...this.payLoad, holiday_type: filteredStatus?.lookup_name };
        this.createAPi(this.payLoad);
      });
    }
  
    createAPi(event:any){
      this.apiservice.createholidays(event).subscribe((res: any) => {
        if(res.code === 201 && res.status === true){
          this.holidayForm.reset('');
          this.dialogRef.close();
          this.toast.showToast(
            'success',
            `${res.message}`,);
        }
        })
    }
  
    Canceldailog(): void {
      document.getElementsByClassName("animate__animated")[0].classList.remove("animate__fadeIn")
      document.getElementsByClassName("animate__animated")[0].classList.add("animate__fadeOut");
      setTimeout(()=>{this.dialogRef.close();}, 1000);
      // this.dialogRef.close();
    }
    
  }