import { Component } from '@angular/core';
import { OrganizationsService } from '../../../inputfileds/form_services/organization/organizations.service';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { QuestionControlService } from '../../../inputfileds/form_services/question-control.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { DynamicFormQuestionComponent } from '../../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { Inputfiledsbase } from '../../../inputfileds/models/inputfiledsbase';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-addsuborganisation',
  standalone: true,
  imports: [FormsModule,MatFormFieldModule,MatDialogModule,MatCheckboxModule,MatIcon,MatTableModule, RouterModule, MatButtonModule, MatInputModule, ReactiveFormsModule, DynamicFormQuestionComponent, CommonModule],
  providers: [QuestionControlService, OrganizationsService, ApiservicesService],
  templateUrl: './addsuborganisation.component.html',
  styleUrl: './addsuborganisation.component.scss'
})
export class AddsuborganisationComponent {
  questions: Inputfiledsbase<string>[] | null = [];
  questions$: Observable<Inputfiledsbase<any>[]> | null;
  form!: FormGroup;
  payLoad :any;
  datesend: any;

constructor(public suborganisationService: OrganizationsService,private qcs: QuestionControlService,private fb: FormBuilder, public apiService: ApiservicesService){
  this.questions$ = suborganisationService.getOrganizationservices();
  this.questions$.subscribe(q => this.questions = q);
  
}

ngOnInit(): void {
  this.form = this.qcs.toFormGroup(this.questions as Inputfiledsbase<string>[]);
}

onSubmit() {
  this.payLoad = this.form.getRawValue();
  this.datesend = this.form.value;
  console.log(this.payLoad);
 
  forkJoin([
    this.apiService.getlookupcodesdropdown('DESIGNATIONS'),
  ]).subscribe(([designationRes]) => {
    const filteredStatus = designationRes.filter((dropfilter:any)=>{ return dropfilter.id === this.form.value.designation_id });
    this.payLoad = { ...this.payLoad, designation: filteredStatus[0]?.lookup_name };
    // this.payLoad.maximum_allowed_leaves = parseInt(this.form.value.maximum_allowed_leaves);
    this.createAPis(this.payLoad);
  });
}

createAPis(event:any){
  this.apiService.createorganization(event).subscribe((res: any) => {
    if(res.code === 201 && res.status === true){
      this.form.reset();
}})
}

}
