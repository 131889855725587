<section class="container py-0">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="d-flex align-items-center">
      <button mat-icon-button class="back_icon me-2" (click)="backPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div *ngIf="BatchData">
        <h4 class="m-0">{{ BatchData?.start_date | date: 'YYYY' }} - {{ BatchData.end_date | date: 'YYYY' }} Academics</h4>
      </div>
    </div>
  </div>

  <div class="semester_details_card">
    <div class="bg-white card p-4">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab label="Semesters">
          <div class="d-flex justify-content-between align-items-center mb-1 p-3">
            <h3 class="m-0">Academic Semisters List</h3>
            <button mat-raised-button color="primary" class="px-4"(click)="openCreateSemisterDialog()">Add</button>
          </div>
          <div class="mt-2 table-container">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Sl.no </th>
                <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
              </ng-container>
              <ng-container matColumnDef="batch">
                <th mat-header-cell *matHeaderCellDef> Batch </th>
                <td mat-cell *matCellDef="let element"> {{ element.start_date | date: 'YYYY' }} - {{ element.end_date | date: 'YYYY' }} </td>
              </ng-container>
              <ng-container matColumnDef="semister">
                <th mat-header-cell *matHeaderCellDef> Semister </th>
                <td mat-cell *matCellDef="let element"> {{ element.semister }} </td>
              </ng-container>
              <ng-container matColumnDef="course_name">
                <th mat-header-cell *matHeaderCellDef> Course Name </th>
                <td mat-cell *matCellDef="let element"> {{ element.course_name }} </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                  <button mat-button color="primary">Edit</button>
                  <button mat-button color="warn" >Delete</button>
                </td>
              </ng-container>
              
              <!-- Define Header and Row Definitions -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        
          <ng-template #createSectionDialog let-dialogRef>
            <div class="">
              <div class="dialog-header">
                <h3 mat-dialog-title>Add Semester Name</h3>
                <button mat-icon-button mat-dialog-close (click)="onSaveClick()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <div mat-dialog-content class="dialog-content">
                <form [formGroup]="createsemisterForm" (ngSubmit)="onSubmit()">
                  <div class="row">
                  <div *ngFor="let question of createsemister" class="form-group mb-2 col-md-6 col-sm-6">
                    <app-question [question]="question" [form]="createsemisterForm"></app-question>
                  </div>
                  </div>
                </form>
              </div>
              <div mat-dialog-actions class="dialog-actions">
                <button mat-button mat-dialog-close color="warn" (click)="onSaveClick()">Cancel</button>
                <button mat-button color="primary" [disabled]="!createsemisterForm.valid" (click)="onSubmit()">
                  Submit
                </button>
              </div>
              </div>
            </ng-template>
          
        </mat-tab>
        <mat-tab label="Working Hours">
          <div class="">
              <div class="text-end mt-2"><button mat-flat-button color="primary"
                      (click)="openShiftDialog()"><mat-icon>add</mat-icon> <span>Add Shift</span></button></div>
              <div><app-shift></app-shift></div>
          </div>
      </mat-tab>
      <mat-tab label="Week Off">
        <div class="">
            <div class="text-end mt-2"><button mat-flat-button color="primary"
                    (click)="openweekoffDialog()"><mat-icon>add</mat-icon> <span>Add Week Off</span></button>
            </div>
            <div><app-weekoff></app-weekoff> </div>
        </div>
    </mat-tab>
    <mat-tab label="Holidays">
        <div class="">
            <div class="text-end mt-2"><button mat-flat-button color="primary"
                    (click)="openholidayDialog()"><mat-icon>add</mat-icon> <span>Add Holiday</span></button>
            </div>
            <div><app-holidays></app-holidays></div>
        </div>
    </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</section>