<h2 mat-dialog-title class="px-3 pt-2">
  Upload {{ data.uploadType === "details" ? "Details" : "Image" }}
</h2>
<input #csvInput hidden type="file" (change)="onFileSelected($event)" />

<div mat-dialog-content class="dialog-content">
  <div class="upload-container">
    <img
      src="../../../assets/images/bulkupload_logo.svg"
      (click)="csvInput.click()"
      alt="Upload Icon"
      class="upload-icon"
    />
  </div>
  <p *ngIf="selectedFile" class="file-name">{{ selectedFile.name }}</p>
</div>

<div mat-dialog-actions class="dialog-actions">
  <button
    mat-raised-button
    color="primary"
    (click)="closeDialog()"
    mat-dialog-close
  >
    Close
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="
      data.uploadType === 'details' ? handleUpload() : handleImagesUpload()
    "
    [disabled]="isLoading || !selectedFile"
  >
    Upload {{ data.uploadType === "details" ? "Details" : "Image" }}
  </button>
</div>
