import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Inputfiledsbase } from '../../../models/inputfiledsbase';
import { DropdownQuestion } from '../../../question-dropdown';
import { TextboxQuestion } from '../../../question-textbox';
import { ApiservicesService } from '../../../../Services/Apis/apiservices.service';
import { ObjectDropdownQuestion } from '../../../question-objectdropdown';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  organisationdata: any = [];
  semisterdata:any = [];
  sectiondata:any = [];
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private courselistSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  courselist$: Observable<any[]> = this.courselistSubject.asObservable();

  constructor(private apiservice: ApiservicesService) {

    this.loadDropdownData('ORGANISATION', this.organisationdata);
    this.loadDropdownData('SEMISTER', this.semisterdata);
    this.loadDropdownData('SECTIONS', this.sectiondata);

    this.apiservice.getcourseslist().subscribe((res) => {
      const courselist = res.rows.map((course: any) => ({
        'course_ID': course.id,
        'name': course.course_name
      }));
      this.courselistSubject.next(courselist);
      console.log('Course List:', this.courselistSubject);
    });
    // this.apiservice.getsemisterdetails().subscribe((res) => {
    //   const semisterlist = res.semister_number;
    // });
  }

  private loadDropdownData(code: string, dataArray: any[]) {
    let observable: Observable<any>;

    if (code === 'ORGANISATION') {
      observable = this.apiservice.getorganisation();
    } else if(code === 'SEMISTER'){
      observable = this.apiservice.getsemisterdrop();
    } else if(code === 'SECTIONS'){
      observable = this.apiservice.getsectionsdrop();
    } else {
      observable = this.apiservice.getlookupcodesdropdown(code);
    }

    observable.subscribe((res) => {
      for (let i = 0; i < res.length; i++) {
        let lookupname;
        if (code === 'ORGANISATION') {
          lookupname = res[i]?.short_name;
        } else if (code === 'SEMISTER' || 'SECTIONS') {
          lookupname = res[i]?.name;
        } else {
          lookupname = res[i]?.lookup_name;
        }
        dataArray.push({
          id: res[i].id,
          key: lookupname,
          value: res[i].id,
        });
      }
    });
  }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getData(): Observable<any> {
    console.log('hit method from semester details', this.dataSubject);
    return this.dataSubject.asObservable();
  }

  getRegulationstudentfilterForm(): Observable<Inputfiledsbase<string>[]> {
    return new Observable((observer) => {

      const createquestions: Inputfiledsbase<string>[] = [
          // new DropdownQuestion({
          //   key: 'course_id',
          //   label: 'Course',
          //   required: true,
          //   options: this.courselistSubject.getValue(),
          //   order: 1,
          // }),
          new DropdownQuestion({
            key: 'semister_id',
            label: 'Semister',
            required: true,
            options: this.semisterdata,
            order: 1,
          }),
          new DropdownQuestion({
            key: 'section_id',
            label: 'Section',
            required: true,
            options: this.sectiondata,
            order: 1,
          }),
        ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
      })
  }

  getCreateSectionForm(): Observable<Inputfiledsbase<string>[]> {
    return new Observable((observer) => {
      const createquestions: Inputfiledsbase<string>[] = [
        
        new DropdownQuestion({
          key: 'organization_id',
          label: 'Organization',
          required: true,
          options: this.organisationdata,
          order: 1,
        }),

        new TextboxQuestion({
          key: 'sequence',
          label: 'Sequence',
          type: 'number',
          required: true,
          // patterns: '^\\d{1,2}(\\.\\d{1,2})?$',
          order: 2,
        }),

        new TextboxQuestion({
          key: 'name',
          label: 'Section Name',
          required: true,
          order: 3,
        }),
      ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
    });
  }

  getCreatebatchSectionForm(): Observable<Inputfiledsbase<string>[]> {
    return new Observable((observer) => {
      const createquestions: Inputfiledsbase<string>[] = [

        new ObjectDropdownQuestion({
          key: 'section_id',
          label: 'Section',
          required: true,
          options: this.sectiondata,
          order: 1,
        })

      ];
      observer.next(createquestions.sort((a, b) => a.order - b.order));
      observer.complete();
    });
  }
}
