<section class="container">
  <div class="course-info">
    <button mat-icon-button (click)="closeClasses()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="text-start">
      {{ classesData.degree_type }} - {{ classesData.year }}- Section
      {{ classesData.section }}
    </span>
  </div>

  <div class="attendance-container">
    <div class="main-content">
      <mat-card class="attendance-list">
        <mat-table [dataSource]="attendanceData">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef = "let element">
              <mat-icon
                [ngClass]="{
                  absent: element.status === 'Absent',
                  present: element.status === 'Present'
                }"
                (click)="toggleStatus(element)"
                class="status-toggle"               
              >
                {{ element.status === 'Absent' ? 'toggle_off' : 'toggle_on' }}
              </mat-icon>      
              <p class="status-text">{{element.status === 'Absent' ? 'Absent' : 'Present'}}</p>     
            </mat-cell>
          </ng-container>

        <ng-container matColumnDef="tillDate">
          <mat-header-cell *matHeaderCellDef> Till Date % </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div class="progress-bar-container">
              <mat-progress-bar
                mode="determinate"
                [value]="element.tillDate"
                [ngClass]="{
                  'progress-red': element.tillDate < 50,
                  'progress-green': element.tillDate > 50
                }"
              ></mat-progress-bar>
            </div>
            <span>{{ element.tillDate }}%</span>
          </mat-cell>
        </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>

        <div class="button-container">
          <button mat-raised-button color="primary">Save</button>
        </div>
      </mat-card>

      <div class="right-container">
        <mat-card class="attendance-data">
          <h2>Attendance Data</h2>
          <div class="chart-container">
            <div style="display: block">
              <canvas baseChart id="MyChart">{{ chart }}</canvas>
            </div>
          </div>
        </mat-card>
        <mat-card>
          <div class="absentees-data">
            <h3>Absentees Data</h3>
            <mat-list>
              <mat-list>
                <mat-list-item *ngFor="let item of absenteesData" class="list-item">
                  <span class="item-id">{{ item.id }}</span>
                  <span class="item-name">{{ item.name }}</span>
                  <div class="icon-container">
                    <mat-icon class="icon">comment</mat-icon>
                  </div>
                </mat-list-item>
              </mat-list>
              
            </mat-list>            
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</section>
