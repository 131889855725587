<!-- <ng-container>
  <app-toast></app-toast>
</ng-container> -->
<div style="margin-top: 65px;">
    <app-header (menuToggled)="toggle()"></app-header>
    <mat-drawer-container class="bg-org-color">
    <!-- <ng-content class="sidebar_fixed"> -->
      <mat-drawer class="sidebar_fixed background-header-color border-0" mode="side" [opened]="opened">
        <app-menu-items [menu]="menu"></app-menu-items>
      </mat-drawer>
    <!-- </ng-content> -->
      <mat-drawer-content [class.margin-left]="opened" class="mt-3">
        <router-outlet></router-outlet>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>