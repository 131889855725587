import { Component, OnInit } from '@angular/core';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { StreamfromService } from '../../inputfileds/form_services/streamform/streamfrom.service';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { MatDialog } from '@angular/material/dialog';
import { AddstreamComponent } from './addstream/addstream.component';

export interface streamView {
  index: number,
  id: number,
  organization_id: number,
  stream_name: string,
  // category_id: number,
  // category_name: string,
  start_date: Date,
  end_date: Date,
}

@Component({
  selector: 'app-stream',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatIconModule
  ],
  providers: [QuestionControlService, StreamfromService, ApiservicesService],
  templateUrl: './stream.component.html',
  styleUrl: './stream.component.scss'
})
export class StreamComponent implements OnInit {

  displayedColumns: string[] = ['select', 'stream_name', 'category_name', 'start_date', 'end_date'];
  dataSource = new MatTableDataSource<streamView>();
  selection = new SelectionModel<streamView>(true, []);
  streamdata: any = [];
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  streamList: any

  constructor(
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getstreamdetails();
  }

  openAddStreamDialog() {
    const dialogRef = this.dialog.open(AddstreamComponent, {
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog result:', result);
      }
    });
  }

  getstreamdetails() {
    this.apiservice.getstreamlist().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<streamView>(res.rows);
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: streamView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.index + 1}`;
  }

}
