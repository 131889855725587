import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DynamicFormQuestionComponent } from '../../inputfileds/dynamic-form-question/dynamic-form-question.component';
import { QuestionControlService } from '../../inputfileds/form_services/question-control.service';
import { CoursesService } from '../../inputfileds/form_services/courses/courses.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router, RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { ApiservicesService } from '../../Services/Apis/apiservices.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddcoursesComponent } from './addcourses/addcourses.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PaginationComponent } from '../../components/pagination/pagination.component';

export interface CourseView {
  index: number;
  id: number;
  organization_id: number;
  course_name: string;
  course_code: string;
  department_name: string;
  number_of_semisters: number;
  description: string;
}

@Component({
  selector: 'app-courses-view',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTableModule,
    RouterModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    DynamicFormQuestionComponent,
    MatProgressSpinnerModule,
    CommonModule,
    PaginationComponent
  ],
  providers: [QuestionControlService, CoursesService, ApiservicesService],
  templateUrl: './courses.component.html',
  styleUrl: './courses.component.scss',
})
export class CoursesComponent implements OnInit{
  displayedColumns: string[] = [
    // 'select',
    'course_name',
    'course_code',
    'description',
    'status',
    'actions',
  ];
  dataSource = new MatTableDataSource<CourseView>();
  selection = new SelectionModel<CourseView>(true, []);
  // private apisevice = inject(ApiservicesService)
  lookupdatas: any;
  showaddflag: boolean = false;
  datesend: any;
  courseList : any
  isLoadingResults = true;
  isRateLimitReached = false;
  total_count: any;
  paginationData : any;

  constructor(
    private apiservice: ApiservicesService,
    private dialog: MatDialog,
    private router: Router,
    private qcs: QuestionControlService,
    service: CoursesService
  ) {}
  ngOnInit(): void {
    this.getCoursedetails();
  }

  pagination(event: any){
    this.paginationData= event;
    this.getCoursedetails();
  }

  getCoursedetails(){
    this.apiservice.getcourseslist(this.paginationData).subscribe((res) => {
      this.courseList = res.rows
      this.total_count = res.total_count;
      this.dataSource = new MatTableDataSource<CourseView>(res.rows);
      this.isLoadingResults = false;
          this.isRateLimitReached = res === null;
    });
  }

  openAddCourseDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = ['animate__animated','animate__fadeIn']
    dialogConfig.minWidth = '80%' ;
    dialogConfig.minHeight = '65vh';
    dialogConfig.maxHeight = '65vh';
    const dialogRef = this.dialog.open(AddcoursesComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      console.log("dialog result", result)
      if (result) {
        this.getCoursedetails();
        console.log('Dialog result:', result);
      }
    });
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CourseView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.index + 1
    }`;
  }

  // To show the semester page
  showsemesterdetails(id: any) {
    // const filteredData = this.courseList.filter((item: any) => item.id === parseInt(id));
    this.router.navigate(['/semesterdetails'],  { state: { data: id } });
  }
}
