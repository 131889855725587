<!-- Main Component Template -->
<section class="container">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h3 class="m-0">Sections List</h3>
      </div>
      <div>
        <button
          mat-raised-button
          color="primary"
          (click)="openCreateSectionDialog()"
          class="px-5"
        >
          Add
        </button>
      </div>
    </div>
    <div class="position-relative">
      @if (isLoadingResults || isRateLimitReached) {
      <div class="loading-shade">
        @if (isLoadingResults) {
        <mat-spinner class="mat_spinner"></mat-spinner>
        } @if (isRateLimitReached) {
        <div class="rate-limit-reached">
          Sorry!.. for Incovience  
        </div>
        }
      </div>
      }
      <div class="mt-2 table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Sl.no</th>
            <td mat-cell *matCellDef="let element">{{ element.id }}</td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Section Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>
          <ng-container matColumnDef="sequence">
            <th mat-header-cell *matHeaderCellDef>sequence</th>
            <td mat-cell *matCellDef="let element">{{ element.sequence }} </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <button mat-raised-button>
                View
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="selection.toggle(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data</td>
          </tr>
        </table>
      </div>
    </div>
</section>

<!-- Create Section Dialog Template -->
<ng-template #createSectionDialog>
  <h1 mat-dialog-title>Add Section Name</h1>
  <form (ngSubmit)="onSubmit()" [formGroup]="createsectionForm">
  <div mat-dialog-content>
    <div *ngFor="let question of createsection" class="question-container">
      <app-question [question]="question" [form]="createsectionForm"></app-question>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-actions">
    <button mat-flat-button (click)="onSaveClick()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!createsectionForm.valid">Submit</button>
  </div>
</form>
</ng-template>

