<section class="container">
  <app-toast></app-toast>
  <div class="header justify-content-between d-flex">
    <h2>Time Slots</h2>
    <div>
      <button
        mat-raised-button
        class="m-2"
        color="primary"
        type="submit"
        (click)="onSave()"
        [disabled]="!isFormValid() || isTimeSlotsEmpty()"
      >
        Save
      </button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="onExit()"
      >
      Exit
      </button>
    </div>
  </div>
  <div class="m-4 container table-container">
    <form [formGroup]="timeslotform" (ngSubmit)="addTimeSlot()">
      <div class="row mt-4">
        <div
          *ngFor="let question of questions"
          class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
        >
          <app-question
            [question]="question"
            [form]="timeslotform"
          ></app-question>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
    <form [formGroup]="addTimeslotform" (ngSubmit)="addTimeSlot()">
      <div class="row mt-3 container">
        <div
          *ngFor="let Addquestion of AddSlotQuestions"
          class="mb-3 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xxl-3"
        >
          <app-question
            [question]="Addquestion"
            [form]="addTimeslotform"
          ></app-question>
        </div>
      </div>
      <button *ngIf="!editMode" mat-raised-button color="primary" type="submit">
        + Add Time Slot
      </button>
      <button *ngIf="editMode" mat-raised-button color="primary" type="submit">
        Update Time Slot
      </button>
    </form>

    <div class="mt-3"> 
      <div *ngIf="timeSlots.length === 0">
          <p>No time slots available.</p>
        </div>
      <div>
        <div *ngFor="let slot of timeSlots; index as i" class="time-slot-card">
          <mat-card class="mat-elevation-z4">
            <mat-card-content>
              <div class="row d-flex align-items-center">
                <div class="col-12 col-md-2">
                  <strong>Start Day:</strong>
                  <div>{{ slot.start_day }}</div>
                </div>
                <div class="col-12 col-md-2">
                  <strong>Start Time:</strong>
                  <div>{{ slot.start_time }}</div>
                </div>
                <div class="col-12 col-md-2">
                  <strong>End Time:</strong>
                  <div>{{ slot.end_time }}</div>
                </div>
                <div class="col-12 col-md-2">
                  <strong>End Day:</strong>
                  <div>{{ slot.end_day }}</div>
                </div>
                <div class="col-4 col-sm-1 d-flex">
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="removeTimeSlot(slot)"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="editTimeSlot(slot, i)"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</section>
