<section class="container mt-4">
  <div class="container mt-4 d-flex justify-content-between align-items-center">
    <h2 class="text-start">Classes</h2>
    <div class="d-flex justify-content-center align-items-center bg-white date_filter mb-3">
      <button mat-icon-button (click)="previousDate()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-flat-button (click)="picker.open()" class="mx-2 calendar-button">
        <span class="align-self-center">{{ selectedDate | date: 'dd MMMM yyyy' }}</span>
      </button>
      <button mat-icon-button (click)="nextDate()">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <mat-form-field appearance="outline" class="hidden-datepicker">
        <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)" [(ngModel)]="selectedDate">
        <mat-datepicker-toggle class="datepicker input-group-text" [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  
 
    <div class="card_container">
      <div class="row bg-white p-3">
        <div class="col-md-4 mb-4" *ngFor="let class of filteredClasses">
          <mat-card class="h-100">
            <mat-card-header>
              <mat-card-title>{{ class.timing }}</mat-card-title>
              <mat-card-subtitle>{{ class.subject_name }} - #{{ class.room }}</mat-card-subtitle>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item >Edit</button>
                <button mat-menu-item >Delete</button>
              </mat-menu>
            </mat-card-header>
            <div (click)="openClasses(class)">
              <mat-card-content>
                <p>{{ class.degree_type }}</p>
                <p>{{ class.year }} Yrs, {{ class.section }} Sec</p>
                <p>
                  Attendance Status: 
                  <span [ngClass]="{
                    'text-success': class.attendance_status === 'Done',
                    'text-warning': class.attendance_status === 'Pending'
                  }">{{ class.attendance_status }}</span>
                </p>
              </mat-card-content>
            </div>
          </mat-card>
        </div>
      </div>    
    </div>
  </section>
