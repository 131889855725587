import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Inputfiledsbase } from '../../models/inputfiledsbase';
import { DropdownQuestion } from '../../question-dropdown';
import { TextboxQuestion } from '../../question-textbox';
import { TextareaQuestion } from '../../question-textarea';
import { ApiservicesService } from '../../../Services/Apis/apiservices.service';
import { FileuploadQuestion } from '../../question-fileuplaod';

@Injectable({
  providedIn: 'root'
})
export class SemestersService {
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  organisationdata: any = [];

  setData(data: any) {
    this.dataSubject.next(data);
  }
  
  getData(): Observable<any> {
    console.log('hit method from semester details', this.dataSubject)
    return this.dataSubject.asObservable();
  }
   constructor(private apiservice : ApiservicesService) {
    this.apiservice.getorganisation().subscribe((res) => 
    {
      for(let i=0; i <= res.length; i++){
        this.organisationdata?.push({id:res[i].id, key: res[i].short_name, value:res[i].id})
      }
    }); 
  }

  getSemestersForm(){

    const questions: Inputfiledsbase<string>[] = [

      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization',
        required: true,
        options: this.organisationdata,
        order: 1,
      }),

      new TextboxQuestion ({
        key: 'semister',
        label: 'semister',
        type: 'number',
        required: true,
        order: 1
      }),

      new TextboxQuestion ({
        key: 'duration_in_months',
        label: 'Duration (in months)',
        type: "number",
        required: true,
        order: 2
      }),
  ];
  return of (questions.sort((a, b) => a.order - b.order));
  }

  getSubjectForm(){
    const questions: Inputfiledsbase<string>[] = [
    
      // new FileuploadQuestion({
      //   key: 'subjectImage',
      //   label: 'Upload Image',
      //   required: true,
      //   type:'File',
      //   order: 1
      // }),

      new DropdownQuestion({
        key: 'organization_id',
        label: 'Organization ID',
        required: true,
        options: this.organisationdata,
        order: 2
      }),

      new TextboxQuestion ({
        key: 'subject_name',
        label: 'Subject Name',
        required: true,
        order: 3
      }),
      new TextboxQuestion ({
        key: 'subject_code',
        label: 'Subject Code',
        required: true,
        order: 4
      }),
      new TextboxQuestion ({
        key: 'subject_credits',
        label: 'Subject Credits',
        required: true,
        order: 5
      }),

      new TextareaQuestion({
        key: 'description',
        label: 'Description',
        required: true,
        order: 6
      })
    ];
    
    return of (questions.sort((a, b) => a.order - b.order));
  }
}
