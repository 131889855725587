<div>
    <div class="row m-0">
        <div class="col-md-6 col-sm-6 col-lg-6 login_banner"
            style="background-size: contain;
            background-repeat: no-repeat;
            background-position: center; background-image: url({{loginbanner}});">
        </div>
        <ng-container *ngIf="!forgotpasswordflag">
            <div class="col-md-6 col-sm-6 col-lg-6">
                <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
                    <div>
                        <ng-container *ngIf="loginerror">
                            <p class="text-center fs-6" style="color: red;">Given details are not match</p>
                        </ng-container>
                        <div>
                            <p class="mb-2" style="font-size: 14px; font-weight: 400;">Welcome to DBREAI Portal</p>
                        </div>
                        <div class="card login_card">
                            <div class="card-body">
                                <div class="text-center my-3">
                                    <div style="font-size: 25px; font-weight: 500;">Login</div>
                                </div>
                                <form [formGroup]="form" (ngSubmit)="onSubmit()" class="pt-2">
                                    <div class="form-group mb-3">
                                        <label>User ID</label>
                                        <input type="text" formControlName="userid" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f['userid'].errors }" />
                                        @if (submitted && f['userid'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['userid'].errors['required']) {
                                            <div>User Id is required</div>
                                            }
                                        </div>
                                        }
                                    </div>
                                    <div class="form-group mb-3">
                                        <label>Password</label>
                                        <input type="password" formControlName="password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                                        @if (submitted && f['password'].errors) {
                                        <div class="invalid-feedback">
                                            @if (f['password'].errors['required']) {
                                            <div>Password is required</div>
                                            } @if (f['password'].errors['minlength']) {
                                            <div>Password must be at least 6 characters</div>
                                            } @if (f['password'].errors['maxlength']) {
                                            <div>Username must not exceed 40 characters</div>
                                            }
                                        </div>
                                        }
                                    </div>
                                    <button class="px-5 form-control" type="submit" mat-flat-button color="primary">Login
                                        <!-- <mat-icon>arrow_forward</mat-icon> -->
                                    </button>
                                </form>
                                <div class="text-center mt-5">
                                    <p style="font-size: 14px; font-weight: 500;" class="cursor_pointer" (click)="forgotflag()">Forgot Password
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-3">
                            <small>By logging in, you agree to <br />Visaka Terms of Use and Privacy Policy</small>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="forgotpasswordflag">
            <div class="col-md-6 col-sm-6 col-lg-6">
                <app-forgotpassword [roleID]="selectedrole" (disableflags)="addItem($event)"></app-forgotpassword>
            </div>
        </ng-container>

    </div>
</div>